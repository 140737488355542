import React from "react";

export default function ({ secondaryBgColor, bgColor, color }) {
  return (
		<svg
			version="1.1"
			id="diploma"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 1400 1400">
<rect fill={bgColor || "#B9D6F3"} width="1400" height="1300"/>
<rect fill={secondaryBgColor || "#B9D6F3"} width="1400" height="100" y="1300"/>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
	<path fill={color || "#EFECEC"} d="M3865,12154c-63-63-157-106-355-158c-332-88-371-115-553-366c-49-69-106-142-127-163c-34-35-106-87-121-87
		c-16,0,12,84,57,167c72,137,94,210,93,318c0,83-4,99-46,205l-46,115l-11-45c-32-125-52-155-219-332c-169-180-193-255-166-519
		c19-192,12-249-43-358c-46-88-79-114-120-91c-63,36-80,87-90,267c-5,107,10,432,23,488c14,59-191-237-255-368
		c-66-135-91-227-98-362c-6-118,0-167,43-365c37-168,39-322,5-392c-13-27-27-48-31-48c-14,0-29,55-40,150c-14,121-28,165-90,270
		c-28,47-56,102-62,123c-9,28-16,37-34,37c-20,0-22-5-22-46c0-46-11-68-114-237c-53-85-73-177-71-322c1-104,6-139,38-260
		c81-305,82-325,23-465c-23-55-45-100-49-100c-17,0-29,75-39,227c-14,237-42,287-169,307c-39,6-43,5-36-11c4-10,10-41,13-70
		c4-49,0-61-56-170c-71-139-89-190-103-289c-8-54-8-94,0-148c15-106,33-151,106-272c60-99,65-111,65-165c0-32-10-96-21-143l-22-85
		l-30,16c-52,26-124,110-162,186c-33,67-75,207-75,249c0,34-16,18-58-54c-53-94-72-169-72-288c0-160,39-286,151-490
		c37-69,75-152,84-185c17-66,20-135,6-135c-18,0-71,75-91,127c-10,28-22,75-25,105c-10,82-8,80-65,56c-62-26-115-83-131-140
		c-32-118,14-264,201-648c76-157,143-303,149-325c13-48,14-95,2-95c-4,0-30,19-56,42c-61,54-120,144-135,207c-8,35-17,51-32,54
		c-16,5-24-3-40-41c-33-74-48-141-48-217c0-149,52-246,250-465c123-135,191-223,222-284c25-49,51-136,44-144c-10-10-55,39-115,128
		c-97,141-182,208-252,198c-36-5-36-39,0-61c44-26,77-105,101-240c33-190,92-334,199-483c41-58,90-107,186-187
		c201-167,362-333,342-354c-7-7-237,63-304,92c-31,14-72,37-90,51c-27,20-36,23-53,14c-25-13-23-22,26-128c86-188,191-284,475-435
		c59-31,135-77,169-103c83-61,372-344,352-344c-9,0-64,17-122,37c-98,34-114,37-253,41l-147,4v-26c0-23,4-26,34-26
		c49,0,85-35,142-137c122-218,281-303,571-303c70,0,144-5,163-10c24-7,70-43,144-115c60-58,107-107,105-110c-3-2-65,10-139,27
		c-109,25-158,31-255,32c-114,1-125-1-208-33c-48-19-86-35-84-37s38-10,81-19c96-18,162-48,246-112c187-142,218-165,270-192
		c118-63,283-103,500-121c166-14,256-32,318-64c50-25,96-67,58-53c-10,4-68,19-130,33c-87,19-155,27-316,33c-203,8-226,6-217-24
		c2-5,20-13,40-17c58-12,104-39,243-143c195-146,254-170,444-182c108-6,170-21,95-23c-22-1-76-7-120-13l-80-12l80-38
		c168-80,177-82,570-82c193,0,366-2,385-3l35-2l-31-20c-23-15-244-80-270-80c-2,0-4-11-4-25c0-17,7-26,23-30c66-15,230-36,364-47
		l152-12l137-137c76-76,136-140,134-142c-3-2-66-37-140-77c-775-421-1361-915-1734-1463c-73-106-84-127-71-137c12-10,23-5,62,27
		c55,46,219,160,313,220c119,75,360,223,364,223c2,0-19-43-46-96c-67-128-98-224-169-514c-33-135-74-285-90-334c-23-67-28-91-19-100
		c9-8,21,0,48,34c246,299,484,523,847,795c156,117,506,362,629,441l29,18l-8-24c-4-14-10-31-13-38c-2-7,22,3,54,23
		c51,30,162,79,230,99c20,6,16-23-84-624c-58-347-103-633-100-636s364,185,802,417l796,421l796-411c438-226,799-411,803-411
		s-6,78-22,173c-126,766-153,937-149,937c2,0,60-48,128-106c167-142,384-347,844-800c466-458,557-541,572-526c8,8,1,25-25,64
		c-124,188-233,622-197,791c27,134,147,201,315,178c115-16,206-51,467-177c263-126,335-157,349-149c20,13,7,31-44,59
		c-99,56-282,184-413,289c-152,122-259,218-589,526c-487,455-661,596-992,806c-78,50-142,92-141,95c0,3,74,76,163,163l162,157h97
		c94,0,346,23,416,38c25,6,36,14,38,29c3,23,0,24-188,77c-30,9-68,24-84,35l-28,19l61,7c34,4,148,1,254-5c230-15,457-9,542,15
		c33,9,94,33,135,52l75,36l-118,17c-65,10-121,21-124,24c-4,3,34,6,83,6c213,1,297,28,463,146c150,107,229,153,272,160
		c50,8,62,18,55,41c-6,18-11,19-52,12c-25-5-123-9-218-8c-146,0-188-3-286-25c-63-13-117-23-118-21c-2,2,15,15,38,30
		c63,40,167,62,356,74c190,13,343,43,452,89c73,30,139,73,302,192c106,78,164,104,278,123l77,13l-59,27c-146,67-259,73-500,23
		c-70-15-130-25-132-23c-2,1,36,44,84,95c119,126,135,132,333,132c303,0,438,67,570,281c69,112,91,134,149,143c35,5,41,10,38,28
		c-3,22-6,22-153,21c-144-1-155-3-259-37c-61-20-116-36-124-36s4,15,27,34c22,18,96,87,165,151c154,146,200,178,403,283
		c272,142,379,248,462,459c20,51,20,53,2,63c-15,8-31,2-75-24c-31-18-93-45-138-60c-132-41-268-75-268-67c0,16,241,242,355,333
		c133,105,191,164,252,253c85,127,138,257,173,430c29,144,44,178,96,223c33,30,42,44,37,60c-4,18-10,20-37,15c-97-18-140-52-241-189
		c-75-100-125-151-125-126c0,5,6,30,14,56c25,82,101,187,246,341c216,228,280,342,280,494c0,71-10,117-42,198c-29,75-55,71-78-11
		c-24-89-108-193-189-235c-23-13-23-12-16,29c11,66,55,164,176,393c128,241,172,339,199,445c33,125,23,205-32,268
		c-25,28-112,84-132,84c-2,0-7-21-11-47c-15-100-44-170-91-220l-45-48l5,66c6,79,30,143,92,245c65,107,119,225,146,319
		c18,65,23,104,22,205c0,138-13,188-79,298l-33,53l-17-76c-40-179-133-326-243-384c-13-7-26-11-28-8c-13,12-36,179-32,233
		c3,55,10,70,71,164c88,135,112,209,112,350c-1,128-16,183-91,336c-64,129-74,175-50,234l16,41l-37-6c-72-12-103-27-127-58
		c-36-49-46-91-57-242c-11-139-20-196-39-225c-8-13-11-12-24,5c-8,11-25,47-38,80c-19,49-23,78-22,160c1,88,6,118,47,255
		c57,192,71,279,64,395c-7,114-34,200-93,295c-63,101-86,157-82,200c3,29,0,35-19,38c-16,2-24-4-32-31c-6-18-32-67-58-108
		c-70-114-91-166-103-264c-13-100-36-178-53-172c-26,8-41,83-41,202c1,106,5,138,40,275c36,144,38,165,39,300c0,171-17,244-95,408
		c-43,91-165,284-221,352l-21,25l6-48c14-118,18-416,7-507c-15-124-28-163-62-196c-55-53-107-27-154,78c-46,102-51,164-31,343
		c35,291,14,352-194,580c-108,118-154,195-166,278c-3,26-9,47-13,47c-8,0-67-140-86-204s-19-173,1-249c8-33,37-104,64-158
		c45-89,73-179,56-179c-44,0-146,108-254,268c-154,228-216,270-522,357c-189,54-291,99-346,156c-21,21-47,39-57,39c-18,0-19-6-15-98
		c4-87,9-107,41-173c39-80,88-143,182-229c80-74,117-103,339-260c174-124,359-281,311-265c-36,12-168,34-295,49
		c-148,17-223,41-296,96c-32,24-55,36-59,29c-4-6-9-45-12-88c-12-177,80-313,282-415c122-61,198-77,390-81c272-6,333-24,394-119
		c23-34,41-64,41-67c0-2-25-2-55-1c-32,2-105-6-177-21c-68-14-147-28-175-31c-116-12-230,28-353,123c-35,27-65,49-66,48
		c-7-7,39-173,61-222c96-219,308-410,504-455c96-22,160-9,299,61c92,47,136,64,176,67l53,4l67-114c36-63,66-117,66-119
		c0-3-28-6-62-7c-35-1-133-17-218-34c-135-28-175-33-310-33c-143,0-158,2-198,23c-24,13-45,29-49,37c-6,17-21,19-41,6
		c-11-6-6-26,23-97c136-330,272-476,473-506c106-15,168-1,340,81c155,74,245,102,265,82c6-6,32-59,58-118l47-108l-37-11
		c-51-16-135-60-204-106c-227-154-441-157-612-8l-50,43l-3-58c-3-52,2-69,37-140c75-151,217-303,338-360c78-38,149-41,294-16
		c68,12,135,24,149,26c42,6,170-15,252-42c76-25,78-26,89-67c6-22,15-58,21-79c6-20,9-39,6-42c-2-3-20,0-39,5c-50,15-204,12-385-6
		c-244-25-380-9-462,53c-26,20-36,22-47,13c-19-16-19-34-1-50c8-6,36-62,62-124s61-133,79-158c47-64,149-128,305-188
		c418-164,490-197,572-270c35-31,41-42,46-88l4-52l-103,54c-127,66-205,90-375,116c-176,26-235,49-299,116l-51,54l-28-116
		c-46-189-39-207,148-397c132-134,194-173,356-223c152-48,246-93,294-143l40-40l-13-94c-19-149-15-144-75-83
		c-133,136-262,206-457,246c-176,36-231,69-268,159c-8,17-17,31-20,31c-13,0-31-124-31-207c0-98,29-216,70-289c36-63,86-106,273-239
		c155-111,261-215,302-299l28-57l-39-104l-39-104l-28,62c-33,73-85,130-147,162c-25,12-103,39-174,59c-211,59-273,107-298,227
		c-7,33-15,59-18,59s-29-24-57-52c-102-105-167-268-167-423c-1-108,19-174,69-232c45-53,90-70,205-78c121-9,162-19,200-52
		c77-64,115-170,108-303c-3-75-9-97-38-154c-38-73-35-75-64,26c-20,69-88,148-156,183c-80,41-161,49-287,31c-184-27-262-17-279,36
		c-7,24-7,125,1,138c5,8-18,20-39,20c-17,0-82-141-97-209c-41-193,55-355,278-469c170-87,243-174,242-287c0-74-19-115-95-210l-69-85
		l-9,30c-12,39-42,60-139,95c-108,38-164,78-214,153c-59,87-93,202-100,330c-3,56-10,102-15,102c-20,0-128-82-172-131
		c-97-108-141-239-133-399c8-177,76-323,212-455c45-44,87-90,93-102c29-54,4-119-72-183l-60-50l-21,38c-11,20-50,59-85,85
		c-149,110-167,126-198,172c-47,70-72,178-72,307c0,66-1,68-32,84c-69,36-163-11-238-118c-100-142-119-271-63-428
		c127-351,144-420,133-520c-5-41-10-48-55-78l-50-34l-5,79c-9,121-49,198-218,407c-36,44-80,104-99,134l-34,53l-90-177
		c-115-227-139-296-139-406c0-46,5-121,10-166c12-94,1-182-28-220c-15-21-248-129-257-120c-2,2,4,28,12,58c11,37,16,100,17,190
		c1,140-3,160-50,268l-23,53l-30-16c-64-33-109-117-187-351c-31-93-57-171-59-172c-2-2-52,4-112,13l-108,18l-47,96l-46,96l-7-52
		c-5-34-17-63-34-84l-28-33l-374,58c-505,77-499,76-499,91c0,7-4,15-9,17c-5,1-90,165-189,364l-180,361l141,23
		c551,89,1112,319,1574,647c767,544,1301,1384,1482,2333c48,253,55,341,55,675c0,337-9,441-65,720c-114,570-378,1136-747,1600
		c-123,154-403,434-558,557c-536,427-1170,696-1874,795c-179,25-721,25-900,0c-449-64-817-177-1210-373c-364-182-641-379-924-654
		c-636-620-1028-1456-1107-2365c-15-167-6-561,16-725c138-1034,668-1941,1482-2537c467-341,1043-582,1613-674c74-12,136-23,138-24
		c3-2-360-723-373-742c-2-4-793-133-815-133c-17,0-20,7-20,45c0,25-4,45-8,45s-20-25-35-55s-30-55-34-55c-5,0-61-9-126-20
		s-124-20-131-20c-8,0-24,38-41,97c-62,222-120,339-183,376l-31,18l-20-39c-62-121-81-259-58-407c8-49,12-93,10-97
		c-10-18-93-7-154,21c-52,24-69,37-89,73c-22,38-25,54-24,138c0,52,4,147,8,210c11,150,0,192-109,422c-47,98-85,182-85,187
		c0,28-21,14-57-37c-22-31-80-104-128-162c-158-187-195-262-195-390c0-44-2-80-5-80c-22,0-84,54-94,81c-32,92,1,257,97,485
		c63,147,76,206,69,301c-9,121-97,274-191,332c-38,24-112,28-139,8c-13-9-20-38-27-115c-20-217-62-289-230-402
		c-110-74-150-118-150-167v-37l-41,39c-65,63-99,130-99,194v55l90,84c49,47,107,112,128,146c160,254,146,547-35,738
		c-49,51-138,118-156,118c-4,0-7-24-7-54c0-181-90-394-195-464c-25-16-88-44-140-62c-119-42-140-62-140-136v-55l-23,28
		c-35,43-85,131-113,198c-35,88-34,193,3,256c39,67,82,100,217,169c147,75,217,138,263,237c28,59,32,78,32,148
		c-1,126-70,300-114,288c-18-5-20-14-20-77c0-85-14-111-69-126c-39-10-62-8-241,20c-146,24-267-7-349-89c-51-51-76-101-93-186
		l-14-68l-21,49c-74,176-59,378,36,486c49,56,90,70,227,78c162,10,210,37,263,150c23,49,26,66,25,165c-1,123-28,221-86,324
		c-37,63-112,146-126,137c-6-3-13-24-17-46c-10-60-62-129-120-161c-28-16-114-45-190-65c-77-19-162-48-189-62
		c-81-44-145-143-171-260c-4-19-10-30-15-25c-4,4-22,51-40,103l-33,95l18,59c40,127,138,236,330,365c253,170,283,200,330,324
		c37,99,49,210,34,323c-12,92-22,113-34,75c-3-10-19-38-36-61c-41-56-106-84-249-108c-63-11-142-30-176-44c-115-46-266-160-346-261
		c-21-27-38-47-38-45c0,3-7,53-15,113l-16,109l35,51c64,92,122,126,313,183c224,68,284,104,441,266c154,158,159,178,112,383l-22,93
		l-51-54c-67-70-121-89-318-115c-85-11-183-29-218-41c-66-22-210-92-268-131l-33-21v37c0,32,8,45,62,99c98,97,185,140,538,266
		c329,117,370,148,467,349c35,75,72,144,81,154c14,15,14,19,1,32c-12,12-24,9-86-22c-65-32-83-37-170-41c-63-2-163,3-281,17
		c-231,27-321,27-404,1c-34-11-64-20-66-20s-1,8,2,18c3,9,12,43,21,75c15,53,20,59,65,82c68,34,182,65,270,72c60,4,102,0,208-22
		c168-35,243-32,335,13c77,38,176,128,250,224c80,106,109,167,116,249c4,38,5,69,4,69c-2,0-31-23-65-50c-141-113-312-129-480-44
		c-29,15-86,49-127,76c-99,68-180,107-239,116l-49,7l49,105c27,57,54,108,61,112c20,13,102,9,148-7c24-9,107-47,184-86
		c108-54,156-72,209-80c192-27,370,73,487,272c43,74,131,255,143,296c10,31-30,43-50,15c-54-77-329-75-629,5c-33,9-106,19-162,22
		l-101,6l61,102c69,114,88,128,172,128c39-1,68-10,125-40c199-104,189-100,280-103c97-4,174,18,276,79c120,72,262,236,325,374
		c30,67,65,192,56,201c-3,3-31-13-63-37c-121-89-239-127-352-113c-32,3-112,19-179,35c-138,33-192,36-253,14c-24-8-45-13-47-11
		c-11,11,107,121,158,146c89,45,137,53,354,55c197,1,201,2,295,34c52,18,119,46,148,63c81,46,174,138,208,205c26,53,29,67,29,160
		c0,56-3,104-7,108c-3,4-29-9-57-29c-75-51-151-76-270-87c-142-14-301-35-326-44c-56-19,136,134,354,282c201,137,265,187,352,273
		c88,87,113,119,145,184c36,73,39,87,42,183c3,57,0,103-5,103S3889,12179,3865,12154z"/>
</g>
		</svg>
	)
}