const FETCH_CALENDAR = "CALENDAR/FETCH";
const FETCH_CALENDAR_SUCCESS = "CALENDAR/FETCH_SUCCESS";
const FETCH_CALENDAR_FAILURE = "CALENDAR/FETCH_FAILURE";

const types = {
  FETCH_CALENDAR,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_FAILURE
};

export default types;
