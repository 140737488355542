import React from "react";

export default function ({ secondaryBgColor, bgColor, color }) {
  return (
		<svg
			version="1.1"
			id="ligaNacional"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 1280 1280">
<rect fill={bgColor || "#B9D6F3"} width="1280" height="1188.57"/>
<rect fill={secondaryBgColor || "#B9D6F3"} width="1280" height="91.43" y="1188.57"/>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
	<path fill={color || "#EFECEC"} d="M3280,12290c-525-47-924-327-1165-815c-120-242-185-447-234-735c-51-299-80-709-62-887c22-217,93-441,215-678
		c94-183,193-337,451-702c116-166,265-383,330-484c271-421,687-981,1234-1664c101-126,216-279,254-340c252-399,483-683,871-1071
		c244-243,386-372,603-547l133-107h-105h-105v-125v-125h68h68l12-117c47-463,30-858-47-1063c-88-235-411-608-845-975
		c-79-66-80-67-127-62c-63,8-109-6-145-44c-26-27-30-38-34-112c-5-79-6-82-32-87c-15-3-47-19-72-37c-39-27-45-37-45-65
		c1-18-4-46-12-63c-19-46-24-450-6-542c7-40,10-70,6-68c-10,7-216-80-234-99c-18-18-13-21,75-56c246-95,699-148,1525-180
		c719-28,1774,13,2245,86c167,26,434,97,463,123c14,14-3,36-41,53c-174,75-194,82-204,67c-7-10-8-5-3,16c28,124,35,425,13,545
		c-6,36-11,83-10,104c1,26-4,46-16,59c-20,22-84,57-106,57c-11,0-15,19-17,84c-4,78-6,87-34,116c-30,32-93,55-124,45
		c-11-3-63,26-152,86c-443,302-739,566-901,805c-88,129-120,228-139,438c-17,182,6,504,58,793l16,93h74h73v125v125h-105h-105
		l137,119c491,423,989,958,1288,1386c46,66,127,167,181,225c167,181,483,538,634,715c416,488,627,762,901,1173
		c95,141,241,354,324,472c199,284,255,368,354,532c251,418,355,808,321,1198c-44,512-145,914-321,1280c-79,164-105,207-200,335
		c-144,196-354,372-537,451c-201,87-332,119-555,134c-397,27-699-75-829-280c-63-98-77-245-38-386c40-146,87-219,329-509
		c264-315,392-516,461-725c18-51,30-109,30-138c0-99-76-293-189-483c-93-155-80-145-137-111c-157,92-448,185-809,257
		c-71,14-135,32-141,38c-5,7-44,52-84,101c-74,88-198,208-297,285c-148,116-396,252-575,316l-78,28v158v158l58,3l57,3l3,72l3,72
		l-61,3l-60,3l-44,97c-115,255-305,456-495,521c-72,25-197,33-270,17c-78-17-185-70-256-127c-116-95-238-262-310-429
		c-34-79-34-79-75-82l-41-3l3-72l3-72l63-3l62-3v-153v-153l-42-12c-74-22-269-107-366-160c-112-61-215-130-313-209
		c-134-108-359-351-327-353c7,0-81-20-197-43c-340-70-571-139-748-225c-65-31-101-43-106-36c-27,33-145,288-177,381
		c-62,182-53,275,44,469c97,195,254,410,502,690c204,229,268,346,289,531c17,142-24,264-122,370C3941,12244,3632,12322,3280,12290z
		 M3708,11847c24-9,57-26,73-37c36-26,38-65,9-142c-23-62-49-99-188-276c-186-238-341-484-428-682c-151-344-144-567,26-850
		c70-116,155-232,231-314l65-71l12-135c25-268,67-638,113-985c98-738,212-1312,320-1622c38-108,117-300,137-331c8-12,13-24,11-27
		c-5-5-80,105-447,650c-338,503-474,717-647,1020c-76,132-208,350-293,485c-361,569-483,807-551,1075c-47,187-50,291-16,550
		c55,423,138,746,257,996c208,437,462,647,855,709C3342,11875,3657,11866,3708,11847z M9543,11830c201-45,364-138,512-292
		c297-307,504-799,612-1448c55-337,7-611-176-995c-85-178-150-290-376-650c-100-159-224-362-275-450c-165-284-296-484-553-845
		c-209-294-819-1100-833-1100c-1,0,17,34,42,76c65,110,138,258,194,394c97,236,135,397,170,720c52,481,91,947,130,1535
		c6,88,15,223,20,300s12,188,15,246l7,105l94,85c197,175,376,422,434,599c73,224,4,513-210,887c-34,59-137,209-228,333
		c-175,237-230,328-239,398c-7,51,5,68,65,95C9041,11863,9376,11868,9543,11830z"/>
</g>
		</svg>
	)
}