const FETCH_STATS = "STATS/FETCH";
const FETCH_STATS_SUCCESS = "STATS/FETCH_SUCCESS";
const FETCH_STATS_FAILURE = "STATS/FETCH_FAILURE";

const types = {
  FETCH_STATS,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_FAILURE
};

export default types;
