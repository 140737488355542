const breakpoints = {
  mobile: 320,
  mobileLandscape: 480,
  tablet: 900, //768
  tabletLandscape: 1024,
  desktop: 1200,
  desktopLarge: 1500,
  desktopWide: 1920,
};

export default breakpoints;
