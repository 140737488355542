import React from "react";

export default function ({ color }) {
  return (
		<svg
			version="1.1"
			id="danger"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="-50 -50 400 400">
      <g>
        <path fill={color || "#000000"} d="M282.358,208.256L182.012,36.049c-7.931-13.61-22.069-21.735-37.821-21.735S114.3,22.44,106.37,36.049L6.025,208.256
          c-7.986,13.705-8.035,30.097-0.132,43.85c7.903,13.752,22.091,21.963,37.953,21.963h200.691c15.862,0,30.049-8.21,37.953-21.963
          C290.393,238.353,290.343,221.96,282.358,208.256z M265.149,242.14c-4.292,7.469-11.998,11.928-20.612,11.928H43.845
          c-8.614,0-16.32-4.459-20.612-11.928c-4.292-7.469-4.266-16.372,0.072-23.815L123.65,46.119
          c4.307-7.392,11.986-11.805,20.541-11.805s16.234,4.413,20.541,11.805l100.345,172.206
          C269.415,225.768,269.441,234.671,265.149,242.14z"/>
        <path fill={color || "#000000"} d="M144.191,91.172c-5.523,0-10,4.477-10,10v84.991c0,5.523,4.477,10,10,10s10-4.477,10-10v-84.991
          C154.191,95.649,149.714,91.172,144.191,91.172z"/>
        <path fill={color || "#000000"} d="M144.191,207.221c-5.523,0-10,4.477-10,10v11.251c0,5.523,4.477,10,10,10s10-4.477,10-10v-11.251
          C154.191,211.698,149.714,207.221,144.191,207.221z"/>
      </g>
    </svg>
  )
}