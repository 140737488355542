const FETCH_PEOPLE = "PEOPLE/FETCH";
const FETCH_PEOPLE_SUCCESS = "PEOPLE/FETCH_SUCCESS";
const FETCH_PEOPLE_FAILURE = "PEOPLE/FETCH_FAILURE";

const types = {
  FETCH_PEOPLE,
  FETCH_PEOPLE_SUCCESS,
  FETCH_PEOPLE_FAILURE
};

export default types;
