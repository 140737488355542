const FETCH_LINKS = "LINKS/FETCH";
const FETCH_LINKS_SUCCESS = "LINKS/FETCH_SUCCESS";
const FETCH_LINKS_FAILURE = "LINKS/FETCH_FAILURE";

const types = {
  FETCH_LINKS,
  FETCH_LINKS_SUCCESS,
  FETCH_LINKS_FAILURE
};

export default types;
