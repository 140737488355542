import React from "react";

export default function ({ secondaryBgColor, bgColor, color }) {
  return (
		<svg
			version="1.1"
			id="copa"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 1400 1400">
<rect fill={bgColor || "#B9D6F3"} width="1400" height="1300"/>
<rect fill={secondaryBgColor || "#B9D6F3"} width="1400" height="100" y="1300"/>
<g transform="translate(0.000000,1260.000000) scale(0.100000,-0.100000)">
	<path fill={color || "#EFECEC"} d="M6677,11885c-181-35-308-97-419-206c-91-89-135-171-146-271l-7-63l-70-8c-840-92-1624-309-2149-594
		c-145-79-352-217-370-247c-17-28-5-413,19-610c9-76,14-140,12-142s-19,20-37,49c-52,81-224,247-323,312c-281,182-617,266-1007,251
		c-522-19-909-163-1186-440c-278-277-408-648-390-1106c12-316,73-563,201-815c110-218,253-410,460-618c268-270,560-480,1132-815
		c147-87,242-161,356-282c237-249,407-546,462-811c22-104,20-125-24-199c-36-61-109-140-129-140c-4,0-28,13-53,28
		c-51,33-109,99-167,192c-62,99-72,110-89,110c-18,0-436-223-465-248c-26-22-22-43,19-114c175-303,393-488,627-533
		c98-19,154-19,252,0c204,39,425,205,520,389c21,41,40,74,41,72c1-1,9-69,18-152c26-249,61-410,116-532c87-194,427-590,749-876
		c602-534,1403-987,1987-1125l52-13l7-66c27-253-59-679-218-1077c-65-166-209-469-236-498c-23-26-115-71-182-90
		c-113-32-241-47-575-67c-358-21-423-34-635-126c-276-119-390-195-441-295c-23-47-24-109-2-152c28-54,143-175,207-216
		c320-209,951-355,1831-423c247-19,924-16,1170,6c733,64,1341,199,1703,377c107,53,133,71,198,137c41,42,83,94,94,117
		c75,154-69,295-475,465c-196,82-205,84-770,120c-363,24-546,77-608,178c-69,111-222,467-290,677c-95,293-136,501-144,746
		c-4,106-2,172,5,185c6,12,30,24,67,32c149,35,420,137,650,245c779,365,1540,974,1962,1567c107,152,143,232,181,410
		c32,150,69,527,72,735c1,26,6,19,41-47c102-194,296-350,499-403c99-26,281-17,378,18c172,62,326,193,461,392
		c79,116,114,183,107,208c-4,16-61,52-232,145c-135,74-234,122-247,120c-14-2-43-35-88-103c-73-111-177-215-213-215
		c-34,0-69,32-123,113c-46,68-48,75-47,137c1,79,32,193,85,320c95,223,227,412,428,610c147,145,158,153,511,363
		c500,298,844,572,1105,881c275,326,418,639,476,1041c18,120,18,436,0,550c-46,298-178,572-371,765c-302,301-761,453-1328,437
		c-263-7-452-45-662-136c-217-94-425-263-540-440c-14-21-16-4-15,197c0,257,9,233-126,350c-393,343-1109,593-2034,712
		c-157,20-614,60-691,60c-18,0-23,8-28,45c-4,24-23,76-42,114c-72,145-251,271-454,321C6977,11898,6783,11905,6677,11885z
		 M12065,10046c506-87,745-369,745-879c0-132-20-289-50-390c-133-441-629-926-1443-1409c-93-55-205-127-249-161
		c-324-244-659-704-803-1101l-32-89l-7,184c-8,210-18,411-43,859c-10,185-17,466-18,680c0,417,3,449,101,1030c31,179,60,354,66,390
		c14,90,22,100,37,48c17-56,72-166,119-237c85-128,241-264,282-245c24,11,310,420,310,443c0,9-26,43-58,76c-128,133-152,295-68,471
		c87,184,291,300,606,344C11651,10073,11958,10064,12065,10046z M2510,9754c261-43,453-160,535-329c85-173,59-349-70-476
		c-30-30-55-62-55-72c0-22,286-436,306-444c33-12,73,14,168,110c122,123,185,223,236,378l37,109l17-97c36-216,96-596,113-728
		c27-207,23-669-10-1295c-24-438-45-900-48-1045l-1-50l-16,40c-134,333-425,747-680,968c-121,105-157,130-372,259
		c-815,490-1288,950-1425,1388c-85,272-69,653,35,860c89,175,238,301,440,370C1938,9774,2253,9795,2510,9754z"/>
</g>
		</svg>
	)
}