const FETCH_CLUBDATA = "CLUBDATA/FETCH";
const FETCH_CLUBDATA_SUCCESS = "CLUBDATA/FETCH_SUCCESS";
const FETCH_CLUBDATA_FAILURE = "CLUBDATA/FETCH_FAILURE";

const types = {
  FETCH_CLUBDATA,
  FETCH_CLUBDATA_SUCCESS,
  FETCH_CLUBDATA_FAILURE
};

export default types;
