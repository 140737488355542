import React from "react";

export default function ({ secondaryBgColor, bgColor, color }) {
  return (
		<svg
			version="1.1"
			id="liga"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 670 670">
<rect fill={bgColor || "#B9D6F3"} width="670" height="622.14"/>
<rect fill={secondaryBgColor || "#B9D6F3"} width="670" height="47.85" y="622.14"/>
<g transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)">
	<path fill={color || "#EFECEC"} d="M1205,6860v-140l-217-1c-247,0-276-6-308-59c-17-30-20-56-24-195c-4-171,13-582,30-730c42-375,134-661,287-895
		c20-30,39-57,42-60s21-25,40-50c113-145,317-308,508-404c78-39,251-111,294-121c16-4,51-34,84-73c242-280,512-476,804-585
		c53-20,126-43,163-53c59-14,67-19,67-40c0-14,4-32,9-42c15-27,31-251,31-417c0-157-16-386-30-420c-4-11-13-58-20-105s-16-89-20-95
		c-4-5-12-37-19-70s-16-69-21-80c-4-11-21-56-36-100c-16-44-34-91-41-105s-13-28-13-32c0-30-152-315-202-379c-15-20-28-40-28-44
		s-11-21-25-39c-14-17-29-36-33-41c-5-6-13-18-18-26c-23-41-304-339-319-339c-3,0-67-54-97-82c-10-10-21-18-25-18c-3,0-13-7-22-15
		c-21-20-132-91-196-126l-50-27l-3-76l-3-76h1501h1500v74v74l-79,46c-44,25-83,50-86,56c-4,6-23,19-43,29c-20,11-51,32-69,48
		c-17,15-46,39-65,53c-43,32-307,296-333,333c-25,35-79,110-117,159c-15,20-28,39-28,42s-17,32-39,65c-71,111-188,363-221,476
		c-5,17-15,48-24,70c-8,22-15,49-16,60c0,12-4,25-8,30c-5,6-14,42-21,80c-7,39-16,79-21,90c-11,28-30,180-30,242c0,29-4,55-10,58
		c-6,4-10,83-10,205s4,201,10,205c6,3,10,34,11,68c0,34,6,96,12,137c12,73,12,74,47,83c191,49,404,144,565,252c55,37,102,70,105,74
		c3,3,23,19,45,35c44,33,264,250,310,308c23,28,51,44,120,71c195,75,395,191,525,304c89,78,177,170,220,233c13,19,27,37,30,40
		c4,3,28,41,55,85c178,294,276,654,315,1155c17,228,30,461,30,553c0,85-1,90-30,119l-31,30l-257-3l-257-4l-3,133l-3,132H3300H1205
		V6860z M5771,6473c-3-38-8-126-11-198c-10-224-34-450-67-630c-93-505-288-837-623-1062c-87-58-128-83-139-83c-2,0,16,37,41,83
		c110,198,215,454,281,692c22,77,43,154,46,170c3,17,11,53,16,80c52,241,80,523,80,802v202l78,4c42,1,128,4,191,5l114,2L5771,6473z
		 M1209,6263c8-300,25-460,72-698c65-328,159-606,304-896c29-57,61-116,72-132c11-15,18-32,16-37c-3-10-205,105-228,130
		c-3,3-25,21-50,40c-75,58-189,180-246,266c-143,214-220,451-264,814c-11,94-30,509-30,663v97h173h174L1209,6263z"/>
</g>
		</svg>
	)
}