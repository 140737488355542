const FETCH_DOCUMENTS = "DOCUMENTS/FETCH";
const FETCH_DOCUMENTS_SUCCESS = "DOCUMENTS/FETCH_SUCCESS";
const FETCH_DOCUMENTS_FAILURE = "DOCUMENTS/FETCH_FAILURE";

const types = {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE
};

export default types;
