import React from "react";

export default function ({ color }) {
  return (
		<svg
			fill={color || "#000000"}
			version="1.1"
			id="city"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 512 512">
		<g>
			<g>
				<path d="M451.94,220.121C449.312,98.224,347.972,0.005,225,0.005c-124.359,0-225,100.632-225,225c0,124.359,100.633,225,225,225
					c42.309,0,84.031-11.621,119.24-32.973l49.723,87.391c5.742,10.092,20.332,10.098,26.075-0.001l79.402-139.591
					c0.064-0.112,0.127-0.226,0.188-0.339C507.722,349.365,512,332.253,512,315.004C512,273.179,487.415,236.991,451.94,220.121z
					M152.841,43.846c-15.746,21.983-26.778,48.878-34.054,74.945c-25.897,7.229-52.908,18.261-74.947,34.046
					C63.66,103.265,103.267,63.664,152.841,43.846z M111.17,152.539c-3.239,18.398-5.238,37.688-5.91,57.466H33.029
					C44.359,181.681,82.199,162.518,111.17,152.539z M105.26,240.005c0.672,19.777,2.67,39.067,5.909,57.466
					c-28.966-9.977-66.81-29.14-78.141-57.466H105.26z M43.84,297.172c22.039,15.784,49.05,26.816,74.947,34.046
					c7.273,26.054,18.303,52.954,34.054,74.945C103.267,386.345,63.66,346.744,43.84,297.172z M210,416.976
					c-28.323-11.33-47.486-49.169-57.466-78.141c18.399,3.239,37.689,5.237,57.466,5.909V416.976z M210,314.734
					c-23.257-0.842-45.684-3.638-66.501-8.228c-4.591-20.817-7.387-43.244-8.228-66.501H210V314.734z M210,210.004h-74.729
					c0.842-23.257,3.638-45.684,8.228-66.502c20.817-4.589,43.244-7.385,66.501-8.227V210.004z M210,105.265
					c-19.777,0.672-39.067,2.67-57.466,5.909c9.977-28.965,29.14-66.81,57.466-78.141V105.265z M408.225,153.352
					c-22.103-15.795-49.359-26.952-75.402-34.26c-7.548-26.307-18.958-53.27-35.015-75.28
					C348.106,63.685,388.26,103.508,408.225,153.352z M418.898,210.005H346.73c-0.692-19.608-2.739-38.761-6.051-57.064
					C369.356,162.867,407.418,181.969,418.898,210.005z M240,32.976c28.769,11.272,48.596,49.382,58.892,78.394
					c-18.86-3.359-38.638-5.422-58.892-6.106V32.976z M240,135.274c23.849,0.858,46.873,3.765,68.242,8.537
					c4.724,20.767,7.604,43.09,8.476,66.193H240V135.274z M316.721,239.805c-0.301,8.177-0.849,16.495-1.634,24.485
					c-7.227,13.046-11.756,27.782-12.831,43.454c-19.314,3.815-40.717,6.212-62.255,6.982v-74.921H316.721z M240,417.062v-72.321
					c20.308-0.681,40.582-2.72,59.407-5.967C285.294,379.21,263.169,408.108,240,417.062z M298.197,406.357
					c7.512-10.13,14.309-21.769,20.258-34.643l10.941,19.229C319.543,396.983,309.086,402.14,298.197,406.357z M473.264,350.173
					l-66.266,116.496L342.432,353.19c-0.037-0.065-0.074-0.13-0.112-0.195c-6.752-11.46-10.32-24.597-10.32-37.991
					c0-41.355,33.645-75,75-75c41.355,0,75,33.645,75,75C482,327.269,478.979,339.426,473.264,350.173z"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M407,270.004c-24.813,0-45,20.187-45,45s20.187,45,45,45s45-20.187,45-45S431.813,270.004,407,270.004z M407,330.004
					c-8.271,0-15-6.729-15-15s6.729-15,15-15s15,6.729,15,15S415.271,330.004,407,330.004z"/>
			</g>
		</g>
		</svg>
	)
}