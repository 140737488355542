const FETCH_TEAMS = "TEAMS/FETCH";
const FETCH_TEAMS_SUCCESS = "TEAMS/FETCH_SUCCESS";
const FETCH_TEAMS_FAILURE = "TEAMS/FETCH_FAILURE";

const types = {
  FETCH_TEAMS,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_FAILURE
};

export default types;
