import React from "react";

function brush() {
  return (
    <svg 
      version="1.1"
      id="svg2"
      x="0px"
      y="0px"
      viewBox="0 0 1000 372.2">
<path id="path2987" d="M355.5,372.1c-3.3-0.1-8.8-0.2-12.1-0.1c-3.3,0-8.6,0-11.8-0.1s-8.2-0.1-11.2-0.2c-9.7-0.2-20-0.9-21.1-1.6
	c-1.3-0.7,4.2-0.8,13.6-0.1c3.5,0.2,13.9-0.1,28.3-0.9c22.1-1.2,22.8-1.2,22.9-0.1c0,0.6,0.4,1.1,0.9,1.1c0.5,0,0.9,0.3,0.9,0.8
	C365.9,372,362.9,372.3,355.5,372.1L355.5,372.1z M405.4,366.4c-1.1-1.2,0.2-1.6,1.4-0.4c0.9,0.9,0.9,1.1,0.2,1.1
	C406.5,367.2,405.8,366.8,405.4,366.4L405.4,366.4z M467.6,362.8c-1.1-0.3-1.1-0.4,0-1c0.6-0.4,2.3-0.9,3.7-1.1
	c2.9-0.5,8.2-2.2,10-3.2c0.8-0.4,1.5-0.4,2.3,0c0.6,0.4,0.7,0.5,0.2,0.4c-0.5-0.1-1.1,0.1-1.4,0.5c-0.5,0.6-4.3,2-11.6,4.2
	C469.7,362.9,468.3,363,467.6,362.8L467.6,362.8z M451.6,363.1c-0.2-0.2-0.4-0.6-0.4-1c0-0.4,0.3-0.3,0.7,0.1c0.4,0.4,0.6,0.8,0.4,1
	C452.1,363.3,451.8,363.3,451.6,363.1z M409.2,363.9c-0.1-0.1-2.6-0.3-5.6-0.3c-2.9,0-5.8-0.3-6.5-0.6c-0.8-0.4-1.2-0.3-1.3,0.2
	c-0.1,0.5-0.5,0.5-1.2-0.2c-0.6-0.5-1-1-1-1.2c0-0.5,3.1-1.3,6.5-1.6c1.9-0.2,5.2-0.6,7.4-0.8c6-0.6,10.1-1.2,11.7-1.7
	c0.8-0.2,3.7-0.8,6.4-1.3c2.7-0.5,5-1.1,5-1.3c0-0.3-0.2-0.5-0.5-0.5c-0.2,0-1.2-0.1-2.1-0.2c-2.5-0.2-2.5-0.6,0.1-1.1
	c1.9-0.4,2.5-0.3,3.5,0.7c1,0.9,2,1.1,4.9,1c2-0.1,3.8-0.4,4-0.7c0.2-0.3,1.6-0.8,3.2-1.1c2.1-0.4,3-0.8,3.1-1.6
	c0.2-0.8,0.5-0.9,1.3-0.6c0.6,0.3,1.6,0.4,2.4,0.2c0.7-0.2,2.3-0.5,3.5-0.7c1.2-0.2,2.7-0.7,3.2-1c0.7-0.4,1.6-0.4,2.6-0.1
	c1.2,0.4,2.1,0.2,4.2-0.8c2.8-1.5,3-1.5,6.9-2.2c1.4-0.3,3.7-0.9,5-1.4c1.3-0.5,3.9-1.1,5.6-1.4s4.6-1.2,6.3-2.1
	c1.8-0.9,3.4-1.7,3.6-1.7c0.2,0,0.7-0.4,1-0.7c0.5-0.5,0-0.9-1.8-1.5c-2.9-1-3.8-1-4.5,0c-0.6,0.8-1.7,0.8-6.2,0.3
	c-1.3-0.1-1.8-0.4-1.6-1.1c0.2-0.5,0.7-0.9,1.2-0.9c0.6-0.1,0.7-0.2,0.2-0.3c-0.4-0.1-0.7-0.5-0.8-0.8c0-0.3,0.4-0.4,1-0.1
	c0.6,0.3,1.3,0.3,1.7,0.2c0.4-0.2,0.7,0.1,0.7,0.6c0,0.5,0.1,0.9,0.3,0.9c0.1,0,0.7,0.1,1.3,0.3c0.6,0.2,0.9,0.1,0.7-0.2
	c-0.2-0.3-0.1-0.9,0.1-1.4c0.4-0.7,0.6-0.7,1.1-0.1c0.7,1,2.4,0.3,2.3-1c0-0.8,0.1-0.9,0.5-0.2c0.3,0.4,1.3,0.8,2.3,0.8
	c1,0,1.6,0.1,1.5,0.2c-0.1,0.1,0.3,0.6,1,1c0.7,0.4,1.1,1,0.9,1.2c-0.1,0.3,0.1,0.6,0.6,0.8c1.5,0.5,3,0.3,3.2-0.5
	c0.1-0.4,1.3-0.8,2.7-1c6.2-0.6,6.8-0.8,7.5-1.8c0.7-1,6.3-2.5,10.5-2.8c0.8-0.1,1.2-0.4,1-0.8c-0.2-0.4-0.1-0.7,0.1-0.6
	c0.9,0.2,5.8-1.1,6.1-1.6c0.2-0.3,0.8-0.6,1.4-0.6c0.6,0,1.3-0.4,1.7-0.9c0.4-0.5,1.7-1.1,2.9-1.3c1.9-0.4,2.2-0.7,2.2-1.8
	c0-1.1-0.5-1.4-3-1.9c-1.6-0.3-3.1-0.4-3.3-0.2c-0.2,0.2,0,0.4,0.5,0.6c4.5,1.2,3.7,1.4-3.5,1c-13.9-0.7-17.9-1.4-18-3
	c0-1,0.9-1,1.3-0.1c0.2,0.5,0.4,0.5,0.8-0.1c0.3-0.5,0.9-0.7,1.6-0.4c1.3,0.5,1.1,0.4,3.7,1c2.8,0.5,5.9,0.3,7.3-0.5
	c0.6-0.4,2.1-0.7,3.2-0.8c1.1,0,2.4-0.5,2.8-1c0.5-0.5,1.8-0.9,3.2-1c1.9-0.1,2.3-0.3,2.3-1.1c-0.1-1.4-3.3-3-6-3
	c-2.3,0-2.7,0.3-1.5,1.4c1.5,1.4,0.1,1.2-2.5-0.3c-2.1-1.2-3.2-1.5-5.2-1.3c-1.7,0.2-3.1-0.1-4.4-0.8c-2.1-1.2-4.8-1.2-11.2-0.3
	c-3.5,0.5-4.9,0.5-7.1-0.3c-1.7-0.6-3.5-0.8-4.9-0.5c-1.4,0.2-2.9,0.1-3.8-0.3c-0.9-0.4-2.1-0.7-2.8-0.7c-0.7,0-1.6-0.2-1.9-0.6
	c-0.4-0.4-1.1-0.4-1.7-0.1c-1.3,0.6-3.8,0.9-4.2,0.4c-0.2-0.2-2.4-0.4-4.9-0.5c-2.5-0.1-7.1-0.5-10.1-1.1c-3-0.5-5.5-0.9-5.7-0.9
	c-0.1,0-0.2-0.4-0.2-0.9s-0.4-0.8-0.7-0.6c-0.4,0.1-0.7,0.6-0.7,1.1c0,0.5-0.5,0.8-1.1,0.7c-0.6-0.1-2.4-0.1-4-0.2
	c-1.6,0-3.7-0.4-4.6-0.9c-1-0.4-3.3-0.8-5.2-0.7c-1.9,0-4.9-0.3-6.6-0.7c-4.8-1.2-7.6-1.6-10-1.5c-1.2,0-2.2-0.2-2.2-0.5
	c0-0.3,1.9-0.7,4.6-0.8c5-0.2,5.9-1,2.1-1.8c-1.4-0.3-3.4-0.9-4.4-1.3c-1.2-0.5-2.7-0.6-3.8-0.4c-1,0.2-2.1,0.1-2.5-0.2
	c-0.3-0.3-2.6-0.5-4.9-0.5c-4.5,0.1-7-0.6-2.9-0.8c1.3-0.1,2.5-0.4,2.7-0.7c0.4-0.8-3.3-2.1-5.4-2.1c-0.9,0-1.9-0.4-2.3-1
	c-0.5-0.7-1.7-1.1-4-1.3c-2-0.2-4.9-1-7.2-2.1c-2.1-1-4.4-1.8-5.1-1.7c-1.1,0-3.4-0.6-6.9-2c-1.9-0.7-5.3-1.3-9.3-1.6
	c-1.6-0.1-3.7-0.6-13.6-2.9c-1.8-0.4-4.9-0.8-6.9-0.8c-2,0-5.1-0.3-6.8-0.6c-1.7-0.3-3.3-0.4-3.6-0.2c-0.3,0.2-1.2,0-2.1-0.4
	c-0.9-0.4-2.3-0.7-3.2-0.7c-0.8,0.1-4.3,0.1-7.7,0.1c-3.4,0-6.2,0.2-6.2,0.4c0,0.3-1,0.4-2.3,0.3c-1.9-0.1-2.2,0-1.9,0.7
	c0.2,0.5,0.7,0.9,1.2,0.8c0.4,0,0.8,0.2,0.8,0.5c0,0.3-0.2,0.6-0.5,0.6c-1.1,0-0.5,0.9,1.2,1.9c1.2,0.7,1.7,1.5,1.7,2.5
	c0,1.4,0.2,1.5,2.3,1.4c1.3-0.1,2.7,0.2,3.2,0.5c1.7,1.2,4.6,2.3,6.8,2.5c1.2,0.1,3.4,0.3,4.8,0.5s2.8,0.2,3.1,0.1
	c0.3-0.1,1.6,0.2,2.9,0.6c3.3,1,7,1.7,11.3,2c2.1,0.1,4.9,0.7,6.4,1.2c2,0.8,4,0.9,9,0.7c3.5-0.1,6.9-0.4,7.5-0.6
	c1.3-0.5,2.8,0.3,2.3,1.3c-0.2,0.4-1,0.6-1.9,0.4l-1.5-0.3l1.5,1.1c0.8,0.6,1.3,1.2,1.1,1.5c-0.2,0.2-2.8,0.6-5.7,0.8
	c-7.5,0.6-7.4,0.5-7.3,1.6c0,0.6-0.3,0.9-1.1,0.7c-0.6-0.1-2.1,0-3.3,0.3c-1.5,0.4-3.1,0.4-5.1-0.1c-4.7-1.1-12.4-1.7-13.1-1
	c-0.4,0.4,0,0.7,0.9,0.9c0.8,0.2,1.5,0.6,1.5,0.9c0,0.7-0.9,0.6-6.9-0.6c-7.7-1.6-15.1-2.6-15.9-2.3c-1.4,0.6-0.9,1.4,1.1,2
	c1.1,0.3,2.3,1,2.9,1.5s2.5,1.3,4.5,1.6c3.5,0.6,3.8,0.8,3.7,2.9c0,0.6,0.3,1,0.9,1c0.7,0,0.9-0.4,0.6-1.5c-0.3-1.1-0.1-1.4,0.8-1.5
	c0.6,0,1.7,0.4,2.4,1c0.7,0.6,1.7,1.1,2.2,1.1c0.5,0,1.6,0.6,2.4,1.3c0.8,0.8,1.7,1.4,2.2,1.3s1.7,0.8,2.9,1.9c1.2,1,2.6,2,3.3,2.2
	s1,0.6,0.9,0.9c-0.5,0.9-2.7,0.7-5.2-0.3c-3.6-1.6-7.2-2.2-8-1.5c-0.4,0.3-0.9,0.5-1.1,0.3c-0.4-0.4-3.9-1-7.4-1.3
	c-4.9-0.5-11.1-1.8-15.7-3.3c-2.7-0.9-12-2.1-13-1.7c-0.4,0.2-1.4,0-2.2-0.3c-3.5-1.8-5.3-0.6-4.6,3c0.5,2.7,0.4,2.8-1.9,1.8
	c-1.6-0.7-7-2.3-8.7-2.6c-2.2-0.3-8-1.9-8.5-2.2c-0.3-0.2-2.1-0.6-4-0.9c-1.8-0.3-3.6-0.6-3.8-0.7c-0.3-0.1-2.4-0.2-4.7-0.1
	c-2.3,0.1-5,0.2-5.9,0.3c-0.9,0-2.1-0.4-2.6-1c-1.3-1.4-9.2-3.3-11.3-2.8c-1.1,0.3-1.7,0.1-2.2-0.6c-0.6-1.1-5.5-2.5-6.4-1.9
	c-0.3,0.2-2.5-0.2-5-0.8c-3.6-1-4.4-1-4.4-0.4c0,0.6-0.4,0.8-2,0.5c-1.5-0.2-2.1-0.1-2.3,0.6c-0.2,0.7-0.7,0.8-2.7,0.4
	c-2.7-0.5-6.3,0-9.1,1.3c-1,0.5-2.3,1-2.9,1.3c-1.1,0.5-1.1,0.5,0.1,1.3c1.1,0.8,1.1,0.8-0.3,0.6c-0.8-0.2-2.8,0-4.3,0.4
	c-1.5,0.4-3.5,0.9-4.5,1.1c-1.7,0.4-2.6,0.7-6.2,2.5c-0.9,0.5-2.7,1-4,1.3c-5.8,1.2-12.8,4.7-17.2,8.6c-0.9,0.8-2.4,1.4-4.2,1.6
	c-3.4,0.3-10.1,3-16.9,6.6c-2.8,1.5-5.9,2.7-6.9,2.8c-1,0-2.1,0.4-2.4,0.9c-0.3,0.4-2.5,1.5-4.7,2.3c-6.1,2.3-9.4,3.6-13.3,5.4
	c-1.9,0.9-4.6,1.9-5.9,2.3c-1.3,0.3-5.3,1.3-8.7,2.1c-6,1.5-8.3,2-13.9,3.6c-1.4,0.4-7.1,1-12.7,1.3c-9.7,0.6-10.2,0.6-10.2-0.5
	c-0.1-1.6,4.8-3.2,7.9-2.5c0.4,0.1,0.9-0.2,1-0.7c0.1-0.5,0-0.6-0.1-0.2c-0.2,0.5-0.6,0.6-1.7,0.1c-1.8-0.7-0.9-1.5,1.9-1.7
	c1.9-0.1,2.3,0.4,1.3,1.5c-0.4,0.5,0.8,0.7,4.4,0.9c5.2,0.2,9.6-0.7,21.9-4.7c2.3-0.7,5.4-1.7,6.8-2.1c6.7-2.1,8.5-2.8,9.2-4
	c1-1.7,6.2-3.7,10.3-4c6.6-0.4,7.2-0.5,8.5-2.1c0.7-0.8,1.8-1.5,2.4-1.5c0.6,0,1.6-0.7,2-1.5c0.5-0.8,1.6-1.7,2.4-1.9
	c1.8-0.5,3-1.4,2.9-2.2c0-0.7-7.2,0.2-13.2,1.7c-2.3,0.6-5.8,1.9-7.7,2.9s-3.7,1.8-4,1.9c-0.3,0-1.5,0.6-2.8,1.2
	c-2.1,1.1-4.7,1.3-4.8,0.2c0-0.2,0.8-0.6,1.7-0.9c1-0.2,2.5-1,3.4-1.7c0.9-0.7,2.5-1.4,3.5-1.6c1-0.2,2.5-0.7,3.4-1.1
	c1.8-0.9,2-2.8,0.3-2.7c-1.3,0.1-1.6-0.8-0.5-1.3c0.4-0.2,0.5-0.5,0.2-0.7c-0.3-0.2-2,0.2-4,1c-1.9,0.8-6.2,2.4-9.5,3.6
	c-3.3,1.2-7.6,2.8-9.6,3.6c-2,0.8-5,1.7-6.5,2.1c-1.6,0.4-4.3,1.1-6,1.6c-5.9,1.8-11,3.1-12.2,3.3c-1.5,0.2-3.8,2.1-4.3,3.6
	c-0.2,0.6-1,1.1-2.1,1.2c-1,0.1-1.9,0.2-2.2,0.3c-3.2,1-3.6,1.3-3.3,1.9c0.4,1,0.1,1.2-3.7,1.8c-2.8,0.5-3.3,0.4-3.3-0.4
	c0-0.6,0.5-1,1.7-1c0.9,0,1.7-0.3,1.7-0.6c0-0.3-0.2-0.6-0.5-0.6c-2.6-0.1-4.9-1.1-4.2-1.7c0.1-0.1,1.9-0.5,4-0.8
	c4.8-0.7,12-2.9,15.8-4.9c1.6-0.8,5.8-2.4,9.3-3.5c6.7-2,15.2-5,17.1-5.9c0.6-0.3,2-0.8,3.1-1c1.1-0.3,2.2-0.7,2.5-0.9
	c0.7-0.6,7.9-3.2,8.8-3.3c1.1,0,4.5-2,4.5-2.6c0-0.7-4.3-0.4-6.1,0.4c-0.9,0.4-2.6,1.2-3.8,1.8c-7.9,3.9-11.2,5.2-12.9,5.1l-1.5-0.1
	l1.4-2.2c1-1.6,1.8-2.2,2.9-2.3c0.9,0,2.2-0.4,3-0.9s2.9-1.2,4.8-1.7c6.2-1.6,7.7-2.2,7.6-3c-0.1-1.6-2-1.3-10.3,1.9
	c-4.7,1.8-9.4,3.3-10.5,3.3c-1.9,0.1-2,0-1.4-1.2c1.1-2.2-2-2.6-5.8-0.6c-2,1-1.7,1.8,0.6,2c2.5,0.1,3.5,0.9,2,1.5
	c-0.6,0.3-1.5,1-2,1.7c-0.8,1.1-1.1,1.1-2.9,0.6c-3.5-1.1-7.4-1.2-12.3-0.2c-2.6,0.5-5,0.8-5.2,0.6c-0.2-0.2,0.1-1.1,0.6-2.1
	c1-2,2.7-2.7,8.9-3.9c4.5-0.9,7.2-1.9,7.1-2.7c0-0.6-6.5,0-8.3,0.9c-0.5,0.2-0.9,0.1-0.9-0.3c0-0.3,0.3-0.6,0.7-0.7
	c0.4,0,2-0.6,3.5-1.3s4.7-2,6.9-3c4.3-1.8,5-3,1.7-2.9c-1.6,0.1-2-0.2-2.5-1.5c-0.3-0.9-0.9-1.5-1.2-1.5c-0.5,0-0.5-0.1,0-0.6
	c0.6-0.7,2.1-0.3,1.7,0.5c-0.5,0.9,4.3,0.4,6.7-0.7c2.8-1.4,3.4-2.5,1.2-2.4c-1.1,0-1.5-0.3-1.9-1.7c-0.5-1.7-0.5-1.8-3-1.3
	c-1.4,0.3-2.4,0.2-2.3,0c0.4-0.7,6.3-2.5,10.2-3.1c4.3-0.6,6.8-3.1,3-2.9c-7.2,0.4-13.8,1.2-14.1,1.7c-0.6,1-2.7,1.5-4.3,1.2
	c-1.5-0.3-1.5-0.4-0.6-1.4c0.5-0.6,1.5-1.1,2.3-1.2c0.7,0,1.3-0.3,1.3-0.7c0-0.3,0.8-1,1.8-1.5c1-0.5,2.2-1.2,2.7-1.4
	c2.5-1.5,3.5-2,4.4-2c0.6,0,1.8-0.4,2.7-0.9c0.9-0.5,4.1-1.2,7.1-1.5c7.6-0.9,13.4-2.5,14.3-4c0.3-0.4,1.1-0.4,2.4,0
	c1.1,0.4,2.3,0.5,2.6,0.3c0.3-0.2,0.7,0,0.9,0.5c0.2,0.5,0.6,0.7,0.9,0.5c0.3-0.2,0.5,0.3,0.6,1.1c0.1,1.3,0.2,1.4,1.8,0.8
	c2.4-0.8,2.5-1,1.2-2.4c-0.8-0.9-1-1.3-0.4-1.5c1.2-0.5,0.7-1.9-0.9-2.2c-1.6-0.3-8.5,0.5-9.4,1.1c-0.3,0.2-3.5,0.6-7,0.9
	c-3.5,0.3-8,0.7-9.8,0.9c-1.9,0.2-6.4,0.7-10,1.1c-9.4,1-12.6,1.7-12.5,2.6c0,0.3-1.9,0.6-4.1,0.7c-7.2,0.4-8.6,0.8-9.7,2.3
	c-0.9,1.3-1.2,1.4-3.4,1c-1.3-0.3-3-0.9-3.8-1.5c-0.8-0.5-2-1-2.6-1c-1.2,0-1.2,0,0.1-0.6c1.8-0.8,5.8-1,7.6-0.4
	c1.8,0.6,4.9,0.1,5.8-1c0.3-0.5,1.2-0.9,1.8-0.9c1.4-0.1,6.2-3.3,5.6-3.9c-0.2-0.2,0.4-0.5,1.3-0.8c0.9-0.2,3.7-1,6.2-1.8
	c2.5-0.8,5.5-1.6,6.7-1.9c1.2-0.3,2.4-0.9,2.6-1.2c0.2-0.4,0.9-0.8,1.6-1c0.7-0.2,1.8-0.6,2.5-1l1.3-0.7l-1.4-0.7
	c-0.8-0.4-2.8-0.7-4.5-0.6c-1.7,0.1-3.9-0.2-4.9-0.6s-2.4-0.6-3-0.4c-3.4,0.7-9.7,0.9-9.9,0.2c-0.2-0.4-0.8-0.7-1.3-0.7
	c-0.6,0-1.2-0.2-1.5-0.5c-0.2-0.3-0.6-0.4-0.9-0.2c-0.3,0.2-0.5,0.1-0.5-0.3c0-0.3,0.4-0.8,0.9-1c0.8-0.4,0.8-0.5-0.2-0.8
	c-1.6-0.5-14.5,0-14.8,0.6c-0.3,0.5-6.2,1.7-13.3,2.7c-3.4,0.5-4.1,0.7-4,1.5c0.1,0.6,1.4,1.2,3.3,1.7c1.7,0.4,3.5,1.1,4.1,1.6
	s1.5,0.7,2.1,0.5c1.4-0.3,8.9,0.7,9.3,1.3c0.2,0.3,0,0.5-0.4,0.5c-1.9,0.2-7.6,0.2-8.7,0c-0.7-0.1-2.5-0.3-4-0.3
	c-1.5,0-3.3-0.5-3.9-1c-1-0.8-1.1-0.8-1.6,0c-0.4,0.8-0.6,0.8-1.2-0.2c-0.7-1-0.6-1.1,0.7-1.5c2.7-0.8,0.2-2-4.1-2.1
	c-3.8,0-4.9-0.3-5-1.1c0-0.7,7.6-2.2,14.5-2.9c3.8-0.3,7-0.8,7.1-1.1c0.1-0.2,1.2-0.5,2.3-0.5c1.1,0,2.9-0.5,4-1.1
	c1.6-0.9,3.6-1.2,10.7-1.5c6.9-0.3,8.8-0.6,8.7-1.2c0-1.1-1.4-1.3-7.2-1.2c-8.4,0.2-14.8,1.4-17.1,3.3c-1.6,1.3-2,1.4-2.6,0.8
	c-1-1.1,0.4-2.4,4.4-4c3.1-1.3,5-2.7,3.2-2.4c-0.4,0.1-2.7,0.2-5.1,0.2l-4.3,0.1l1.8-1.8c1-1,1.8-2.2,1.8-2.6
	c-0.1-1.3-3.5-2.3-9.8-3c-3-0.4-5.7-0.8-6-0.9c-0.3-0.1-2.1-0.3-4.1-0.4c-2-0.1-4.4-0.3-5.4-0.6s-3.6-0.7-5.8-0.9
	c-6.2-0.7-6.7-0.9-6.4-1.5c0.3-0.5,2.4-0.5,6.7,0.1c0.6,0.1,1.1-0.1,1.3-0.4c0.5-0.9-0.8-2-3.3-2.6c-2.8-0.7-3-1.2-0.5-2.2
	c2.5-1,3.6-1,4.1,0c0.2,0.5,1,1.1,1.8,1.3s1.5,0.8,1.5,1.1c0,0.8,1.6,0.7,1.8-0.1c0.1-0.4,0.7-0.5,1.8-0.1c0.9,0.3,2.2,0.5,2.9,0.5
	c1.2-0.1,1.2-0.1,0.1-0.8c-0.6-0.4-2.1-1-3.2-1.3c-5.5-1.5-6.7-2.1-6.6-3.1c0.3-1.9,0.9-2,6.4-0.8c3,0.7,6.2,1.2,7.1,1.1
	c0.9,0,1.6,0.2,1.6,0.4c0,0.7,3.5,1.1,7.4,1c1.9-0.1,3.7,0.2,4.5,0.7c1.1,0.6,2.7,0.7,7.1,0.4c6.5-0.5,7.9-1.3,5.3-2.8
	c-1.4-0.8-2.8-0.9-9.4-0.6c-4.3,0.2-8.7,0.6-9.8,0.9c-1.6,0.4-2.1,0.3-2.8-0.7c-0.7-1-0.6-1.2,0.7-1.5c0.8-0.2,2.1-0.5,2.9-0.8
	c0.8-0.2,4.4-0.5,7.9-0.6c8.8-0.4,9.8-1.3,4.1-3.9c-2.6-1.2-3.7-1.3-8.2-1.1c-2.9,0.2-7,0.2-9.2,0.1c-2.2-0.1-5.7-0.1-7.8,0.1
	c-2.7,0.2-4,0-4.5-0.6c-0.6-0.7-0.5-0.9,0.8-1.4l1.6-0.6l-2.1-0.8c-2.9-1.2-7-1.5-9.6-0.6c-1.2,0.4-3.4,0.9-4.8,1.1
	c-1.4,0.2-3.3,0.5-4.2,0.7c-1.1,0.2-1.5,0.1-1.4-0.4c0.1-0.4,0.7-0.8,1.4-0.9c0.6-0.1,1.3-0.8,1.4-1.4c0.2-1,0.6-1.1,2.3-0.9
	c1.7,0.2,2.2,0.1,3.4-1.3c1.2-1.4,1.6-1.5,3.6-1.3c1.4,0.2,2.4,0,2.7-0.4c0.5-1,2.9-0.9,3.8,0.1c0.5,0.5,1.3,0.7,2.2,0.6
	c0.7-0.2,2.4-0.1,3.7,0.1c2.5,0.5,4.2-0.4,3.6-1.8c-0.2-0.4-0.1-0.8,0.2-0.8c0.3,0,0.7,0.4,0.9,0.8c0.2,0.5,1.1,0.8,2.4,0.8
	c2.4-0.1,2.1-0.9-0.5-1.3c-1.7-0.3-1.8-0.4-1-1.2c0.5-0.6,2-1,4-1.1c1.7-0.1,3.4-0.5,3.8-0.8c0.4-0.3,1.5-0.7,2.6-0.8l1.9-0.2
	l-2.4-3.9c-3.7-6.1-5.1-7.6-7.3-7.5c-1,0-2.5-0.1-3.3-0.3c-1.4-0.4-1.4-0.4,0.3-0.9c1.7-0.5,2.9-1.9,1.9-2.4
	c-1.5-0.7-4.6-0.7-6.1,0.2c-1.6,0.9-2.9,0.9-5.3-0.2c-1-0.4-1.6-0.3-2.9,0.7c-0.9,0.7-1.9,1.3-2.2,1.3c-0.6,0-2.6-3.6-2.7-4.8
	c0-0.5-0.4-0.8-0.9-0.8c-0.5,0-0.9-0.2-0.9-0.4c0-0.2,1-0.4,2.3-0.3c1.4,0.1,2.3-0.1,2.3-0.5c0-0.3,0.2-0.6,0.4-0.6
	c1.1,0.1,1.4-0.2,0.7-1c-0.8-0.9-2.1-1.1-1.5-0.3c0.2,0.3-0.1,0.5-0.7,0.5c-1,0-1-0.2,0.2-2.1c0.7-1.1,1.2-2.3,1.2-2.5
	c0-0.3,0.4-0.5,1-0.5c2.4-0.1,3.1-2.8,0.9-3.2c-0.8-0.2-1.7-1-2.1-1.8c-1.5-3.4-3-4.9-5.5-5.6c-1.4-0.4-2.4-1-2.3-1.3
	c0.3-0.7,2.6-0.8,6.8-0.1c1.8,0.2,3.3,0.3,3.4,0.2s0.2-1.1,0.2-2.2c-0.1-2.6-2.8-5-4.8-4.4c-1.1,0.3-1.8,0-3.4-1.5
	c-1.8-1.8-2.2-1.9-3.6-1.3c-1.8,0.8-2.8,0.2-2.5-1.4c0.1-0.8,0.7-1.1,2.6-1.2c2-0.1,2.4-0.3,2-1c-0.7-1.4-0.8-3.6-0.2-4.5
	c0.4-0.6-0.2-1.4-2-3.1l-2.6-2.3l1.5-0.3c0.8-0.1,1.6-0.6,1.8-1c0.2-0.7-1-4.8-2.6-8.5c-0.4-0.9-0.7-1.9-0.6-2.1
	c0.1-0.2-0.1-0.4-0.5-0.4c-0.3,0-0.6-0.2-0.6-0.5c0-1.1-5.7-9-6.4-8.9c-0.4,0-1.2-0.4-1.7-1c-1-1-1-1.1,0.2-0.8
	c0.7,0.1,1.4,0.5,1.6,0.8c0.4,0.7,2.6,0.5,2.5-0.1c0-0.3,0.4-0.7,1-0.8c0.8-0.2,0.8-0.3-0.2-0.3c-1.5,0-3.2-1.6-3.2-3.1
	c0-0.6-0.7-1.9-1.5-2.8l-1.5-1.8l1.4-0.7c0.8-0.4,1.4-1,1.4-1.3c0-0.8,1.9-0.8,2.8,0c0.6,0.6,0.7,0.5,0.3-0.4
	c-0.3-0.7-0.2-1.2,0.5-1.6c0.5-0.3,0.9-0.9,0.9-1.4c0-0.8,1.1-1.3,1.2-0.5c0.1,1.3,2.8,3.2,5.4,3.7c3.7,0.8,5,0.1,3.4-2
	c-0.5-0.7-0.8-1.4-0.6-1.4c0.2,0-0.3-0.8-1.1-1.8c-1.1-1.4-1.6-2.7-1.9-5.7c-0.3-3-0.3-3.9,0.3-3.9c0.4,0,0.9,0.2,1.1,0.5
	c0.7,1,2.9,0.5,3.6-0.7c0.9-1.7,2.1-1.5,4.2,0.5c1.5,1.5,1.9,1.6,2.3,0.9c0.3-0.5,0.4-2.4,0.2-4.3c-0.4-4.2,0.3-5.1,4-5.4
	c3.6-0.3,5-0.9,5.3-2.3c0.1-0.7-0.2-1.8-0.7-2.5c-0.9-1.2-0.9-1.3,0.1-1.4c1.5-0.1,2.5-1.1,4-3.8c1.4-2.5,1.2-2.8-1.9-4.3
	c-2.4-1.1-2.7-2.5-0.6-2.7c3-0.2,3.4-0.4,3.4-1.4c-0.1-1.2-11.1-10.9-14.3-12.4c-2.6-1.3-5.8-3.5-5-3.5c1.1,0,6,1.4,9.3,2.8
	c1.9,0.8,4,1.5,4.7,1.4c0.9,0,1.2,0.4,1.3,1.6c0.1,1.1,0.9,2.3,2.3,3.6c2.2,2,2.2,2,3.8,1c1.6-1.1,2.9-0.7,3,0.9
	c0.1,1.3,1.9,2.4,3.2,2.1c0.7-0.2,2-0.5,2.9-0.6c2.4-0.3,2.7-2.2,0.4-2.1c-1.2,0-2.3-0.6-3.9-2c-2.9-2.8-3-4-0.2-4.1
	c1.8-0.1,4.9-1.3,3.3-1.3c-0.3,0-0.1-0.3,0.4-0.6c0.5-0.3,1.3-0.5,1.6-0.3c0.3,0.2,0.9,0.1,1.3-0.3c0.4-0.3,2-0.8,3.5-1
	c1.6-0.2,2.9-0.4,3-0.5c0.1,0,0.1-0.6,0.1-1.2c0-1,0.3-1.2,2.6-1.3c1.5-0.1,3-0.5,3.4-1c0.4-0.5,2.4-1.1,4.3-1.4
	c4.1-0.7,7.4-2,7.1-3c-0.1-0.4,0.4-0.8,1.1-0.9c0.7-0.1,1.5-0.6,1.9-1.1c0.4-0.5,1.1-0.9,1.7-0.9c1,0,1-0.1,0-1.1
	c-1.8-1.6-3.1-1.9-3.4-0.5c-0.1,0.7-0.5,1-0.9,0.8c-1.2-0.7-6.1-0.3-6.1,0.5c0,0.6-1.1,0.8-4.5,0.9c-2.5,0.1-4.8,0.3-5.2,0.6
	c-0.7,0.5-0.8-0.2-0.1-1.3c0.3-0.5,1.4-0.8,2.9-0.9c2-0.1,2.3-0.2,1.7-0.9c-0.4-0.4-1.4-0.8-2.3-0.7c-2,0.1-2.4-0.4-1.8-1.8
	c0.4-0.8,0.4-1.3,0-1.5c-0.6-0.3-0.7-0.6-0.8-2c0-1.1-2.1-1.5-4.6-0.8c-1.4,0.4-2,0.9-2.2,1.9c-0.2,1.1-0.7,1.5-2.2,1.7
	c-1.1,0.2-2.2,0.5-2.4,0.8c-0.3,0.3-1.3,0.3-2.4,0c-1.4-0.4-2.6-0.3-4.1,0.2c-1.2,0.4-3.6,1-5.5,1.2c-2.7,0.4-3.6,0.3-4.5-0.5
	c-1.1-0.9-1.1-1,0-2c0.6-0.6,1.9-1.1,3-1.2c1.1,0,2.9-0.5,4-1.1s2.3-0.8,2.7-0.6c0.8,0.4,4-1.1,3.6-1.7c-0.7-1,5.3-2.9,9.1-2.8
	c2.3,0.1,6.3-1.6,6.2-2.5c0-0.5,1-1,2.6-1.3c1.5-0.3,3.2-0.8,3.8-1.2c0.6-0.4,1.6-0.7,2.2-0.7c1,0,4.1-2.5,4.1-3.3
	c0-0.2-0.9-0.7-2-1.1c-1.7-0.6-3.4-2.7-2.7-3.4c0.1-0.1,1.6,0.1,3.4,0.5c1.8,0.4,5.1,0.5,7.5,0.3l4.3-0.4l-0.1-1.8
	c-0.1-1.4-0.4-2-1.5-2.4c-0.8-0.3-2.1-1-2.9-1.5c-0.8-0.5-2.1-0.9-2.9-1c-0.8-0.1-1.9-0.4-2.6-0.7c-1.2-0.6-1.2-0.6,0.3-1.2
	c1.9-0.8,2-1.5,0.2-1.8c-1.8-0.3-7.1-0.6-10.4-0.7c-1.9,0-3.2-0.4-4.1-1.2c-0.8-0.7-1.7-1.2-2.1-1.2c-1.1,0-6.5-2.2-6.5-2.7
	c0-0.8,2.3-0.5,2.7,0.3c0.2,0.5,0.7,0.7,1.2,0.5c0.5-0.2,0.7-0.1,0.4,0.3c-0.2,0.4-0.1,0.5,0.4,0.3c0.4-0.2,1.5,0,2.4,0.4
	c0.9,0.4,2.1,0.6,2.6,0.4c0.5-0.2,3.7-0.5,7-0.7c7-0.4,7.8-0.6,6.8-1.7c-0.4-0.4-0.5-1-0.4-1.2c0.2-0.2,3-0.6,6.2-0.8
	c3.2-0.2,6.1-0.4,6.3-0.5c0.2,0,0.3-0.4,0.1-0.8c-0.6-1.4,1.5-1.7,3.3-0.5c1.5,1,2.4,1.1,9,0.7c4-0.2,9-0.4,11-0.5
	c2.5,0,3.8-0.3,4.1-0.8c0.3-0.6,0.6-0.6,1.2-0.1c0.4,0.4,1.1,0.7,1.6,0.6c0.6,0,0.3-0.5-0.8-1.2c-0.9-0.6-1.9-1.7-2.2-2.4
	c-0.4-1-1.1-1.3-2.7-1.4c-2.6-0.1-2.8-1-0.3-1.8c1.8-0.6,6.4,0.1,6.4,1c0,0.3,1.2,1.6,2.7,3c2.6,2.4,4,3.1,4.6,2
	c0.4-0.6,23.2-2,33.6-2c4.3,0,14.7-1.5,15.6-2.3c2.3-1.7,16.8-0.4,21.7,2c0.8,0.4,1.6,1.3,1.7,2.1c0.1,0.8,0.6,1.6,0.9,1.9
	c1.5,1,5.4,1.6,7.1,1c1.2-0.4,2.1-0.4,3.1,0.1c0.8,0.4,2.8,0.7,4.4,0.6c1.6-0.1,3.9,0.2,5.1,0.7c1.2,0.4,3.7,0.9,5.7,0.9
	c2,0.1,4.4,0.5,5.4,0.8s2.9,1,4.4,1.3c2.2,0.6,2.7,0.9,3,2.4c0.3,1.5,0.6,1.7,2.5,1.8c1.2,0,2.4,0.4,2.8,0.9
	c0.4,0.4,1.1,0.8,1.5,0.8c0.4,0,1.1,0.5,1.4,1.1c0.4,0.6,1.3,1.1,2,1.1c0.8,0,1.6,0.2,1.8,0.6c0.5,0.7,8.6,4.2,9.6,4.1
	c0.4,0,2.1,0.6,3.8,1.3c2.5,1,3.2,1.7,3.6,3c0.6,2.2,2.6,4.5,3.9,4.4c0.6,0,1.2,0.5,1.4,1.1c0.2,0.6,1.3,1.5,2.4,2
	c1.1,0.5,2.5,1.6,3.1,2.5s1.8,2,2.8,2.4s1.7,1.2,1.8,1.6c0,0.4,0.7,1.2,1.5,1.7c0.9,0.5,1.5,1.4,1.5,2.3c0,0.8,0.5,1.7,1,2.1
	c2.6,2,5.1,4.6,5,5.3c-0.2,1,1.1,2.8,3.3,4.6c2.5,2.1,2.6,2.7,0.5,5.8c-1,1.6-1,1.6,0.4,6c0.8,2.5,1.7,4.9,2,5.4
	c0.3,0.5,0.6,2.2,0.6,3.8c0.1,3.4,0.5,4.1,2,4c1.4-0.1,1.5,0.6,0,1.6c-1.2,0.9-1.4,3.2-0.3,5.6c0.5,1.1,0.5,1.9-0.1,3.2
	c-1,2.2-0.5,3.3,1.5,3.6c0.8,0.1,2.8,0.6,4.3,1c1.5,0.4,3,0.7,3.2,0.7c0.7,0,1.7-2.7,1.7-4.2c0-1,0.1-1.1,0.9-0.5
	c1.3,1,1.5,0.5,0.5-1.6c-0.9-1.9-2.4-2.3-3.6-1.1c-1.4,1.5-2-0.1-1.9-5.2c0.1-5.3,0.3-6.3,1.3-6.3c0.3,0,0.4,0.2,0.2,0.6
	c-0.2,0.3,0.1,0.6,0.5,0.6c0.5,0,1.4-0.9,2-2s1.6-2.2,2.3-2.4c1-0.3,1.1-0.6,0.7-2.4c-0.6-2.7,0.7-2.8,3-0.2c2,2.3,3.1,2.3,6.8,0.5
	c2.9-1.5,3.5-2.4,2.6-4.3c-0.4-0.9-0.5-2.2-0.2-4.2c0.4-2.6,0.3-3.3-1-5.7c-3.3-6.2-3.3-6.1-2.3-5.4c0.9,0.7,2.1,0.3,2.1-0.7
	c0-0.3-1.2-1.7-2.7-3.2c-2.5-2.4-2.6-2.6-1.4-2.8c1.2-0.2,2.6,0.8,4,3.1c0.5,0.7,0.7,0.7,1.8-0.2c1.8-1.4,3.3-1.4,3.4,0.1
	c0.1,1.6,0.9,2.9,3.1,4.9c1.1,1,1.8,2.3,1.9,3.1c0.1,1.7,1,2.4,2.1,1.4c1.2-1.1,4.3,0,4.4,1.7c0,0.2-0.8,0.8-1.8,1.3
	c-1,0.5-2.3,1.5-2.9,2.2c-1.1,1.3-1.1,1.3,0.2,1.6c0.7,0.1,1.3,0.8,1.5,1.5c0.1,0.7,0.7,1.3,1.4,1.3c0.9,0.1,1.2,0.6,1.3,2.2
	c0.3,3.8,3.9,5.1,7.5,2.7c1.4-0.9,2.2-1.1,3.2-0.8c0.8,0.3,2.9,0.3,4.8,0.2c3.2-0.3,3.5-0.4,5.3-2.8c1.1-1.4,2.6-3,3.4-3.6
	c4.6-3.3,7.6-5.7,8.7-7.1c1.8-2.1,2.5-2,2.6,0.4c0.1,1.5-0.2,2.2-1.3,2.8c-5.6,3.1-12.2,10.5-12.1,13.4c0,0.8-0.2,1.4-0.6,1.4
	c-0.3,0-1,0.4-1.4,0.9c-0.4,0.5-1.2,0.7-1.7,0.6c-1.1-0.4-3.8,2.5-3.4,3.6c0.2,0.5,1.5,0.9,3.1,1c1.5,0.1,4.6,0.4,6.8,0.6
	c9.1,0.9,12.9,1.2,15.9,1.4c4.5,0.3,5.9,0.5,5.6,1.1c-0.1,0.3-1.8,0.4-3.6,0.2c-5.8-0.5-14.2-0.4-15.6,0.2c-1.1,0.5-1.2,0.7-0.6,1.4
	c0.9,1,2.4,1,3.3,0c0.5-0.5,0.7-0.5,0.9,0.1c1.2,3.8,1.5,4.2,2.9,4.3c0.8,0.1,1.7,0.3,1.9,0.6s1.9,0,3.7-0.6
	c3.6-1.1,5.6-1.3,6.8-0.4c0.4,0.3,2.4,0.5,4.3,0.4c2.2-0.1,4.2,0.1,5.3,0.6c1.4,0.7,2.7,0.7,6.3,0.2c2.5-0.3,5.8-0.5,7.4-0.3
	c2.5,0.2,2.9,0.4,2.7,1.4c-0.2,0.9,0.1,1.1,1.8,1c1.1,0,2.5,0.3,3.2,0.8c1,0.8,1.4,0.8,3.8,0.1c5.8-1.8,7.8-2,9.6-1.3
	c1.6,0.6,7.8,1.9,11,2.3c0.6,0.1,2.2-0.3,3.4-0.8c2.8-1.1,7.3-1.6,25.5-2.7c7.7-0.5,14.7-1,15.5-1.1c0.8-0.1,2.1,0,3,0.4
	c0.8,0.4,1.7,0.5,1.9,0.3s2.5-0.5,5.1-0.7c11-0.6,10.8-0.6,10.6-2c-0.2-1.1,0.1-1.3,2.3-1.5c2.7-0.3,5.2,0.5,5.2,1.6
	c0,0.8,2.3,1.4,3,0.9c0.9-0.6,0.4-1.3-0.9-1.5c-1.3-0.1-1.3-0.2-0.2-0.8c0.6-0.4,5.1-1,10-1.5c4.9-0.4,9.5-0.9,10.3-1
	c0.8-0.1,2.2-0.3,3.3-0.4c1-0.1,2.2-0.6,2.6-1c0.7-0.7,1.4-0.8,4.5-0.4c3.4,0.4,17.6-0.5,22-1.3c3.7-0.7,13.7-1.6,13.3-1.1
	c-0.2,0.3-1.6,0.5-3,0.5c-1.4,0-2.6,0.3-2.6,0.6c0,0.3-0.8,0.6-1.9,0.7c-1.1,0-3.3,0.4-5,0.8c-1.7,0.4-3.8,0.9-4.8,1.1
	c-0.9,0.2-2.1,0.5-2.6,0.6c-0.5,0.1-1.7,0.3-2.8,0.3c-1.1,0.1-1.7,0.4-1.5,0.7c0.4,0.6,8.2-0.1,11.2-1c1.6-0.5,12.7-2.1,16-2.4
	c11.7-0.8,23.1-1.9,24.8-2.4c1.2-0.4,5.4-0.9,9.4-1.2c4-0.3,8.6-0.7,10.1-1c1.6-0.3,3.9-0.7,5.3-0.9c1.6-0.3,2.4-0.7,2.4-1.3
	c0-0.5,0.4-0.8,1-0.7c0.7,0.1,1-0.2,1-1c0-0.8-0.4-1.1-1.1-0.9c-0.6,0.1-1-0.1-0.9-0.5c0.4-1.2,5-2.4,12-3.1c8-0.7,7.9-0.7,11.4-2.4
	c1.5-0.7,3.9-1.4,5.4-1.5c1.5-0.1,4.1-0.5,5.8-1c2.9-0.8,8-0.8,8.9,0c0.2,0.2-0.9,0.6-2.4,0.8c-1.5,0.3-3.6,0.9-4.7,1.4
	c-1,0.5-2.8,0.9-4,0.9c-1.2,0-3.4,0.4-4.9,0.8c-1.6,0.4-5,1.1-7.7,1.5c-2.7,0.4-4.9,0.9-5,1.2c-0.1,0.3-1.6,0.5-3.4,0.5
	c-4.2,0-5.2,0.6-4.1,2.5c0.6,1.1,1.2,1.3,3,1.2c1.2-0.1,2.4-0.3,2.6-0.6c0.2-0.2,1.2-0.5,2.3-0.5c1.1,0,1.9-0.3,1.9-0.6
	c0-0.3,0.6-0.4,1.6-0.1c0.9,0.3,2.4,0.4,3.3,0.2c2.5-0.4,6.5-1.9,6.5-2.5c0-0.3,0.6-0.5,1.3-0.5c1.1,0,1.1,0.1,0.5,0.6
	c-0.6,0.5,1.2,0.5,6.2,0c4.1-0.4,7.4-0.9,7.9-1.3c0.4-0.4,3.2-0.9,6.1-1.2c3.5-0.3,6.1-0.9,7.5-1.7c1.3-0.7,3.5-1.2,5.4-1.3
	c1.8-0.1,3.9-0.6,4.9-1.1c0.9-0.6,3.2-1.1,5.1-1.3s3.7-0.4,4.1-0.7c0.6-0.4,1.3-0.6,11.3-2.3c3.5-0.6,8.3-1.5,10.7-1.9
	c2.4-0.4,9.3-2.3,15.4-4.1c6-1.8,11.7-3.5,12.7-3.7c0.9-0.2,3.2-0.7,5.1-1.2c1.9-0.4,4.1-0.9,4.8-1.1c0.8-0.2,3.3-0.7,5.5-1.1
	c3.8-0.7,11-2.9,22.5-7.1c2.5-0.9,6.3-2.6,8.5-3.7c6.6-3.5,7.1-3.6,7.1-2.2c0,1.1,0.3,1.2,2.6,0.7c1.4-0.3,4.5-1.3,6.8-2.4
	c2.4-1,4.7-1.9,5.1-1.9c0.4,0,1.3-0.6,2-1.2s1.8-1.2,2.5-1.3c1.6-0.1,3.7-1.7,6.6-5c1.7-2,2.9-2.9,4-2.9c1.7-0.1,1.8,0.1,1,1.8
	c-0.9,2-1.1,4.4-0.2,4.3c2.3-0.2,2.8,0.1,2.8,1.7c0.1,1.4,0.3,1.6,1.3,1.3c0.6-0.2,1.4-0.8,1.7-1.4c0.4-0.8,1-0.9,2.7-0.7
	c2.4,0.3,4.1-0.2,5.8-1.9c0.6-0.6,1.5-1.1,1.8-0.9s1.8-0.8,3.1-2.1c1.9-1.8,2.7-2.3,3.4-1.9c1.8,0.9,4.1-0.8,9.1-6.7
	c0.6-0.7,1.3-1.3,1.5-1.3c0.7,0,0.7,2.1,0,2.6c-1.2,0.8-4.3,6.9-4,7.7c0.3,0.8,0.5,0.8,1.7-0.1c2-1.5,3.1-1.3,3.2,0.4
	c0,0.8-0.2,2-0.5,2.7c-0.8,1.7,0.3,2.6,2.1,1.6c0.8-0.4,1.4-0.5,1.4-0.3c0,0.7,7.1-0.3,8.3-1.2c0.6-0.5,1.6-0.9,2.1-0.9
	c1.2-0.1,3-1.1,4.7-2.7c0.7-0.7,1.6-1,2.3-0.8c1.4,0.4,4.1-0.9,4.1-2c0-0.4,0.3-0.6,0.6-0.4s0.6,1.1,0.5,2.1c-0.1,0.9,0.1,1.8,0.3,2
	c0.9,0.5,3.9-0.6,3.5-1.2c-0.2-0.4-0.1-0.4,0.3-0.2c0.4,0.2,1.4-0.4,2.3-1.4c1.5-1.7,3.9-2.1,3-0.6c-0.2,0.4,0,0.5,0.4,0.3
	c0.4-0.2,2.2-0.9,3.9-1.6c2.5-1,3.3-1.7,4.4-3.7c1.3-2.3,2.8-3.4,2.8-2c0,0.3,2-0.5,4.4-1.9c5-2.9,5.5-3,5.9-1.5
	c0.3,1.1-0.4,2.4-1.3,2.4c-0.8,0-8.4,5.9-9.8,7.6c-0.7,0.9-1.6,1.6-1.8,1.6c-0.3,0-1.3,0.8-2.3,1.8c-1,1-2.1,1.8-2.4,1.8
	c-0.4,0-0.7,0.2-0.8,0.5c-0.1,0.3-1.6,1.1-3.3,1.9c-3.1,1.5-6.2,4-6.1,5.2c0,0.3-1.1,1.4-2.5,2.4c-1.4,1-2.5,2-2.5,2.3
	c0,0.3-0.4,0.5-0.9,0.6c-0.5,0-1.5,0.6-2.1,1.2c-0.6,0.6-2.7,1.5-4.5,1.9s-3.8,1.1-4.2,1.6c-1.5,1.4-8,4.7-12.1,6.1
	c-4.7,1.7-5.4,2.2-5,3.7c0.1,0.6,0,1.1-0.3,1.1c-0.3,0-0.5,0.4-0.5,1c0,1,0.4,1,5.1-1.2c3.9-1.8,6.8-2.4,6.8-1.4
	c0,0.9,4.2,0.1,6.5-1.3c1.5-0.9,10.7-4.6,11.3-4.7c0.2,0-0.6,0.9-1.8,2c-1.9,1.8-4.7,3.8-9.2,6.4c-1.3,0.7-1.5,1.2-1.3,2.6
	c0.2,1.2-0.1,1.9-0.9,2.4c-2.1,1.3-2.5,2.4-2,5.1c0.3,1.4,0.6,2.8,0.7,3c0.4,0.7,2.5-0.8,2.4-1.7c0-0.5,0.2-0.9,0.4-0.9
	c0.2,0,1.2-0.7,2-1.5c1.5-1.4,2.2-1.8,2.8-1.4c0.1,0.1,1.1-1.1,2.3-2.5c1.1-1.5,2.3-2.8,2.6-3c0.3-0.2,0.9-1.2,1.4-2.2
	c1.3-2.6,3.8-6.7,4.9-8c0.6-0.7,0.8-1.8,0.7-2.7c-0.2-1.4,0-1.7,2.3-2.6c1.9-0.8,2.5-0.9,2.5-0.3c0,0.4-0.3,1.1-0.8,1.5
	c-0.5,0.4-0.8,1.2-0.8,1.7c0,0.5-0.3,1.5-0.8,2.3s-0.7,1.3-0.5,1.3c0.2,0-0.2,1.2-1,2.6c-0.9,1.7-1.2,2.7-0.8,3
	c0.3,0.2,0.9-0.1,1.3-0.7c1-1.5,2.9-1,3.1,0.7c0.2,1.1-0.3,1.7-1.6,2.7c-1.6,1.1-3.1,4.1-2.4,4.7c0.1,0.1,1.1,0,2-0.4
	c2.6-0.9,3.8,0.9,3.1,4.4c-0.1,0.5,0.3,0.9,1.1,1c1.3,0.1,4.1-2.6,4.1-3.9c0-0.8,1.5-1.6,2.3-1.2c0.3,0.2,0,1.9-0.6,3.8
	c-0.7,2.2-0.9,3.7-0.5,4c0.3,0.3,2.1,0.5,4,0.6c2.9,0.1,3.7-0.1,5.1-1.2c1.5-1.2,3-1.5,8-1.9c1-0.1,2,0.3,2.5,0.9
	c0.4,0.5,0.8,0.7,0.8,0.3c0-0.7,0.9-1.1,3.9-1.8c1.5-0.3,2-0.2,2.2,0.6c0.6,1.8,4.1,2.3,7.8,1.1c3.2-1,5.4-0.9,4.8,0.2
	c-0.2,0.3-0.9,0.6-1.7,0.7c-0.9,0-1.3,0.4-1.2,1.1c0.1,0.8-0.4,1.1-2.2,1.3c-1.3,0.2-2.5,0.5-2.8,0.8s-1.1,0.6-1.8,0.6
	c-0.7,0-1.6,0.7-2.1,1.5c-0.5,0.8-1.3,1.6-1.9,1.8c-0.7,0.2-1,0.8-0.9,1.7c0.1,0.7,0.2,1.5,0.3,1.7c0.2,1.2,6.9-0.4,6.9-1.7
	c0-0.3,0.3-0.5,0.7-0.5c1.3-0.1,5.3-4.2,6.2-6.3c0.5-1.1,1.2-2,1.6-2c0.4,0,0.8-0.2,0.8-0.5c0-0.7,3.4-2.6,4-2.3
	c0.8,0.4,0.7,1.2-0.4,3.7l-1,2.3l1.7,0.3c0.9,0.2,3.8,0,6.3-0.3c3.6-0.5,5.3-1.1,8.3-2.8c2.1-1.2,3.9-2.4,4-2.6
	c0.4-0.6,2.8-2,2.8-1.6c0,0.9-0.7,2.1-1.3,2.1c-0.4,0-0.9,0.4-1,0.9c-0.2,0.5-0.8,0.9-1.5,0.9c-0.7,0-1.2,0.4-1.1,0.9
	c0,0.5-0.3,0.9-0.8,0.9c-0.5,0-1.7,0.9-2.7,1.8c-1,1-2.2,1.8-2.5,1.8c-0.4,0-1.6,0.7-2.7,1.5c-1.1,0.8-2.7,1.7-3.4,1.9
	c-0.7,0.3-1.6,0.8-2,1.2c-0.4,0.4-2.2,1.5-3.9,2.5c-2.1,1.1-3,2-2.5,2.2c0.4,0.2,1.6-0.1,2.7-0.7c3.1-1.8,5.7-2.3,5.7-1.2
	c0,0.5-0.2,0.9-0.5,0.9c-0.3,0-0.7,0.5-0.8,1.1c-0.3,1-1.5,1.7-10.2,6c-3.2,1.6-4,2.4-4.9,5.4c-0.9,2.9-1.1,4.3-0.8,4.3
	c1.9-0.4-0.1,2-3.5,4.1c-1.8,1.1-4,5.1-4.7,8.6c-0.2,0.8-0.9,2-1.7,2.5c-1.1,0.8-1.3,1.3-1,3c0.3,1.7,0.1,2.1-0.9,2.6
	c-2.2,0.9-7.4,7.1-8.8,10.4c-1.4,3.2-3.3,5-4.9,4.4c-1.2-0.4-1.2-2,0-4.2c0.6-1.1,0.8-2,0.5-2.2c-0.7-0.4-5.3,0.4-8.1,1.3
	c-2.4,0.8-2.8,2.3-0.7,2.2c0.8,0,2,0.4,2.8,1c1.2,0.9,1.3,1.2,0.7,2c-0.4,0.5-0.9,0.8-1.2,0.7c-0.8-0.5-6.7,1.8-8,3.2
	c-0.7,0.7-2.4,1.8-3.8,2.5c-1.4,0.6-2.8,1.6-3.2,2.3c-0.4,0.6-1.1,1.2-1.5,1.2c-0.4,0-1,0.3-1.2,0.7c-0.3,0.5-0.1,0.5,0.4,0.2
	c0.4-0.3,0.2,0.2-0.5,1c-0.9,1.1-1.1,1.6-0.5,1.8c0.4,0.1,0.9,0.9,1,1.8c0.1,1.1-0.2,1.7-1.2,2.2c-0.8,0.4-1.4,0.9-1.4,1.2
	c0,0.2-0.6,1.2-1.4,2.1c-0.8,0.9-1.8,2.2-2.4,2.9c-0.5,0.7-1.6,2.1-2.4,3.3l-1.4,2.1l1.6,1.2l1.6,1.2l3.6-4c2-2.2,3.8-4.6,4.1-5.2
	c0.3-0.7,1.2-1.6,2.1-2c0.8-0.4,1.5-1,1.5-1.3c0-0.6,2.6-1.9,4-2c0.7,0,1,0.2,0.8,0.8c-0.2,0.5,0,0.9,0.3,0.9
	c1.2-0.1,2.4-0.8,2.3-1.6c0-0.4,0.5-0.9,1.1-1.2c0.6-0.2,1.1-0.8,1.1-1.2c0-0.5,0.2-0.8,0.5-0.8c0.8,0,2.8-3.3,3-4.9
	c0.1-0.7,0.9-1.9,1.7-2.6c0.8-0.7,1.4-1.5,1.2-1.7c-0.5-0.5,2.3-3.8,3.3-3.9c0.7,0,0.7,0.2-0.1,1.1c-0.5,0.6-0.7,1.3-0.5,1.5
	c0.2,0.2,0,0.3-0.5,0.1c-0.7-0.2-1,0.1-1.2,1.2c-0.2,0.8-0.2,1.7,0,2c0.2,0.2-0.8,1.7-2.1,3.2c-1.3,1.5-2.8,3.6-3.2,4.7
	c-1.2,2.7-1.8,3.9-4.4,7.4c-1.3,1.7-2.3,3.5-2.3,4c0,0.4-0.3,1-0.7,1.3c-0.4,0.3-1,1.2-1.4,2c-0.4,0.8-1.6,2-2.6,2.6s-1.9,1.5-1.9,2
	c0,0.7-0.1,0.7-0.9,0.1c-1.1-0.8-4.9,0.3-6.5,1.9c-1.4,1.4-2.8,2.4-4.7,3.3c-1.2,0.6-1.4,0.9-1,1.7c0.4,0.7,0.3,1.6-0.4,3
	c-0.5,1.1-1.1,1.9-1.3,1.7c-0.5-0.5-2.6,2-2.6,3c0,0.5-0.5,1.2-1.2,1.4c-0.7,0.2-2,1.5-2.9,2.7c-2.3,3.2-6,5.7-4.7,3.1
	c0.3-0.7,0.4-1.4,0.2-1.7s-0.2-0.5,0.1-0.5c0.8,0.2,3.5-1.5,3.2-2c-0.2-0.3,0.1-0.5,0.6-0.5c0.5,0,0.9-0.4,0.9-0.9
	c-0.1-1.6,1.6-4.7,2.8-5.4c0.7-0.3,1.6-1.5,2.2-2.5l1-1.9l-1.7,0.1c-2.1,0.1-5.3,1.3-5.2,2c0,0.3-0.3,0.5-0.7,0.4
	c-0.8-0.1-5.6,1.5-6.1,2.1c-0.9,1-9.6,4.6-11.9,4.9c-1.8,0.2-3.4,1.1-3.1,1.6c0.2,0.3,0.1,0.5-0.3,0.5c-1.2,0-1.5,0.2-1.8,0.7
	c-0.2,0.3-0.9,0.4-1.7,0.1c-0.8-0.3-2-0.2-3,0.2c-1.8,0.8-2,1.3-0.7,1.7c0.5,0.2,0.9,0.7,0.9,1.1c0,0.4,0.4,0.9,0.9,1.1
	c0.5,0.2,0.9,0.6,0.9,0.9c0,0.9-5.4,3.5-10.1,4.8c-2.3,0.6-5.5,1.8-7,2.6c-1.5,0.8-3.2,1.5-3.7,1.5c-0.6,0-1.3,0.5-1.7,1
	c-0.5,0.6-1.6,0.9-3.9,0.9c-3.4,0-4.6,0.5-4.6,1.7c0.1,1.2-2.6,2.9-6.8,4.5c-4.3,1.6-9,4.5-8.7,5.4c0.1,0.3,1.2,0.5,2.5,0.3
	s1.9-0.1,1.4,0.1c-3.4,1.4-5.2,2.5-5.9,3.8c-0.5,0.8-1.2,1.4-1.5,1.3c-0.4,0-1.4,0.3-2.3,0.7c-0.9,0.4-1.8,0.7-2.1,0.8
	c-1.6,0.1-5.7,3-6.2,4.4c-0.3,0.9-0.8,1.5-1.1,1.3c-0.3-0.1-0.5,0-0.4,0.3c0,0.3-0.3,0.5-0.8,0.3c-0.5-0.2-0.9,0.2-1.1,1.1
	c-0.2,0.7-0.9,1.6-1.6,2c-0.7,0.4-1.3,1.1-1.2,1.8c0.1,1.6-2.1,2.6-5.1,2.4c-2.7-0.2-3.7,0.7-2.2,2c1.3,1.1,0.7,2-1.1,1.7
	c-1-0.2-1.4-0.1-1.4,0.5c0.1,1.4-2.6,4.1-4,4c-2.1-0.1-2.5,0.1-2.6,1c0,0.5-0.1,1.2-0.1,1.7c0,0.5-0.3,0.7-0.6,0.6
	c-0.3-0.2-0.5,0.1-0.5,0.6c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.8,0.3-0.6,0.5c0.2,0.3-0.3,0.8-1.1,1.3c-0.8,0.5-1.4,1-1.4,1.3
	c0,0.2-0.9,0.3-2,0.2c-1.4-0.2-2.4,0.1-3.6,1c-1.9,1.5-8.1,3.1-8.1,2.1c0-0.3-0.9-0.5-2-0.4c-1.6,0.1-2,0.3-2,1.2
	c0,0.6-0.2,1.2-0.5,1.2c-0.3,0-0.6,0.5-0.5,1.2s0.3,1.1,0.6,1.1c0.3,0,0.7,0.4,0.9,0.9c0.5,1.2-1.6,3.7-3.2,3.8
	c-0.7,0-1.6,0.8-2.3,1.8c-0.6,1-1.5,1.8-2,1.8c-0.5,0-1,0.2-1.1,0.5c-0.1,0.2-1.1,0.9-2.1,1.4s-3.6,1.9-5.6,2.9s-4.9,2.1-6.4,2.4
	c-2.8,0.5-8.5,3.1-9.1,4.2c-0.2,0.3-0.9,0.6-1.5,0.7c-0.6,0-1.3,0.4-1.4,0.9s-1.3,1.2-2.5,1.6c-1.2,0.4-2.1,0.9-1.9,1.2
	c0.5,0.7,9.5-0.2,13.2-1.4c6.6-2.1,12.3-4.3,14-5.5c1-0.6,3.9-1.5,6.4-1.9c2.5-0.4,5.3-1.1,6.1-1.4c0.8-0.4,2.1-0.8,2.8-1
	c2.2-0.5,9-3.4,9.4-4c0.8-1.2,5.2-3.3,7-3.4c1.1,0,2.4-0.5,3-1c0.6-0.5,1.5-0.9,2-1c0.5,0,1.2-0.4,1.6-0.9c0.3-0.5,1.9-1.3,3.5-1.9
	c1.6-0.6,4.4-2.1,6.3-3.4c3.9-2.6,13.9-8.2,15-8.5c2.3-0.5,5.5-2,6.7-3.2c0.8-0.8,2.9-2.2,4.7-3.3c1.9-1,5.5-3.4,8.1-5.3
	c2.6-1.9,5-3.4,5.4-3.4c0.4,0,1-0.5,1.4-1c0.4-0.5,2.3-1.8,4.2-2.8c1.9-1,4.5-2.9,5.9-4.3c2.4-2.5,14.4-11.5,15.3-11.5
	c0.3,0,1.4-1.1,2.5-2.4c1.2-1.4,3.1-2.7,4.8-3.4c1.5-0.6,3.5-1.8,4.4-2.8c0.9-0.9,2.2-1.8,2.8-2s1.1-0.6,1.1-0.9
	c-0.1-1.4,0.9-1.5,2.3-0.2l1.4,1.4l-1.9,1.8c-1.1,1-2.8,2.6-3.8,3.7s-2,2-2.2,2c-0.7,0-2.4,2.8-2.4,3.9c0,1.5-1.6,3.2-3.1,3.2
	c-0.6,0-1.6,0.6-2.1,1.2c-0.5,0.6-1.7,1.5-2.6,1.9s-1.6,1.1-1.6,1.5c0,1-3.2,4.3-4.7,4.8c-0.6,0.2-1.6,0.8-2.1,1.3
	c-0.5,0.5-1,0.7-1,0.5c0-0.2-0.9,0.3-1.9,1.1c-1,0.9-2.1,1.6-2.3,1.6c-0.2,0-1.9,1.1-3.8,2.5s-3.7,2.5-4.1,2.5c-1,0-4.2,1.9-4.2,2.5
	c0,0.3-0.7,0.7-1.5,0.9s-4.4,2-7.8,3.9c-3.4,1.9-6.7,3.4-7.2,3.4c-0.6,0-1.6,0.7-2.2,1.5c-0.7,0.8-2.2,2-3.4,2.7
	c-3.4,2.1-4.3,2.7-5.4,4.2c-0.6,0.7-1.2,1.2-1.5,1.1c-0.2-0.1-1.3,0.4-2.3,1.2c-1,0.8-2.2,1.5-2.6,1.5c-0.4,0-2,1.2-3.7,2.6
	c-1.6,1.4-3.6,2.9-4.4,3.4c-0.8,0.4-2.5,1.5-3.9,2.4c-4.4,2.7-8.8,5.2-9.5,5.2c-0.4,0-1.4,0.6-2.3,1.3c-0.9,0.7-2.9,1.7-4.6,2.3
	c-1.6,0.6-3.4,1.4-3.8,1.8c-1.5,1.4-8.7,3.7-13,4.1c-2.7,0.3-4.6,0.8-5.2,1.4c-0.5,0.5-1.5,1-2.3,1.1c-0.7,0.1-1.7,0.4-2.2,0.6
	c-4.5,1.8-13.4,3.9-16.9,4c-1.3,0.1-3,0.4-3.8,0.7c-0.8,0.3-4.1,0.9-7.6,1.2c-3.4,0.4-6.6,0.9-7.1,1.2c-2.5,1.5-6,2.6-8.5,2.7
	c-1.5,0.1-3.7,0.6-4.9,1.1c-2.3,1.1-9,2.9-12.8,3.5l-2.6,0.4l2-1.2c1.2-0.7,2.6-1.1,3.4-1c1.4,0.2,1.4,0.2,0.2-0.7
	c-1.3-1-4.3-0.7-8.1,0.8c-1.8,0.7-1.9,0.9-1.1,1.5c1.5,1.2,1.2,1.6-2,2.4c-7.2,1.7-8.8,1.8-11.1,0.5c-2.1-1.2-3.1-1.2-8.6,0.1
	c-0.9,0.2-2.3,0.5-3,0.6c-0.7,0.1-1.5,0.5-1.7,0.9c-0.2,0.3-1.4,0.8-2.6,1.1c-2.6,0.5-2.6,0.5-8.9,0.7c-2.5,0.1-4.8,0.3-4.9,0.4
	c-0.2,0.2-1.3,0-2.4-0.3c-2.8-0.8-6.3-0.2-5.5,1c1.3,1.9,9,3,8.2,1.2c-0.2-0.4,0.1-0.8,0.8-0.9c0.6-0.1,1.3,0.2,1.5,0.7
	c0.2,0.4,0.8,0.8,1.2,0.8c0.8,0,0.7,0.1-0.1,1.1c-0.9,1.1-0.9,1.2,3.1,1c2.2-0.1,4.6,0.1,5.2,0.4c0.6,0.3,3.1,0.5,5.5,0.4
	c3.4-0.1,4.3-0.4,4.6-1.1c0.3-0.8,1.3-0.9,7.4-0.9c12.9,0,14.9,0.3,16.4,1.9l1.4,1.4l-4.1,0.8c-2.3,0.4-4.8,0.6-5.8,0.4
	c-2.3-0.5-19.7,0.8-22,1.7c-1.9,0.7-3.9,1-17.9,2.4c-4.9,0.5-10.2,1.3-11.8,1.9c-1.7,0.6-3.2,0.8-3.7,0.5c-1.3-0.7-4.9-0.4-5.6,0.6
	c-0.4,0.6-1.2,0.8-2.1,0.6c-1.4-0.3-4.2,0.9-4.1,1.8c0,0.6,2.5,1.4,5,1.6c2.3,0.2,7.9,2.4,7.9,3.1c0,0.3-2.2,0.5-5,0.4
	c-19.8-0.1-25.5,0.2-25.4,1.6c0,1.1-5,1.5-11.2,0.8c-2.9-0.3-6.1-0.5-7.2-0.5c-1.1,0-6.5,0-12.1,0c-10.2-0.1-16.4,0.5-15.7,1.5
	c0.2,0.3-0.4,0.5-1.6,0.5c-3.8,0-4.5,0.1-4.1,1.1c0.2,0.6,0.1,0.8-0.3,0.7c-0.4-0.1-0.7-0.5-0.7-0.8c-0.1-0.6-5.1-1.7-7.2-1.7
	c-1.1,0-1.1,0.1-0.1,0.8c0.7,0.5,0.9,1.1,0.7,1.7c-0.3,0.8-0.8,1-2.7,0.7c-4.7-0.6-8.6-0.5-9.8,0.2c-0.7,0.4-2.3,0.8-3.8,1
	c-3.3,0.4-8.9,1.1-8.9,1.2c0,0-0.6,0.2-1.4,0.4c-0.8,0.2-1.5,0.6-1.6,0.9c-0.1,0.3-1.4,0.6-2.8,0.7c-3,0.1-4.2,0.8-3.9,1.9
	c0.1,0.4-0.1,0.9-0.6,1.1c-0.5,0.2-0.6,0.1-0.4-0.3c0.4-0.7-1.8-2-2.6-1.4c-0.5,0.3-0.9-1.2-0.7-3c0.1-0.9-0.1-0.9-4.3,0.1
	c-3.8,0.8-8.2,2.6-8.2,3.2c0,0.9-3.1,1.9-7.8,2.4c-2.7,0.3-6,1-7.2,1.5c-1.2,0.5-2.8,1-3.4,1.1c-0.6,0.1-2,0.7-3.1,1.3
	c-1.4,0.8-4.8,1.3-12.9,2.1c-6,0.6-11.9,1.4-13.2,1.8c-1.2,0.4-3.9,1.1-6,1.7c-5.9,1.5-6.8,1.8-7.1,2.4c-0.2,0.3-1.3,0.6-2.4,0.6
	c-1.2,0.1-2.4,0.4-2.7,0.7c-0.3,0.4-2.1,0.6-4.1,0.6c-1.9,0-3.5,0.1-3.5,0.4C412.1,364,409.7,364.3,409.2,363.9L409.2,363.9z
	 M506.1,342.1c0.7-0.5,0.7-0.7,0-1.1c-1-0.6-3.7-0.6-4.2-0.1c-0.2,0.2-0.2,0.7,0,1.2C502.3,343,504.9,343,506.1,342.1L506.1,342.1z
	 M610.4,332.7c1.7-0.1,3.6,0,4.2,0.2c0.6,0.3,1,0.2,1-0.2c0-0.3-0.5-0.8-1-0.9c-0.5-0.2-1.6-0.9-2.2-1.6c-1-1-2.2-1.3-6.7-1.6
	c-3-0.2-6.7-0.5-8.2-0.7c-1.5-0.2-2.6-0.1-2.4,0.1c0.2,0.3-0.7,0.5-2,0.6c-1.3,0.1-2.3,0.3-2.3,0.6c0.1,1.2,1.9,2.1,4,2.1
	c1.3,0,3.1,0.3,4.1,0.8c1,0.5,2.1,0.7,2.4,0.5c0.3-0.2,0.9-0.1,1.3,0.1c1,0.7,2.9,1.1,3.9,0.7C606.9,333.1,608.7,332.8,610.4,332.7
	L610.4,332.7z M646,317c0.6-0.5,0.5-0.6-0.8-0.5c-0.9,0-1.5,0.3-1.2,0.6C644.5,317.8,644.9,317.8,646,317L646,317z M550.1,319.1
	c-0.4-0.1-0.9-0.1-1.1,0.1c-0.2,0.2,0.2,0.3,0.8,0.3C550.4,319.4,550.6,319.2,550.1,319.1L550.1,319.1z M564.3,316.7
	c0.7-0.7-0.4-0.9-4.7-0.6c-2.3,0.1-4.1-0.2-6.6-1.2c-2.3-0.9-3.6-1.2-4.1-0.8c-0.4,0.3-1.2,0.7-1.8,0.8c-1,0.2-0.9,0.4,0.5,1.2
	c1.1,0.7,3.1,1,6.3,1.1c2.6,0,4.8,0.2,4.9,0.3C559.5,318.1,563.8,317.3,564.3,316.7L564.3,316.7z M540,315.5c-0.8-0.4-2-0.7-2.8-0.6
	c-1.4,0.1-1.4,0.1-0.3,0.8c0.6,0.4,1.9,0.7,2.8,0.6l1.7-0.1L540,315.5L540,315.5z M684.7,306.5c0-0.9-1.6-1.4-2.8-1
	c-0.4,0.2-0.7,0.5-0.7,0.8c0,0.4,0.6,0.6,3.1,0.9C684.5,307.2,684.7,306.9,684.7,306.5z M441.2,304c0-0.3,0.2-0.4,0.6-0.2
	s0.6,0.1,0.6-0.3c0-0.6-1.4-0.8-7-1.2c-3-0.2-3.1-0.1-2.2,0.7C434.7,304.5,441.2,305.2,441.2,304L441.2,304z M326,308
	c-0.2-0.3-0.7-0.4-1-0.2c-0.4,0.3-0.3,0.5,0.2,0.8C326.2,309.1,326.6,308.8,326,308z M431.6,302.8c-0.2-0.3-0.6-0.5-1-0.5
	c-0.3,0-0.4,0.3-0.2,0.6c0.2,0.3,0.6,0.5,1,0.5C431.7,303.3,431.8,303.1,431.6,302.8z M141.5,313.2c0.2-0.3,1.1-0.7,2-1
	s1.7-0.7,1.7-1c0-0.4-0.6-0.4-1.6-0.1c-0.9,0.3-2.4,0.6-3.4,0.9c-2.2,0.5-2.5,2-0.4,1.9C140.6,313.7,141.3,313.5,141.5,313.2z
	 M107.9,313.7c0-0.1-0.4-0.2-0.9-0.2c-0.5,0-0.9,0.3-0.8,0.6c0,0.3,0.4,0.4,0.9,0.2C107.5,314.1,107.9,313.8,107.9,313.7
	L107.9,313.7z M131.1,312.1c1-0.8,1-1,0.1-1.8c-0.8-0.7-1.2-0.7-2.1-0.2c-1.2,0.7-1.5,1.8-0.7,2.6
	C129,313.3,129.9,313.1,131.1,312.1z M170.7,309.3c1-0.8,2.1-1.5,2.5-1.5c1.4-0.1,5.6-2.7,5.2-3.2c-0.2-0.4-0.5-0.3-0.8,0.2
	c-0.3,0.6-0.6,0.6-1.3,0.1c-1.6-1.2-12.7,3.4-13.3,5.5c-0.1,0.5,0.7,0.7,2.8,0.6C168.2,310.8,169.3,310.4,170.7,309.3L170.7,309.3z
	 M135.4,309.5c-0.4-0.1-0.9-0.1-1.1,0.1c-0.2,0.2,0.2,0.3,0.8,0.3C135.8,309.9,135.9,309.7,135.4,309.5L135.4,309.5z M356.8,299
	c-1.1-1.5-4.2-2.3-10.5-2.8c-2.8-0.2-7.6-1-10.6-1.6c-6.3-1.3-6.1-1.3-6-0.6c0,0.6,6.1,2.9,7.4,2.8c0.4,0,0.8,0.2,0.8,0.4
	c0,0.9,3.3,1.4,9.1,1.5c2.7,0,4.5,0.3,5.1,0.8c0.5,0.4,1.9,0.8,3.2,0.8C357.5,300.1,357.6,300.1,356.8,299z M318.4,300
	c-0.8-0.4-2.6-0.8-4-0.9c-2.2-0.2-2.4-0.1-1.4,0.5c1,0.6,2.8,0.9,6,1.1C319.5,300.7,319.3,300.4,318.4,300z M329,298.6
	c-2.6-1.1-7.3-1.3-5.2-0.2c1.4,0.7,4.2,1.3,6.1,1.2C331.2,299.6,331,299.4,329,298.6z M183.9,302.6c0-0.3-0.5-0.6-1.1-0.5
	c-0.6,0-1.3,0.3-1.4,0.6c-0.2,0.3,0.3,0.6,1.1,0.5C183.3,303.2,183.9,303,183.9,302.6z M167.1,300.2c0-0.1-0.4-0.2-0.9-0.2
	c-0.5,0-0.7,0.3-0.5,0.6C166.1,301.1,167.2,300.8,167.1,300.2z M321.8,292.1c-0.9-0.7-3.8-1.2-3.7-0.5c0,0.6,1.3,1,3.2,1
	C321.9,292.6,322.2,292.4,321.8,292.1z M185.5,294c0.2-0.3,1.2-0.7,2.4-1c1.1-0.3,2.4-0.8,2.8-1.3c1.3-1.4-0.8-1.5-3.2-0.2
	c-1.2,0.7-2.5,1.3-3,1.3c-0.4,0-1.3,0.4-1.9,0.9c-1,0.8-1,0.9,0.8,0.8C184.4,294.6,185.4,294.3,185.5,294L185.5,294z M122.2,292.9
	c2.2-0.9,2.2-0.9-1.7-0.4c-4.7,0.6-5.4,0.8-5.4,1.6C115.1,294.9,118.9,294.2,122.2,292.9z M114.4,291.5c0-0.1-0.3-0.2-0.6-0.2
	c-0.3,0-0.6,0.3-0.5,0.6c0,0.3,0.3,0.4,0.6,0.2C114.2,292,114.4,291.7,114.4,291.5z M127.5,291.5c0.7-0.2,1.3-0.6,1.3-0.9
	c0-0.8-2.7-0.5-3.4,0.5C124.5,292.1,125.2,292.2,127.5,291.5L127.5,291.5z M116.7,290.9c0-0.5,0.7-1.2,1.5-1.7
	c0.8-0.5,1.6-1.3,1.7-1.8c0.1-0.8-0.3-1-2.4-0.9c-1.4,0.1-3.7,0.3-5.1,0.6c-2.1,0.4-2.6,0.7-2.8,1.8c-0.2,1.2,0,1.4,1.3,1.4
	c2.7,0,2.9,0.1,3.6,0.8C115.5,292.1,116.7,292,116.7,290.9L116.7,290.9z M102.3,290.7c0-0.3-0.5-0.6-1.2-0.5c-0.6,0-1.1,0.3-1.1,0.6
	c0,0.3,0.5,0.6,1.2,0.5C101.8,291.3,102.3,291,102.3,290.7z M108.6,290.5c0-0.7-1.6-1.2-2.6-0.7c-1.4,0.6-0.9,1.4,0.9,1.3
	C107.8,291,108.6,290.7,108.6,290.5z M141.2,288c1.3-0.2,2.6-0.7,2.8-1c0.5-0.8-7.1,0.4-9.2,1.5c-1.7,0.9-1.7,0.9,1.2,0.4
	C137.4,288.7,139.8,288.3,141.2,288z M124.8,286.2c-0.2-0.3-0.8-0.5-1.2-0.5c-0.5,0-0.8,0.3-0.8,0.6c0,0.3,0.6,0.6,1.2,0.5
	C124.7,286.8,125,286.5,124.8,286.2z M128,285.1c-0.4-0.1-1.1,0-1.6,0.2c-0.8,0.3-0.8,0.5-0.1,0.9c0.5,0.3,1.1,0.2,1.6-0.2
	C128.4,285.5,128.4,285.2,128,285.1z M146.6,283c-0.2-0.3,0-0.6,0.4-0.8c0.4-0.2,0-0.4-0.8-0.5c-0.8-0.1-2,0-2.6,0.2
	c-1,0.4-1,0.5,0.4,1C145.8,283.7,147,283.7,146.6,283L146.6,283z M155.4,280.7c0-0.3-0.4-0.4-0.9-0.2c-0.5,0.2-1.1,0.4-1.5,0.4
	c-0.3,0-0.4,0.3-0.2,0.5C153.2,282.2,155.4,281.5,155.4,280.7L155.4,280.7z M114,277.4c3.5-0.5,5.8-1.2,7.5-2.3
	c1.3-0.8,3-1.6,3.7-1.7c1.8-0.3,2-1.6,0.4-1.9c-4-0.7-5.2-0.6-4.8,0.4c0.5,1.4-2.4,2.4-7.9,2.7c-4.4,0.2-11.2,2.3-11.1,3.4
	C101.8,279.3,102.1,279.3,114,277.4L114,277.4z M132.3,274.1c0-0.3-1-0.6-2.2-0.7c-1.7-0.1-2.2,0.1-2.1,0.8c0,0.7,0.5,0.9,2.2,0.7
	C131.4,274.8,132.4,274.4,132.3,274.1z M134.6,271c0.1-0.5-0.5-0.7-2.1-0.6c-1.8,0.1-2.4-0.1-2.6-1c-0.3-0.8-0.8-1.1-2.1-1.1
	c-1.8,0.1-2,0.4-1.3,2c0.3,0.6,1.1,0.9,2.5,0.8c1.2,0,2.5,0.2,3,0.4C133.1,272.2,134.4,271.9,134.6,271L134.6,271z M787.4,241.4
	c0.4-0.6,0.7-1.2,0.6-1.5c-0.3-0.8,1.4-1.6,3.4-1.7c1.2-0.1,2.2-0.5,2.8-1.3c0.5-0.7,1.3-1.2,1.7-1.2c0.4,0,0.9-0.4,1.1-1
	c0.2-0.5,0.9-1.2,1.7-1.5c0.8-0.3,1.3-0.9,1.1-1.3c-0.4-1-3.4-0.4-4.3,0.7c-0.4,0.6-1.2,0.8-2.1,0.7c-0.9-0.1-1.9,0.2-2.6,0.9
	c-0.6,0.6-1.7,1.3-2.4,1.5c-1.4,0.5-4.4,3.8-4.6,5C783.6,242.8,786,243.2,787.4,241.4L787.4,241.4z M112.4,269
	c0.5-0.7,0.4-0.8-0.8-0.4c-0.8,0.2-1.7,0.5-2.1,0.5c-0.4,0-0.7,0.3-0.7,0.6C108.9,270.5,111.7,270,112.4,269L112.4,269z M72.5,255.9
	c-0.7-0.8-3.4-0.9-3.4-0.1c0,0.9,0.7,1.1,2.4,1C72.8,256.7,73,256.5,72.5,255.9z M47.4,246.5c-1.2-0.7-2.5-0.6-2,0.1
	c0.2,0.3,0.9,0.5,1.6,0.5C48.1,247,48.1,246.9,47.4,246.5L47.4,246.5z M37.6,245.1c-2.1-0.5-2.9-0.5-2.9,0.1c0,0.6,2.9,1,3.8,0.5
	C38.8,245.6,38.4,245.3,37.6,245.1L37.6,245.1z M50.5,243.8c0.7-0.8-0.6-1.2-3.1-0.9c-0.8,0.1-1.2,0.4-0.9,0.9
	C46.8,244.6,49.8,244.6,50.5,243.8L50.5,243.8z M55.1,243.8c-0.2-0.3-1.2-0.5-2.2-0.4l-1.8,0.1l1.7,0.4
	C55.3,244.5,55.5,244.4,55.1,243.8z M81.4,234.6c0-0.3-0.3-0.6-0.6-0.5c-0.3,0-0.6,0.3-0.5,0.6c0,0.3,0.3,0.6,0.6,0.5
	C81.2,235.2,81.4,234.9,81.4,234.6z M46.5,225.2c0.7-0.1,0.6-0.3-0.3-0.9c-1.3-0.9-5.9-0.9-7.2,0c-0.6,0.4-0.4,0.6,0.9,0.9
	c0.9,0.2,2,0.4,2.3,0.4c0.3,0.1,1.2,0,2-0.1C45,225.4,46,225.3,46.5,225.2L46.5,225.2z M54,224.9c0.5-0.3,0.7-0.6,0.5-0.6
	c-0.2,0-1.5-0.1-3-0.2c-2.2-0.1-2.7,0-2.7,0.7C48.8,225.8,52.5,225.9,54,224.9L54,224.9z M836.1,170.7c-0.2-0.3-0.1-0.7,0.2-0.9
	c0.9-0.6,0.6-1.5-0.3-1.4c-0.5,0-0.8,0.6-0.8,1.5c0,0.8,0.3,1.4,0.7,1.4C836.2,171.2,836.3,171,836.1,170.7L836.1,170.7z
	 M898.6,166.5c0.4-1.1-0.1-1.1-1.9,0.1l-1.4,0.9l1.5,0C897.6,167.4,898.4,167,898.6,166.5L898.6,166.5z M896,160.5
	c0.9-0.5,1.7-0.5,2.4-0.2c2.4,1.2,8-1.9,10-5.6l1-1.8l-2.9,0.5c-1.7,0.3-3.4,1-4.1,1.7c-0.7,0.7-1.7,1.3-2.2,1.3
	c-0.6,0-1.2,0.3-1.3,0.6c-0.2,0.3-0.5,0.5-0.8,0.3c-0.3-0.1-1.4,0.7-2.6,1.8C893.2,161.2,893.5,161.8,896,160.5L896,160.5z
	 M42.7,193.5c0.1-0.1-0.2-0.6-0.6-1.1c-0.5-0.7-1.9-1-4.2-1.1c-3.3-0.2-3.4-0.1-2.6,0.9c1.7,2.1,2.6,2.4,5,1.9
	C41.5,193.8,42.6,193.5,42.7,193.5z M34,160.6c-0.6-0.6-1.8-0.1-1.8,0.8c0,0.7,2,1,2.2,0.3C34.5,161.4,34.3,160.9,34,160.6z
	 M33.2,158.4c0-0.8-1.3-2.9-2-3.3c-1.9-1.2-3.6,1.7-1.9,3.3C30.8,159.7,33.3,159.7,33.2,158.4L33.2,158.4z M29.1,152.2
	c-1-1.7-2-1.6-1.9,0.2c0,1,0.8,1.5,2.2,1.5C29.9,153.8,29.8,153.4,29.1,152.2z M860.9,112.3c0-0.5-0.3-0.9-0.6-0.8
	c-0.3,0-0.6,0.4-0.5,0.9c0,0.5,0.3,0.9,0.6,0.8C860.7,113.2,860.9,112.8,860.9,112.3z M468.3,107.7c1.1-0.3,3.3-0.8,4.8-1
	c1.6-0.2,3.1-0.8,3.5-1.3c0.7-1,2.1-1.1,3.7-0.4c1,0.5,9-0.6,9.6-1.3c0.1-0.1-0.1-0.7-0.6-1.2c-0.7-0.8-2.3-0.8-14.3,0
	c-7.4,0.5-14.9,1.1-16.7,1.4c-3,0.4-7.4,2.2-7.3,2.9c0,0.2,1.5,0.7,3.4,1.1C458,108.9,465.1,108.7,468.3,107.7L468.3,107.7z
	 M499.7,103.3c1.5-0.3,2.8-1.1,2.6-1.5c-0.8-1.2-10.7,0.3-10.6,1.6c0,0.6,2.9,0.9,5.2,0.4C498,103.7,499.3,103.4,499.7,103.3z
	 M33.1,119.5c-0.1-4.4-0.7-7-1.4-7c-0.3,0-0.4,0.3-0.2,0.6c0.2,0.3,0,0.6-0.5,0.6c-0.9,0-1.7,1.6-0.9,1.9c0.3,0.1,0.7,1.1,0.9,2.2
	C32.1,123.5,33.3,124.5,33.1,119.5L33.1,119.5z M608.4,95.8c1.4-0.7,2-0.7,3.6-0.1c1.6,0.6,2.7,0.5,6-0.1c2.3-0.5,7.2-1.1,10.9-1.4
	c3.7-0.3,7.2-0.8,7.7-1.1s4.3-0.7,8.4-0.9c4.1-0.2,8.6-0.7,10-1.1c1.4-0.4,4.1-1,6-1.4c1.9-0.3,3.9-0.9,4.5-1.2s3.1-1.1,5.5-1.6
	c2.7-0.6,4.4-1.2,4.4-1.6c0-0.8-11.6,0.1-12.1,1c-0.2,0.3-3.6,0.8-7.7,1.1c-7.6,0.6-20.2,2.2-21,2.8c-0.2,0.2-4,0.6-8.5,0.9
	c-4.4,0.3-8.6,0.8-9.3,1.1s-2.8,0.6-4.7,0.8c-1.9,0.2-4.1,0.7-5,1.2S605.5,95,605,95c-1.1,0-1.1,1-0.1,1.4
	C606,96.7,606.6,96.6,608.4,95.8L608.4,95.8z M522.4,95.7c0-0.3-0.3-0.6-0.6-0.5c-0.3,0-0.6,0.3-0.5,0.6c0,0.3,0.3,0.6,0.6,0.5
	C522.2,96.3,522.4,96,522.4,95.7L522.4,95.7z M541.6,95.1c4.4-0.7,1.8-1.4-3.4-0.9c-2.5,0.2-5.7,0.5-7.1,0.7
	c-1.4,0.1-2.1,0.4-1.7,0.6C530.3,95.9,537.7,95.7,541.6,95.1z M546.8,94.6c0.2-0.3-0.3-0.6-1-0.5c-1.1,0.1-1.2,0.2-0.4,0.6
	C546.5,95.4,546.4,95.4,546.8,94.6L546.8,94.6z M372.7,97c3-1.7,3.2-2.9,0.4-2.8c-1.9,0.1-5.4,2.5-5.3,3.7
	C367.8,98.8,370.1,98.4,372.7,97z M682.9,83.6c0.6-0.4,0.5-0.6-0.5-0.9c-1.3-0.4-2.4,0.1-2.3,1C680.2,84.4,681.9,84.3,682.9,83.6z
	 M893,73.5c0.5-0.8,1.4-2,1.9-2.7c0.5-0.6,1-1.3,0.9-1.4c0-0.6-3.1-0.1-4,0.7c-0.7,0.7-3.6,1.5-6,1.7c-0.3,0-0.4,0.2-0.1,0.4
	c0.2,0.2,0.1,0.8-0.1,1.4c-0.6,1.2,0.1,1.5,1,0.4c0.4-0.5,0.8-0.4,1.4,0.2C889.4,75.6,891.9,75.2,893,73.5z M361.6,96.4
	c1.1-1,1.1-2.4,0-2.3c-1.1,0-3.7,1.4-3.7,1.9c0,0.7,1.3,1.6,2.1,1.4C360.4,97.3,361.1,96.9,361.6,96.4z M32.8,108.8
	c-0.5-0.4-0.7-0.4-0.6,0.3c0,1.1,0.9,1.9,1.1,1C33.4,109.8,33.2,109.2,32.8,108.8z M653,82.2c0-0.5-0.4-0.8-0.9-0.8
	c-0.9,0-1.1,0.7-0.4,1.4C652.4,83.4,653.1,83.1,653,82.2L653,82.2z M887,64.3c0-0.3,0.4-0.6,0.9-0.6c1.3-0.1,0.6-1.4-0.9-1.6
	c-1-0.1-1.2,0.2-1.2,1.3c0,0.8,0.3,1.5,0.6,1.5C886.7,64.9,887,64.6,887,64.3z M67.3,96.6c0-0.3-0.3-0.6-0.6-0.5
	c-0.3,0-0.4,0.3-0.2,0.6c0.2,0.3,0.5,0.6,0.6,0.5C67.3,97.1,67.4,96.9,67.3,96.6z M298,85.1c0.1-0.9-0.2-1.9-0.7-2.1
	c-1.2-0.7-1.7-0.5-1.6,0.6c0,0.6-0.2,1.3-0.6,1.7c-0.9,0.9,0,2,1.5,1.7C297.6,86.9,298,86.3,298,85.1L298,85.1z M323.5,84.9
	c-0.4-0.6-0.9-1.2-1.2-1.4c-0.8-0.5-0.7,1.1,0.2,1.9C323.6,86.4,324.2,86.1,323.5,84.9z M63.2,94c-1.5-0.9-2.2-0.5-1.1,0.7
	c0.4,0.4,1.1,0.6,1.6,0.4C64.3,94.9,64.2,94.7,63.2,94z M77.8,84.5c1.3,1.2,3.2,0.7,8.5-2.2c2.8-1.5,5.4-2.8,5.8-2.9
	c1-0.1,2.5-2.7,2-3.4c-0.2-0.3-1.1-0.7-2-0.8c-0.9-0.1-1.8-0.4-2.2-0.5c-0.3-0.2-0.6-0.1-0.6,0.2c0,0.8-3.2,1.5-6.8,1.6
	c-2.3,0.1-3.3,0.3-3.8,1c-0.9,1.3-8.8,3-10.8,2.4c-1.1-0.4-1.7-0.3-2.1,0.2c-0.4,0.5-0.1,1.1,1.4,2.1c2.3,1.6,8.9,3.2,9.5,2.3
	C77,84,77.4,84,77.8,84.5L77.8,84.5z M285.3,74c1.6-1.8,1.6-2.1,0.7-3.7c-0.9-1.5-0.5-1.8,2.6-2.3c3.4-0.6,4-1.6,2.8-4.8
	c-2.3-6.3-5.3-10.8-10.5-15.5c-5.3-4.9-7.4-6.6-8.3-6.6c-0.3,0-1-0.5-1.6-1.1c-0.6-0.6-1.3-1.1-1.6-1.1c-0.3,0-1.5-0.7-2.7-1.5
	c-1.9-1.4-4.8-3-9.5-5.2c-0.8-0.4-1.8-0.7-2.1-0.7s-2.3-0.7-4.3-1.5c-2-0.8-3.9-1.4-4.2-1.2c-0.3,0.2-0.8,0.1-1.2-0.2
	c-0.4-0.3-2.4-0.9-4.3-1.4c-2-0.5-3.6-1.1-3.7-1.4c0-0.3-0.4-0.3-1,0c-0.5,0.3-1.4,0.5-1.9,0.4c-2.8-0.4-9-1.7-12.5-2.5
	c-6.8-1.6-11.6-1.9-23.6-1.6c-7.8,0.2-12.3,0-14.1-0.4c-3.9-1-7-1.2-9.5-0.5c-2.8,0.8-3.4,1.4-2.4,2.7c0.7,0.9,2.1,1,14.6,0.6
	c7.6-0.2,14.9-0.3,16.4-0.2c13.2,0.9,20.5,1.8,23.2,3.1c1.5,0.7,1.7,1,1,1.4c-0.5,0.3-1.7,0.3-2.8,0.1c-3.4-0.8-8.4-0.9-8.8-0.2
	c-0.5,0.9,1.7,1.5,8,2.3c2.9,0.4,6.4,1.1,7.7,1.7c1.3,0.6,5,2.1,8.2,3.4c3.2,1.3,6.5,2.9,7.4,3.6c0.8,0.7,3.1,2,5,3
	c1.9,1,4.5,2.6,5.7,3.7c1.2,1.1,4,3.6,6.2,5.5s4.5,4.1,5,4.8c1.1,1.5,2.1,1.6,3.1,0.3c1.2-1.6,1.7-1,1.8,2.5c0.1,3.1,0.3,3.5,1.6,4
	c0.8,0.3,1.6,1.1,1.8,1.7c0.2,0.6,0.9,1.2,1.5,1.4c1.2,0.3,1.4,0.7,1.3,2.7c-0.1,1.3,2.5,6.6,3.2,6.5
	C283.7,75.6,284.5,74.9,285.3,74L285.3,74z M68.8,77.5c0.9-0.6,1-0.9,0.5-1.8c-0.9-1.6-3.4-1.3-3.9,0.3c-0.2,0.7-0.2,1.4,0.1,1.5
	c0.3,0.1,0.4,0.4,0.2,0.7C65.3,78.9,67.2,78.4,68.8,77.5L68.8,77.5z M73.5,74.8c0.2-0.6-0.2-0.8-1.2-0.7c-1.4,0.1-1.4,0.1-0.4,0.8
	c0.6,0.4,1.2,0.7,1.2,0.7C73.2,75.6,73.3,75.2,73.5,74.8z M245.2,64.6c0.2-0.4-0.1-1-0.6-1.3c-0.5-0.4-1-1-1-1.4c0-0.6,0.2-0.6,1,0
	c0.9,0.7,0.9,0.6,0.3-0.1c-0.4-0.5-1.8-1.4-3.1-2.1l-2.4-1.2l-1.4,1.5c-0.8,0.8-1.4,1.7-1.4,2c0,0.3,1,0.9,2.2,1.5s2.6,1.1,3.1,1.4
	C243.3,65.5,244.8,65.3,245.2,64.6L245.2,64.6z M886.7,34.4c0.7-1,1.2-2,1.2-2.4c-0.1-1.2-1.9-0.5-3.5,1.3c-1.4,1.5-1.5,1.9-0.9,2.3
	C884.9,36.5,885.5,36.2,886.7,34.4z M251,61.6c0.3-1-0.9-0.8-1.2,0.3c-0.2,0.6-0.1,0.8,0.4,0.7C250.5,62.4,250.8,62,251,61.6
	L251,61.6z M248.7,60c0.4-0.9,0.2-1.3-1.4-2.4c-2.7-1.8-9.7-5.2-10.7-5.2c-1.3,0.1-0.9,1.1,0.8,2.1c0.9,0.5,2,1.3,2.5,1.9
	c1.6,1.7,5.1,3.2,5.8,2.4c0.5-0.5,0.6-0.3,0.7,0.9C246.5,61.4,247.9,61.6,248.7,60L248.7,60z M231.8,59.7c0.2-0.5,0-1.5-0.5-2.2
	c-0.6-1-1.4-1.3-3.8-1.5c-3-0.2-6.6,0.7-6.6,1.7c0,0.3,0.6,0.7,1.3,1c1.1,0.4,7,1.6,8.7,1.9C231.3,60.6,231.7,60.2,231.8,59.7
	L231.8,59.7z M78.2,62.7c0.9-0.5,0.8-0.5-0.7-0.4c-1,0.1-1.9,0.4-1.8,0.6C75.7,63.5,76.8,63.4,78.2,62.7z M219.6,54.3
	c-1.5-0.8-6.3-1.1-6.2-0.4c0,0.3-0.4,0.5-1,0.4c-0.6-0.2-0.8-0.1-0.3,0.2c0.4,0.3,2.5,0.4,4.6,0.4C219.5,54.8,220.3,54.7,219.6,54.3
	L219.6,54.3z M98.7,24.1c-1.3-1.2-5.2-3.5-6.1-3.4c-0.7,0-0.1,1.4,1.4,3.1c1.4,1.6,2,1.9,3.9,1.8l2.3-0.1L98.7,24.1L98.7,24.1z
	 M466.7,356.4c0.6-0.4,1.8-0.9,2.8-1c1-0.2,2.7-0.7,3.9-1.2c1.3-0.6,2.2-0.7,2.5-0.4c0.2,0.3,0,0.6-0.4,0.7c-0.4,0.1-1,0.2-1.4,0.2
	c-0.4,0.1-1,0.4-1.4,0.8c-0.4,0.4-2.2,0.9-3.9,1.2C466.3,357,465.9,357,466.7,356.4z M487.7,355.6c0-0.3,0.3-0.6,0.8-0.6
	c0.5,0,1,0.2,1.2,0.5c0.2,0.3-0.2,0.6-0.8,0.6C488.3,356.1,487.7,355.9,487.7,355.6z M493.6,350.2c-0.6-0.7-0.5-0.7,0.6-0.4
	c0.9,0.3,1.4,0.1,1.6-0.6c0.4-1,1.2-1.2,2.5-0.4c0.8,0.5,0.7,0.6-0.2,1.3C496.6,351.1,494.5,351.1,493.6,350.2L493.6,350.2z
	 M396,354.3c0.4-0.4,0.8-0.6,1-0.4c0.2,0.2-0.1,0.5-0.7,0.7C395.6,355,395.5,354.9,396,354.3L396,354.3z M605.4,337.1
	c-1.3-0.4-1.3-0.6-0.5-1.1c1.3-0.8,4.6,0.2,3.4,1.1C607.3,337.8,607.3,337.8,605.4,337.1L605.4,337.1z M393.6,345.9
	c-0.6-0.3-0.5-0.5,0.1-0.8c0.4-0.2,1.1-0.2,1.5,0.1c0.6,0.3,0.5,0.5-0.1,0.8C394.7,346.1,394,346.1,393.6,345.9L393.6,345.9z
	 M400.3,345.8c0.4-0.2,1-0.2,1.4-0.1c0.4,0.1,0.1,0.3-0.7,0.3C400.2,346.1,399.9,345.9,400.3,345.8L400.3,345.8z M389.5,345.5
	c-0.8-0.4-0.8-0.5,0.1-0.6c0.5,0,1.1,0.2,1.4,0.5C391.4,346.1,390.6,346.1,389.5,345.5L389.5,345.5z M429,343.7
	c-1.2-0.6-1.1-2.5,0.2-2.8c1-0.2,3.4,2,3,2.8C431.8,344.4,430.2,344.3,429,343.7L429,343.7z M415.7,343.9c-0.6-0.1-1-0.6-1.1-1
	c0-1,1.9-1.7,3.5-1.3c0.7,0.2,2,0.3,3,0.4c2.3,0,2.8,1.4,0.6,1.5c-0.9,0-2.4,0.2-3.3,0.4S416.3,344.1,415.7,343.9L415.7,343.9z
	 M372.7,344.5l-2-0.3l2.1-0.5c1.6-0.4,2.4-0.3,3.5,0.3l1.4,0.9l-1.4,0C375.4,344.8,373.9,344.7,372.7,344.5L372.7,344.5z
	 M637.7,332.9c-0.2-0.3-0.3-0.7-0.1-0.9c0.1-0.2,0.4,0.1,0.6,0.6C638.7,333.6,638.4,333.8,637.7,332.9z M641.2,332.5l-2.9-0.6
	l2.4-0.4c2.4-0.4,5.2,0.4,4.4,1.2C644.7,333.2,644.9,333.3,641.2,332.5L641.2,332.5z M651.6,332.3c-0.2-0.3,0-0.6,0.5-0.6
	c0.5,0,0.9,0.2,0.9,0.5c0,0.3-0.2,0.6-0.5,0.6C652.2,332.9,651.8,332.6,651.6,332.3z M463.4,338.3c2.1-1.2,10.8-2.1,10.9-1.1
	c0,0.5-3.6,1-5.6,0.8c-0.4,0-0.7,0.2-0.7,0.5c0,0.3-1.4,0.6-3.3,0.7l-3.3,0.1L463.4,338.3L463.4,338.3z M338.6,343
	c0.4-0.4,0.8-0.6,1-0.4c0.2,0.2-0.1,0.5-0.7,0.7C338.1,343.7,338,343.6,338.6,343z M495.1,332.8c0.4-0.7-1.3-1.1-3.8-1
	c-1.2,0.1-2.3-0.2-2.3-0.5c0-0.3,0.3-0.6,0.7-0.7c0.4-0.1,1.3-0.2,2-0.3c0.7-0.1,2,0.1,2.8,0.5c0.8,0.4,1.9,0.7,2.3,0.7
	c1.4-0.1,0.9,1-0.6,1.3C495.5,333.1,495,333,495.1,332.8z M55.2,350.3c-0.8-0.2-0.2-0.6,2-1.1c4.3-1.1,9.1-1.7,8.8-1
	c-0.2,0.3-1,0.6-1.7,0.6c-0.8,0-1.9,0.3-2.5,0.7C60.3,350.2,56.6,350.7,55.2,350.3z M504,326.6c-0.2-0.3-0.3-0.7-0.1-0.9
	s0.4,0.1,0.6,0.6C504.9,327.3,504.6,327.5,504,326.6z M54,345.1c0-0.2,0.6-0.9,1.3-1.4c1.1-0.8,1.7-0.8,3.4-0.3
	c1.9,0.6,2,0.7,1.1,1.3C58.7,345.5,54.1,345.8,54,345.1z M367.6,330.4c-0.6-0.4-0.3-0.6,1.2-1c2.4-0.6,4.2-0.4,4.2,0.4
	C373,330.5,368.6,331,367.6,330.4z M483,320.1c-1.3-0.2-3.5-0.6-4.9-0.9c-2-0.4-2.7-0.4-3,0.2c-0.3,0.6-0.7,0.6-1.5-0.1
	c-1.7-1.3-3.2-1.7-5.6-1.5c-1.5,0.2-2.3,0-2.5-0.6c-0.5-1.2-3-1.5-4.2-0.7c-0.9,0.6-1.5,0.6-3.3-0.1c-3.6-1.3-8.6-2.4-10.6-2.3
	c-1,0-2.4-0.2-3-0.5c-1.3-0.6-6.2-1.8-9.4-2.2c-3.4-0.4-3-1.2,0.6-1c2.2,0.1,3.8-0.1,4.3-0.5c0.8-0.7,3-0.4,5.9,1
	c1,0.5,2.7,0.8,3.8,0.8c1.1,0,3.1,0.1,4.3,0.4c3.7,0.8,3.9,0.8,6.2,0.2c1.2-0.3,2.3-0.3,2.5-0.1c0.5,0.7,5.4,1,6.5,0.3
	c0.6-0.3,1.3-0.4,1.6-0.2c0.3,0.2,1.9,0.8,3.5,1.3c1.6,0.5,3.5,1.2,4.1,1.6c0.7,0.4,2.1,0.8,3.2,1c1.1,0.2,2.7,0.8,3.5,1.3
	c0.8,0.5,2.4,1.1,3.5,1.3c1.1,0.2,2.4,0.5,2.9,0.8C492.5,320.4,486.3,320.6,483,320.1L483,320.1z M108.8,335.3
	c0-0.7,0.4-0.9,2.4-1.5c2.2-0.6,2,0.4-0.2,1.3C108.9,336,108.9,336,108.8,335.3z M380.1,321.5l-2.3-0.6l1.8-0.7
	c1.5-0.6,2-0.5,3.5,0.6c1.5,1.1,1.6,1.3,0.6,1.3C382.9,322.1,381.4,321.8,380.1,321.5L380.1,321.5z M401.3,320.8
	c-0.7-0.2-1.4-0.7-1.6-1s-0.9-0.4-1.5-0.2c-0.7,0.2-1.1,0.1-1.2-0.4c0-0.5,0.6-0.6,1.7-0.5c1,0.1,1.7,0,1.7-0.2
	c0-0.7,5.2-1.8,7.5-1.6c1.4,0.1,2.3-0.1,2.9-0.8c1.5-1.6,3.8-1.2,4.7,0.8c0.3,0.8,0.1,1.1-0.8,1.4l-1.3,0.4l1.4,0.3
	c1.7,0.3,2.3,1.5,0.8,1.4c-0.6,0-1.3-0.2-1.6-0.4c-0.8-0.4-4.1-0.1-4.6,0.5C408.8,321.2,402.8,321.3,401.3,320.8L401.3,320.8z
	 M403.3,319.8c-0.8-0.9-2.3-1.1-2.3-0.2c0,0.5,1.1,0.9,2.6,1C403.8,320.6,403.7,320.3,403.3,319.8z M391.7,320.6
	c-2.1-0.1-3.2-0.4-2.9-0.8c0.2-0.3,1-0.7,1.8-0.8c0.8-0.1,1.6-0.5,1.8-0.9c0.3-0.6,0.5-0.6,0.5,0c0,0.4,0.5,0.7,1.1,0.7
	c1.1,0,2.2,1.4,1.4,1.8C395.2,320.7,393.5,320.7,391.7,320.6L391.7,320.6z M420,319.4c0-0.7,1.1-0.8,2.3-0.1
	c0.7,0.4,0.5,0.5-0.7,0.6C420.7,319.9,420,319.7,420,319.4z M377.9,318.6l-2-0.6l2.4-0.1c1.3-0.1,2.6,0.1,2.7,0.3
	C381.7,319,380,319.2,377.9,318.6L377.9,318.6z M389.4,317c0-0.6,1.1-0.9,1.4-0.4c0.2,0.3,0,0.6-0.5,0.6
	C389.8,317.3,389.4,317.2,389.4,317L389.4,317z M52.6,327.3c-2.8-0.8-2.9-0.9-1.3-1c1-0.1,2.5,0.1,3.3,0.3c1.8,0.6,4.9-0.5,4.9-1.7
	c0-0.6,0.4-0.8,2.1-0.5c1.2,0.2,2.6,0.5,3.1,0.7c1,0.5,0.9,0.6-3.4,1.9C57.5,328.2,56,328.3,52.6,327.3z M357.7,314
	c-1.3-2,0.4-4.3,2.4-3.1c0.6,0.3,0.7,1.6,0.4,3.7C360.5,315.5,358.5,315.1,357.7,314L357.7,314z M127.3,324.2c0-0.3,0.4-0.7,0.9-0.9
	c1.5-0.7,2.1-0.5,1.2,0.5C128.5,324.7,127.4,325,127.3,324.2L127.3,324.2z M774.6,294.8c-1.2-0.7-0.3-1.8,1.6-1.9
	c1.5-0.1,2.8,0.7,2.3,1.3C777.9,294.9,775.3,295.2,774.6,294.8z M351.2,311.5c0-0.3,0.2-0.6,0.5-0.6s0.7,0.2,0.9,0.5
	c0.2,0.3,0,0.6-0.5,0.6C351.6,312,351.2,311.8,351.2,311.5L351.2,311.5z M794.7,291c1.2-0.7,2.7-1.3,3.4-1.3c1.1,0,1.1,0-0.1,1.2
	c-0.8,0.7-2.2,1.2-3.4,1.3l-2.1,0.1L794.7,291L794.7,291z M399.6,307.6c-2.2-0.5-4.7-0.9-5.5-0.9c-3.1,0.1-0.1-1.1,3.9-1.5
	c4.1-0.4,4.3-0.4,6.4,1.4c2.1,1.8,2.1,1.8,0.7,1.8C404.3,308.5,401.9,308.1,399.6,307.6z M65.1,322.7c-0.5-1.3-0.5-4.5,0-4.9
	c0.3-0.2,0.5-0.1,0.3,0.2c-0.2,0.3,0.3,0.8,1.2,0.9c1.5,0.3,1.5,0.4,0.6,1.1c-0.5,0.4-1,1.1-0.9,1.4C66.2,322,65.2,323.1,65.1,322.7
	L65.1,322.7z M781.2,290.9c-0.7-0.5-0.6-0.5,0.4-0.3c0.7,0.1,1.3,0,1.3-0.3c0-0.3,0.2-0.4,0.6-0.2c0.3,0.2,0.6,0.1,0.6-0.2
	c0-0.3,0.3-0.5,0.7-0.6c0.9-0.1,3-0.7,4.4-1.2c0.6-0.2,1.2-0.1,1.5,0.3c0.6,0.9-0.9,1.8-1.8,1.1c-0.8-0.6-2.1-0.1-1.7,0.6
	c0.1,0.2-0.4,0.4-1.3,0.5c-0.8,0-2,0.3-2.7,0.5C782.6,291.3,781.7,291.2,781.2,290.9L781.2,290.9z M778.3,290.5
	c0-0.3,0.4-0.6,0.8-0.6c0.5,0,0.9,0.2,0.9,0.5c0,0.3-0.4,0.6-0.8,0.6C778.7,291.1,778.3,290.8,778.3,290.5z M53.3,321.1
	c-0.2-0.3-1.4-0.6-2.7-0.8c-1.7-0.2-2.3-0.6-2.4-1.4c-0.1-1.9,1.7-2.4,3.7-1c2.3,1.6,5,2.2,5,1.2c0-0.4,0.1-0.6,0.3-0.4
	c0.2,0.2,0.2,0.9,0,1.5c-0.2,0.9-0.8,1.2-2,1.3C54.4,321.5,53.5,321.3,53.3,321.1L53.3,321.1z M387.6,306.6
	c-0.2-0.2-0.3-0.6-0.1-0.8c0.3-0.6,2-0.1,2,0.6C389.5,307,388.2,307.2,387.6,306.6z M408.9,305.2c-1.4-0.5-2.7-0.5-3.8-0.1
	c-1.4,0.5-1.7,0.4-2-0.5c-0.4-1.2,0.8-1.9,1.8-1c0.4,0.3,1.7,0.5,3,0.4c2.2-0.3,4,0.4,4.1,1.5C411.9,306.1,411.5,306,408.9,305.2z
	 M72.2,319.6c-1-0.3-1.1-0.4-0.2-0.8c0.5-0.2,1.5-0.4,2.2-0.3c1.1,0.1,1.2,0.2,0.2,0.8C73.8,319.7,72.8,319.8,72.2,319.6z
	 M801.2,287.9c0-0.3,0.5-0.7,1.1-0.9c0.7-0.2,1.1-0.1,1,0.3C803.1,288.2,801.3,288.7,801.2,287.9z M381,305.4c-0.6-0.8,0-1,1.8-0.6
	c1.9,0.5,1.8,0.5-0.2,0.9C382,305.8,381.2,305.7,381,305.4L381,305.4z M415.5,303.4c-0.2-0.3-0.3-0.7-0.1-0.9
	c0.4-0.5,4.4,0.2,4.5,0.8C419.9,304.1,416,304.2,415.5,303.4L415.5,303.4z M804,286.1c0-1.9,7.2-5.3,7.3-3.5c0,0.8-1.2,1.9-2.2,1.9
	c-0.5,0-1.6,0.5-2.5,1C804.9,286.6,804,286.8,804,286.1L804,286.1z M391,301.3c-4.9-1.1-5.1-1.2-3.7-1.8c0.7-0.3,1.9-0.4,2.6-0.2
	s1.9,0.3,2.7,0.2c1.7-0.2,4.8,1.1,4.4,1.9C396.6,302.2,394.9,302.1,391,301.3L391,301.3z M61.9,315.3c-0.1-1.9,3.6-3.7,7.5-3.7
	c2.2,0,0.8,1.9-2.1,2.8c-1.6,0.5-3.4,1.1-4.1,1.3C62.3,316,62,315.9,61.9,315.3L61.9,315.3z M217.3,308.7c-0.2-0.3,0-0.6,0.5-0.6
	c0.5,0,1,0.2,1.2,0.5c0.2,0.3,0,0.6-0.5,0.6C218,309.2,217.5,309,217.3,308.7L217.3,308.7z M37.5,314.5c-0.2-0.5-0.4-1.5-0.4-2.2
	c0-0.7-0.4-1.5-0.7-1.9c-1.5-1.3,0.1-1.8,5.9-1.8c10,0,13.1-0.4,20.2-2.2c9.7-2.5,10-2.5,10-1.9c0,0.3-0.9,0.9-2.1,1.4
	c-1.2,0.4-3.1,1.8-4.3,3c-1.2,1.2-2,1.9-1.8,1.5c0.7-1.4-5.6-1.3-7.4,0.1c-0.7,0.6-3.4,1.1-10.2,2.2c-2.8,0.4-5.7,1.2-6.4,1.7
	C38.6,315.5,38,315.6,37.5,314.5L37.5,314.5z M799.5,280.5c-0.6-0.2-1.9-0.3-2.8-0.1c-2.9,0.5-0.7-1.4,2.3-2
	c3.2-0.7,2.7-0.5,5.8-1.6c1.4-0.5,2.7-0.9,3-0.8c0.2,0,0.4-0.1,0.4-0.4c0-0.9,1.8-1.4,5.5-1.7c2-0.2,3.9-0.6,4.3-0.9
	c0.4-0.3,1.8-1,3.1-1.4c1.3-0.4,3-1.4,3.8-2.2c0.7-0.8,1.7-1.5,2.2-1.5c0.4,0,1.1-0.5,1.5-1c0.4-0.6,1.3-0.9,2.1-0.7
	c0.9,0.1,1.5-0.1,1.7-0.7c0.7-2.3,10-10.3,12-10.4c0.5,0,1.1-0.4,1.3-0.8c0.5-1,4.6-3.1,5.1-2.7c0.4,0.4-2.6,3.3-3.4,3.4
	c-0.3,0-1.5,1-2.6,2.1c-1.1,1.2-2.3,2.1-2.6,2.1c-0.3,0-1.4,0.8-2.3,1.8c-0.9,1-2.4,2-3.3,2.2c-0.9,0.2-1.7,0.7-1.9,1.1
	c-0.3,0.9-5.7,5.1-10.9,8.5c-2,1.3-4.2,2.9-4.8,3.6c-0.9,0.9-2,1.2-4.3,1.3c-2,0.1-4.3,0.7-6.5,1.7
	C804.4,280.8,801.6,281.2,799.5,280.5L799.5,280.5z M396,297.2c-1.1-0.3-2.8-0.9-3.6-1.4c-0.8-0.5-2.6-1-3.9-1
	c-3.2-0.2-4.5-1.3-1.6-1.4c1.2,0,2.3-0.3,2.4-0.4c0.2-0.2,1.2,0.2,2.3,0.8c1.5,0.9,2.5,1,4.3,0.7c1.2-0.3,2.3-0.3,2.3,0
	c0,0.2,0.2,0.8,0.3,1.4C398.9,297.3,398.2,297.7,396,297.2L396,297.2z M80.4,309c-1-0.1-2.1-0.5-2.6-0.8c-0.7-0.5-0.5-0.7,0.8-1.1
	c0.9-0.3,2.2-0.7,2.9-0.9c0.9-0.3,1.3-0.2,1.7,0.7c0.2,0.7,0.8,1.2,1.2,1.2c0.4,0,1,0.2,1.2,0.5C85.9,309.2,83.7,309.4,80.4,309
	L80.4,309z M88.4,308.6c-0.5-0.8,1.5-2,2.7-1.6c0.9,0.3,1.3,1.3,0.5,1.4c-0.2,0-1,0.2-1.6,0.4C89.4,309,88.7,308.9,88.4,308.6
	L88.4,308.6z M27.9,300c0-0.5,0.7-1,1.5-1.2c0.9-0.2,2.8-0.8,4.3-1.4s4.5-1.2,6.5-1.5s4.8-0.9,6-1.4c1.2-0.5,3.8-1,5.7-1.1
	c1.9-0.1,4.7-0.5,6.1-0.9c1.9-0.5,3.1-0.6,4.7-0.1c2.7,0.8,2.3,1.6-0.8,1.7c-6,0.3-28.1,4.4-31,5.7C28.6,301,27.9,301,27.9,300
	L27.9,300z M18.3,296.8c-0.3-0.5,0.7-1.5,1.8-1.8c0.6-0.2,0.5,0.1-0.2,1C18.8,297.3,18.7,297.4,18.3,296.8z M15.8,296.4
	c0.2-0.2,0.7-0.2,1.1-0.1c0.5,0.2,0.3,0.3-0.3,0.4C16,296.7,15.6,296.6,15.8,296.4L15.8,296.4z M75.4,292.9c-2-0.6-1.3-1,1.1-0.6
	c1.3,0.2,1.7,0.5,1.2,0.7C77.2,293.2,76.2,293.2,75.4,292.9L75.4,292.9z M67.1,281c-0.4-1,0.6-1.8,1.4-1.1c0.4,0.3,0.3,0.8-0.2,1.2
	C67.7,281.7,67.4,281.7,67.1,281L67.1,281z M74.4,279.9c0.2-0.2,1.1-0.5,2-0.6c0.9-0.1,3-0.4,4.6-0.7c2.4-0.4,2.9-0.4,2.9,0.4
	c0,0.8-0.8,0.9-4.9,1.1C76.3,280.2,74.3,280.1,74.4,279.9L74.4,279.9z M858,244.5c0-0.3,0.2-0.7,0.5-0.9c0.3-0.2,0.6,0,0.6,0.5
	c0,0.5-0.2,0.9-0.5,0.9C858.3,245,858,244.8,858,244.5z M861.9,241.1c1.4-1.7,2.2-1.9,2.3-0.7c0,1-0.6,1.5-2.3,1.9
	C860.7,242.6,860.7,242.6,861.9,241.1L861.9,241.1z M78.3,275c0.2-0.3,0.7-0.6,1.2-0.6c0.5,0,0.8,0.2,0.9,0.5c0,0.3-0.5,0.6-1.2,0.6
	C78.5,275.6,78.1,275.4,78.3,275z M69,275c0.6-0.6,3.8-0.9,3.8-0.3c0,0.3-0.9,0.6-2.1,0.6C69.6,275.4,68.8,275.3,69,275L69,275z
	 M864.7,239.6c0-0.6,1.8-2.1,4.1-3.2c2.8-1.4,2.9-1.4,1,0.8c-0.9,1.1-1.6,2.1-1.6,2.3c0,0.2-0.8,0.4-1.7,0.4
	C865.5,240,864.7,239.8,864.7,239.6L864.7,239.6z M80.1,269.8c0-0.3,0.4-0.4,0.9-0.3c0.5,0.2,0.9,0.4,0.9,0.5c0,0.1-0.4,0.2-0.9,0.3
	C80.5,270.3,80.1,270.1,80.1,269.8L80.1,269.8z M871.7,234.7c-0.2-0.3,0-0.6,0.5-0.6c1,0,1.2,0.3,0.4,0.8
	C872.3,235.1,871.9,235,871.7,234.7z M875.1,232.4c-0.7-0.7-0.5-1.2,0.7-1.6c0.9-0.3,1.1-0.1,1,0.6
	C876.7,232.5,875.8,233,875.1,232.4z M77.2,266.7c0.2-0.2,0.7-0.2,1.1-0.1c0.5,0.2,0.3,0.3-0.3,0.4C77.4,267,77,266.9,77.2,266.7
	L77.2,266.7z M15.5,246.7c-0.4-0.6,1.1-0.7,3.2-0.1c1.3,0.4,1.2,0.4-0.7,0.5C16.8,247.2,15.7,247,15.5,246.7z M0.4,241.8
	c-0.9-0.8-0.4-1.3,1.3-1.4c1.1,0,1.7,0.2,1.8,0.8C3.5,242.1,1.2,242.5,0.4,241.8L0.4,241.8z M4.6,240.3c0.5-0.3,1.4-0.6,2-0.6
	c1,0,1,0.1,0.3,0.5c-0.5,0.3-1.4,0.6-2,0.6C3.9,240.9,3.9,240.8,4.6,240.3L4.6,240.3z M891.2,199.1c-0.7-0.7-0.4-1.5,0.6-1.6
	c0.3,0,0.4,0.2,0.2,0.6c-0.2,0.3-0.1,0.6,0.3,0.6c0.3,0,0.6,0.2,0.6,0.5C892.9,200,892,200,891.2,199.1z M36.3,208.3
	c-1.4-1.3-1.6-1.8-1-2.2c0.4-0.3,1.2-0.5,1.8-0.4c0.6,0.1,1.1,0,1.3-0.3c0.4-0.7,2.4-0.8,2.8-0.1c0.6,0.9-0.2,2.9-1,2.8
	c-0.4-0.1-1.1,0.3-1.5,0.9C38.1,209.9,37.9,209.8,36.3,208.3L36.3,208.3z M17.2,153.4c-0.2-0.3-0.1-0.6,0.2-0.6
	c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.1,0.6-0.2,0.6C17.7,154,17.4,153.7,17.2,153.4z M16.6,114.5c0.1-0.7-0.1-1.4-0.5-1.5
	c-0.5-0.2-0.5-0.3,0-0.3c1-0.1,1.3,0.6,0.8,1.9C16.4,115.7,16.4,115.7,16.6,114.5L16.6,114.5z M315.4,97.1c-0.1-1.7,0-1.8,0.9-0.9
	c0.5,0.5,0.8,1.3,0.7,1.8C316.4,99.6,315.4,99.1,315.4,97.1L315.4,97.1z M595.9,86.2c0.2-0.3,1.4-0.6,2.7-0.7
	c1.8-0.1,2.2,0.1,1.6,0.5C599.1,86.8,595.5,86.9,595.9,86.2z M603.1,85.4c0-0.3,0.3-0.6,0.7-0.6s1.3-0.3,2.1-0.6
	c1-0.4,1.3-0.3,1.1,0.3C606.7,85.5,603.1,86.3,603.1,85.4z M464.1,90.8c0-0.3,0.4-0.6,0.8-0.6c0.5,0,0.9,0.2,0.9,0.5
	c0,0.3-0.4,0.6-0.8,0.6C464.5,91.3,464.1,91.1,464.1,90.8z M469,90.6c-1.6-0.3-1.4-0.4,1.3-0.8c3.2-0.5,4.8-0.3,3.5,0.6
	C472.9,91,471.5,91.1,469,90.6z M611.1,84.7c0-0.1,0.2-0.4,0.5-0.6c0.3-0.2,0.6-0.1,0.6,0.2c0,0.3-0.2,0.6-0.5,0.6
	C611.4,84.9,611.1,84.8,611.1,84.7z M475.5,88.9c0-0.1,0.4-0.4,0.9-0.6c0.5-0.2,0.7-0.1,0.6,0.2C476.6,89.1,475.5,89.4,475.5,88.9z
	 M483.3,87.7c-0.4-0.7,0.1-0.9,2.2-1.3c3.6-0.5,5.7-0.5,5.8,0c0,0.3-0.5,0.6-1.2,0.7c-0.7,0-1.4,0.3-1.7,0.5
	C487.8,88.2,483.7,88.3,483.3,87.7L483.3,87.7z M11.7,106.2c-0.2-0.5,0-0.9,0.5-0.9c0.9,0,1.2,0.8,0.4,1.4
	C12.3,106.9,11.9,106.6,11.7,106.2z M507.5,83.7c0-0.3,0.2-0.6,0.5-0.6c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.2,0.6-0.5,0.6
	C507.8,84.3,507.5,84,507.5,83.7z M542.1,80.2c-0.7-0.4-1.9-0.8-2.8-0.8c-0.9,0-1.6-0.2-1.6-0.5c0-0.8,3.8-1.7,10.5-2.4
	c2.5-0.3,4.7-0.5,4.9-0.6c2.2-0.3,2.1,0.7,0,1.1c-0.7,0.1-1.5,0.5-1.8,0.8c-0.3,0.3-1.3,0.6-2.1,0.7c-0.9,0-1.6,0.4-1.6,0.7
	c0,0.4-0.5,0.5-1.4,0.3c-1-0.2-1.6,0-1.8,0.6C544,81.1,543.7,81.2,542.1,80.2L542.1,80.2z M557.6,75.8c0.3-0.4,2.2-0.9,4.1-1.1
	c1.9-0.2,3.7-0.5,4-0.7c0.7-0.4,21.6-1.3,21.2-0.9c-0.5,0.6-0.9,0.6-12.2,1.5c-5.6,0.4-11.9,1-13.9,1.3
	C557.9,76.4,557.1,76.3,557.6,75.8L557.6,75.8z M19.3,98.8c-1.6-0.2-1.7-1-0.2-1.5c0.8-0.2,1.4,0,1.8,0.7c0.3,0.6,0.4,1,0.2,1
	C20.8,99.1,20,98.9,19.3,98.8z M624.3,72c-0.6-0.6-0.6-0.8,0.1-0.8c0.4,0,1.1,0.2,1.6,0.4c0.6,0.4,0.6,0.5-0.1,0.8
	C625.5,72.6,624.7,72.4,624.3,72L624.3,72z M310.8,85.4c-0.7-0.4-0.7-0.6-0.2-1.2c0.5-0.6,0.8-0.6,1.4-0.1c0.5,0.4,0.5,0.9,0.2,1.2
	C311.9,85.6,311.3,85.7,310.8,85.4L310.8,85.4z M587.6,73.3c0-0.2,1-0.5,2.3-0.5c1.3-0.1,2.2,0.1,2,0.4
	C591.6,73.8,587.6,73.9,587.6,73.3z M596.2,72.9c0-0.3,0.9-0.5,2.1-0.6c2.5-0.1,2.3,0.4-0.3,0.8C597.1,73.2,596.2,73.1,596.2,72.9
	L596.2,72.9z M593.2,72.7c0.4-0.2,1-0.2,1.4-0.1c0.4,0.1,0.1,0.3-0.7,0.3C593.1,73,592.8,72.9,593.2,72.7L593.2,72.7z M637.5,70.5
	c0-0.6,4.9-1.5,5.7-1c1.5,0.8,0.5,1.5-2.5,1.5C639,70.9,637.6,70.7,637.5,70.5z M11.1,94.3c-1.6-0.8-3.4-2.5-4.8-4.4
	c-1.2-1.7-0.5-2.1,1-0.5c0.6,0.6,1.7,1.2,2.6,1.3c1,0.2,1.9,0.8,2.3,1.7C13.7,94.9,13.4,95.4,11.1,94.3L11.1,94.3z M56.1,58.5
	c-0.1-0.9,0.1-1.1,0.7-0.9c0.5,0.2,1.3,0.3,1.7,0.3c0.4,0,0.8,0.4,0.9,0.8c0,0.6-0.5,0.9-1.6,0.9C56.6,59.6,56.2,59.4,56.1,58.5
	L56.1,58.5z M65.3,55.2c-0.2-0.3,0.2-0.6,1.1-0.6c0.8,0,1.6,0.2,1.8,0.5c0.2,0.3-0.2,0.6-1.1,0.6C66.3,55.8,65.5,55.5,65.3,55.2z
	 M938.4,16.6c0-0.3,0.1-0.6,0.2-0.6c0.1,0,0.4,0.2,0.6,0.5c0.2,0.3,0.1,0.6-0.2,0.6C938.7,17.1,938.4,16.9,938.4,16.6z M322.4,39.5
	c-1.2-1.1-1.6-1.9-1.3-2.7c0.3-0.9,0.6-0.7,1.8,1.2C325.2,41.4,325,42,322.4,39.5L322.4,39.5z M68.4,51.3c-0.4-0.1-0.6-0.5-0.4-0.8
	c0.3-0.6,2-0.2,2,0.6C70,51.6,69.5,51.7,68.4,51.3L68.4,51.3z M300.8,20.8c-0.5-0.7,0.6-1.4,1.4-1c0.7,0.4,0.3,1.4-0.5,1.5
	C301.4,21.3,301,21.1,300.8,20.8L300.8,20.8z M258.1,18.1c-0.2-0.3-0.1-0.6,0.2-0.6c0.3,0,0.6,0.2,0.6,0.5c0,0.3-0.1,0.6-0.2,0.6
	C258.6,18.6,258.3,18.4,258.1,18.1L258.1,18.1z M255.5,17.6c0-0.3,0.2-0.6,0.5-0.6c0.3,0,0.6,0.1,0.6,0.2c0,0.1-0.2,0.4-0.5,0.6
	C255.8,18.1,255.5,18,255.5,17.6z M254.1,17.3c-0.2-0.2-0.4-0.6-0.4-1c0-0.4,0.3-0.3,0.7,0.1c0.4,0.4,0.6,0.8,0.4,1
	C254.7,17.5,254.4,17.5,254.1,17.3z M79.7,23.7c1.4-1,4-1.5,4-0.9c0,0.3-0.2,0.6-0.5,0.6c-0.3,0-1.4,0.2-2.4,0.4
	C79.5,24.1,79.1,24,79.7,23.7z M59.5,22.1c-6.8-0.2-5.3-1.2,2.4-1.6c3.9-0.2,7-0.6,7.1-1c0.6-1,2.5-1.1,3.9-0.2l1.3,0.8L73,20.9
	C71.6,21.8,66.1,22.3,59.5,22.1z M54.2,14.9c-0.6-0.5-0.2-1.2,0.6-1.2c0.5,0,0.9-0.4,0.9-0.8c0-0.6,0.1-0.6,0.3,0
	c0.2,0.4,0.6,0.6,0.8,0.4c0.3-0.2,0.5,0.2,0.6,0.8c0,0.8-0.3,1.1-1.4,1.1C55.2,15.2,54.4,15,54.2,14.9L54.2,14.9z M54.5,10.5
	c0.2-0.2,0.7-0.2,1.1-0.1c0.5,0.2,0.3,0.3-0.3,0.4C54.7,10.8,54.4,10.7,54.5,10.5L54.5,10.5z"/>
</svg>
  )
}

export default brush;
