const FETCH_STREAMS = "STREAMS/FETCH";
const FETCH_STREAMS_SUCCESS = "STREAMS/FETCH_SUCCESS";
const FETCH_STREAMS_FAILURE = "STREAMS/FETCH_FAILURE";

const types = {
  FETCH_STREAMS,
  FETCH_STREAMS_SUCCESS,
  FETCH_STREAMS_FAILURE
};

export default types;
