import React from "react";

export default function ({ color }) {
  return (
		<svg
			fill={color || "#000000"}
			version="1.1"
			id="flickr"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 512 512">
        <path d="m167.726562 179.164062c-41.917968 0-76.027343 34.105469-76.027343 76.027344 0 42.003906 34.109375 76.175782 76.027343 76.175782 42.003907 0 76.179688-34.171876 76.179688-76.175782 0-41.921875-34.175781-76.027344-76.179688-76.027344zm0 122.230469c-25.390624 0-46.050781-20.726562-46.050781-46.203125 0-25.394531 20.660157-46.050781 46.050781-46.050781 25.476563 0 46.203126 20.65625 46.203126 46.050781 0 25.476563-20.726563 46.203125-46.203126 46.203125zm0 0"/><path d="m437.484375 74.925781c-48.367187-48.316406-112.675781-74.925781-181.078125-74.925781-141.109375 0-255.90625 114.796875-255.90625 255.90625 0 141.210938 114.796875 256.09375 255.90625 256.09375 68.398438 0 132.707031-26.640625 181.082031-75.011719 48.371094-48.371093 75.011719-112.683593 75.011719-181.082031 0-68.378906-26.640625-132.652344-75.015625-180.980469zm-21.191406 340.867188c-42.710938 42.707031-99.492188 66.230469-159.886719 66.230469-124.578125 0-225.929688-101.4375-225.929688-226.117188 0-124.578125 101.351563-225.929688 225.929688-225.929688 60.402344 0 117.1875 23.496094 159.890625 66.15625 42.707031 42.667969 66.226563 99.40625 66.226563 159.769532 0 60.398437-23.519532 117.179687-66.230469 159.890625zm0 0"/><path d="m345.835938 179.164062c-42.003907 0-76.175782 34.105469-76.175782 76.027344 0 42.003906 34.171875 76.175782 76.175782 76.175782 41.941406 0 76.066406-34.171876 76.066406-76.175782 0-41.921875-34.125-76.027344-76.066406-76.027344zm0 122.230469c-25.476563 0-46.199219-20.726562-46.199219-46.203125 0-25.394531 20.722656-46.050781 46.199219-46.050781 25.414062 0 46.089843 20.65625 46.089843 46.050781 0 25.476563-20.675781 46.203125-46.089843 46.203125zm0 0"/>
    </svg>
  )
}