const FETCH_STANDING = "STANDING/FETCH";
const FETCH_STANDING_SUCCESS = "STANDING/FETCH_SUCCESS";
const FETCH_STANDING_FAILURE = "STANDING/FETCH_FAILURE";

const types = {
  FETCH_STANDING,
  FETCH_STANDING_SUCCESS,
  FETCH_STANDING_FAILURE
};

export default types;
