const FETCH_HISTORIC = "HISTORIC/FETCH";
const FETCH_HISTORIC_SUCCESS = "HISTORIC/FETCH_SUCCESS";
const FETCH_HISTORIC_FAILURE = "HISTORIC/FETCH_FAILURE";

const types = {
  FETCH_HISTORIC,
  FETCH_HISTORIC_SUCCESS,
  FETCH_HISTORIC_FAILURE
};

export default types;
