import React from "react";

function FooterSeparator({ color }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      viewBox="0 0 3840.000000 220.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,220.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M9723 1826 c4 -10 1 -16 -8 -16 -8 0 -15 -5 -15 -11 0 -6 -7 -9 -15
      -5 -8 3 -25 2 -37 -3 -20 -7 -18 -9 20 -16 23 -4 42 -10 42 -14 0 -9 69 -37
      113 -45 21 -4 35 -11 32 -17 -4 -5 -2 -9 4 -9 6 0 8 -7 5 -15 -4 -9 2 -18 17
      -24 13 -5 18 -10 10 -10 -8 -1 -20 -9 -27 -18 -12 -17 -13 -16 -14 4 0 28 -15
      37 -40 23 -16 -9 -20 -8 -20 6 0 10 5 14 15 11 25 -10 26 10 0 27 -28 18 -42
      19 -37 3 2 -6 -4 -4 -13 3 -8 7 -19 10 -23 6 -4 -4 2 -14 12 -22 11 -8 21 -20
      22 -27 1 -6 5 -24 9 -39 5 -21 2 -29 -13 -37 -25 -13 -92 -15 -92 -2 0 5 -9
      12 -20 16 -16 5 -17 9 -7 20 6 7 15 11 20 8 4 -2 7 3 7 11 0 9 8 16 17 16 14
      0 14 3 5 12 -10 10 -14 10 -21 0 -6 -10 -14 -9 -37 3 -40 21 -90 19 -113 -4
      -17 -17 -17 -20 -4 -25 8 -3 22 -6 30 -6 13 0 13 3 3 15 -11 13 -8 15 14 15
      23 0 26 -3 21 -21 -6 -19 -4 -21 14 -16 16 4 21 1 21 -15 0 -12 8 -27 18 -34
      16 -13 16 -13 0 -14 -13 0 -17 -8 -16 -35 1 -27 5 -34 15 -30 8 3 19 5 24 5 6
      0 7 9 4 20 -7 23 1 26 20 7 11 -12 10 -17 -6 -30 -21 -19 -69 -23 -69 -7 0 6
      -6 10 -14 10 -7 0 -25 11 -40 25 -29 27 -59 34 -50 11 3 -7 16 -18 30 -24 35
      -16 30 -32 -11 -33 -54 -3 -60 -4 -65 -10 -3 -3 -26 -12 -52 -21 -33 -10 -49
      -12 -52 -4 -3 8 -7 8 -13 -1 -5 -6 -30 -19 -57 -28 -62 -21 -156 -91 -156
      -116 0 -22 -44 -32 -142 -33 -45 -1 -71 4 -80 13 -11 11 -9 13 9 8 15 -3 20
      -1 16 9 -3 8 -19 14 -37 14 -18 0 -38 6 -44 12 -9 9 -15 10 -20 2 -8 -13 -51
      -13 -71 0 -10 6 -16 5 -19 -2 -2 -7 -10 -12 -18 -12 -8 0 -14 -5 -14 -11 0 -5
      -6 -7 -12 -3 -7 4 -5 -1 4 -11 17 -18 18 -18 37 -1 19 17 19 17 17 -9 -5 -41
      -42 -61 -103 -56 -13 1 -33 -3 -44 -10 -12 -6 -38 -12 -58 -13 -20 -2 -51 -10
      -68 -19 -22 -12 -33 -14 -33 -6 0 8 -4 8 -12 1 -13 -10 -84 -9 -94 1 -3 3 -19
      1 -35 -3 -19 -6 -29 -5 -29 2 0 6 -14 9 -33 6 -31 -3 -33 -2 -29 23 2 17 -2
      28 -11 32 -19 7 -41 -18 -31 -35 5 -8 1 -8 -11 2 -17 14 -18 14 -7 -7 9 -16 9
      -23 -1 -26 -7 -3 -5 -6 6 -6 9 -1 17 4 17 10 0 5 5 7 10 4 6 -3 10 -14 10 -24
      0 -14 -9 -18 -50 -20 -41 -1 -49 1 -43 14 3 9 12 14 19 11 10 -4 14 3 14 24 0
      24 -4 30 -22 31 -17 1 -19 2 -5 6 9 2 17 9 17 15 0 5 7 7 16 4 10 -4 25 1 35
      10 12 10 29 15 48 12 17 -2 32 0 34 6 2 6 11 -4 21 -21 9 -18 21 -33 26 -33
      14 0 12 14 -2 28 -9 9 -9 12 -1 12 7 0 15 -4 18 -10 10 -16 45 -12 45 5 0 20
      -41 36 -70 28 -16 -4 -21 -3 -16 5 4 7 13 12 19 12 15 0 51 40 43 47 -3 3 -11
      1 -18 -4 -23 -18 -71 -26 -66 -11 3 7 18 15 34 16 16 2 34 7 39 12 6 4 27 10
      47 14 36 6 37 8 35 47 -1 39 -2 40 -37 37 -48 -4 -55 -35 -8 -41 l33 -4 -37
      -1 c-21 -1 -38 -6 -38 -12 0 -5 -15 -10 -32 -9 l-33 1 28 11 c19 8 27 18 27
      35 0 19 -4 23 -17 16 -10 -4 -38 -8 -63 -8 -30 -1 -44 -5 -43 -13 4 -19 -46
      -16 -53 2 -4 9 -19 15 -41 15 -20 0 -32 4 -28 10 8 12 -35 13 -53 1 -8 -4 -12
      -12 -10 -16 4 -11 -52 -27 -74 -21 -10 2 -18 0 -18 -5 0 -5 -11 -9 -24 -9 -14
      0 -28 -4 -31 -10 -3 -5 -18 -10 -33 -9 -15 0 -21 3 -14 6 24 10 11 23 -23 23
      -32 0 -40 -7 -36 -32 2 -14 -17 -8 -23 7 -8 23 -31 18 -44 -10 -6 -14 -17 -25
      -24 -24 -10 0 -10 2 0 6 20 8 3 23 -26 23 -37 0 -62 -17 -44 -29 10 -6 7 -10
      -12 -15 -18 -4 -32 -2 -42 8 -18 19 -43 10 -54 -18 -9 -21 -9 -21 -9 2 -1 25
      1 25 -111 12 -47 -6 -143 -12 -215 -14 -71 -2 -152 -7 -178 -11 -40 -7 -50 -5
      -61 11 -8 10 -12 28 -10 41 2 12 0 25 -6 28 -6 4 -10 -5 -10 -19 0 -19 -5 -26
      -20 -26 -23 0 -27 25 -6 37 12 7 12 12 1 33 -11 19 -14 21 -15 8 0 -10 -6 -18
      -14 -18 -9 0 -12 7 -9 20 4 15 0 20 -13 20 -14 0 -15 3 -6 8 7 5 10 16 6 25
      -3 10 -1 17 5 17 6 0 11 -5 11 -10 0 -6 6 -9 13 -8 6 2 11 -2 10 -9 -4 -17 24
      -37 32 -23 4 7 -8 24 -29 42 -20 16 -34 34 -31 39 4 5 -1 9 -9 9 -9 0 -16 -4
      -16 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -8 -15 -17 -1 -17 -1 -17 -11 0 -7 12
      -23 17 -52 18 -23 0 -36 3 -29 6 24 9 11 23 -28 29 -22 4 -43 11 -46 15 -3 5
      -10 7 -15 4 -5 -4 -9 2 -10 12 0 10 -3 13 -6 6 -3 -8 -25 -13 -56 -14 -29 0
      -61 -5 -72 -10 -15 -7 -12 -8 13 -3 20 3 32 1 32 -5 0 -6 -4 -11 -9 -11 -12 0
      -20 -35 -11 -50 4 -6 10 -11 14 -11 3 1 18 1 33 1 24 0 25 -1 10 -12 -16 -12
      -14 -16 18 -42 39 -33 44 -45 18 -44 -10 1 -38 -2 -63 -5 -25 -4 -39 -4 -32
      -1 29 13 6 19 -85 22 -60 1 -100 6 -104 14 -5 8 -103 12 -358 12 -274 1 -351
      4 -351 14 0 6 -5 12 -10 12 -6 0 -13 -6 -15 -12 -4 -9 -37 -14 -107 -16 -56
      -2 -107 -7 -113 -11 -17 -14 -137 -22 -190 -12 -27 5 -42 9 -32 10 9 0 17 9
      17 19 0 14 -2 15 -10 2 -6 -9 -10 -10 -10 -3 0 8 -13 11 -35 9 -19 -1 -36 2
      -38 7 -4 13 -55 -3 -53 -16 1 -7 5 -18 9 -24 5 -8 3 -13 -7 -13 -9 0 -16 -4
      -16 -9 0 -14 -70 -43 -87 -37 -9 4 -30 2 -47 -3 -56 -19 -106 -21 -106 -5 0 8
      -5 14 -11 14 -6 0 -9 -9 -6 -20 3 -13 0 -20 -9 -20 -8 0 -14 -7 -14 -15 0 -8
      -4 -15 -10 -15 -5 0 -10 5 -10 11 0 10 -16 7 -59 -9 -9 -3 -12 -1 -8 5 5 8
      -11 10 -48 9 -30 -1 -55 1 -55 5 0 14 -38 16 -61 3 -15 -8 -18 -13 -9 -14 13
      0 13 -1 0 -10 -8 -5 -36 -10 -61 -10 -38 0 -47 3 -45 18 1 14 -10 18 -56 22
      -32 3 -58 2 -58 -1 0 -4 -8 -6 -17 -5 -12 0 -19 -7 -21 -21 -3 -20 -9 -23 -53
      -23 -36 0 -51 4 -56 16 -5 13 -15 15 -51 10 -25 -3 -51 -11 -58 -17 -8 -6 -20
      -9 -28 -6 -8 3 -16 -2 -19 -15 -5 -16 -11 -19 -36 -13 -23 5 -31 3 -31 -7 0
      -10 -2 -10 -9 1 -9 14 -72 18 -90 6 -5 -3 -11 1 -14 9 -5 12 -30 14 -134 10
      -151 -4 -203 -8 -300 -19 -54 -6 -76 -5 -88 5 -10 8 -19 9 -27 3 -7 -6 -64
      -14 -127 -17 -63 -4 -122 -11 -131 -16 -9 -5 -72 -11 -140 -14 -67 -3 -128
      -10 -134 -15 -16 -13 -266 -14 -266 -1 0 6 -34 10 -79 10 -54 0 -81 4 -84 13
      -3 9 -9 9 -26 0 -35 -21 -70 -16 -83 12 -11 25 -28 34 -28 15 0 -5 4 -10 8
      -10 5 0 9 -7 9 -15 0 -18 -33 -20 -40 -2 -4 10 -6 9 -6 0 -1 -7 -12 -13 -25
      -13 -13 0 -27 -6 -29 -12 -4 -10 -6 -10 -6 0 -1 14 -31 17 -31 2 0 -5 8 -14
      18 -19 16 -10 16 -10 0 -11 -10 0 -18 5 -18 10 0 6 -5 10 -11 10 -6 0 -9 -7
      -6 -15 4 -8 2 -17 -3 -20 -6 -4 -10 3 -10 14 0 20 -6 21 -112 22 -163 3 -181
      0 -200 -24 l-17 -22 -10 28 c-10 26 -31 38 -31 16 0 -13 -203 -1 -208 12 -2 5
      -13 2 -25 -6 -16 -10 -49 -13 -127 -11 -135 5 -130 4 -124 20 3 8 -3 16 -15
      19 -25 6 -38 -8 -15 -17 37 -14 -4 -24 -103 -25 -132 -1 -242 -11 -248 -22 -3
      -4 -21 -5 -41 -2 -24 4 -39 2 -44 -7 -6 -9 -13 -7 -30 10 -21 21 -22 24 -7 35
      22 16 11 25 -29 25 -38 0 -51 -14 -34 -35 10 -13 8 -15 -16 -16 -16 -1 -37 -1
      -46 0 -10 1 -33 -9 -51 -23 -19 -14 -38 -26 -43 -26 -5 0 -2 5 6 10 22 14 -1
      32 -36 28 -34 -4 -45 -28 -12 -29 13 0 17 -3 11 -6 -7 -2 -13 -16 -13 -29 0
      -13 -5 -24 -11 -24 -6 0 -8 9 -4 20 6 19 2 20 -59 20 -36 0 -66 3 -66 8 0 4
      -17 6 -39 4 -27 -3 -40 0 -44 11 -3 9 1 18 8 21 22 8 6 36 -20 36 -30 0 -41
      -16 -25 -35 10 -13 8 -15 -14 -15 -24 0 -26 -3 -26 -46 0 -33 5 -51 18 -60 16
      -13 16 -13 0 -14 -17 0 -18 -31 -18 -505 l0 -505 19200 0 19200 0 0 724 0 724
      -22 -6 c-17 -4 -20 -2 -16 14 3 10 7 26 9 34 3 9 -5 16 -18 18 -16 2 -23 -2
      -23 -13 0 -9 4 -14 9 -11 5 4 7 -3 4 -13 -4 -16 -12 -18 -41 -15 -21 3 -45 5
      -54 5 -10 0 -18 8 -19 17 0 11 -3 12 -6 5 -2 -7 -9 -13 -14 -13 -6 0 -7 5 -4
      10 10 16 -11 12 -25 -5 -7 -9 -18 -12 -29 -7 -9 4 -35 8 -59 7 -29 0 -41 4
      -38 12 8 20 -12 25 -22 7 -8 -15 -20 -16 -93 -9 -46 4 -153 9 -238 11 -85 2
      -157 7 -160 11 -3 5 -24 8 -46 8 -22 -1 -60 3 -85 7 -49 9 -200 10 -325 2 -95
      -6 -196 -6 -262 1 -59 6 -363 17 -416 16 -27 -1 -36 2 -31 10 5 8 3 9 -7 3 -8
      -5 -17 -9 -21 -10 -5 -1 -9 -3 -10 -4 -8 -8 -214 -5 -219 3 -4 5 -15 4 -28 -2
      -17 -10 -21 -9 -21 4 0 13 -3 13 -20 3 -16 -11 -22 -9 -36 7 -13 17 -15 17
      -10 3 5 -15 -1 -18 -40 -18 -25 0 -43 4 -39 9 10 17 -12 21 -25 6 -7 -9 -21
      -13 -31 -10 -11 4 -19 2 -19 -5 0 -6 -5 -8 -11 -4 -7 4 -26 7 -43 6 -17 0 -36
      4 -43 10 -9 7 -13 7 -13 0 0 -11 -144 -7 -162 4 -5 3 -8 0 -8 -6 0 -6 -10 -10
      -22 -9 -13 2 -29 0 -35 -4 -9 -6 -13 -2 -13 12 0 12 -5 21 -11 21 -5 0 -8 -4
      -5 -9 3 -5 -3 -11 -14 -14 -11 -3 -18 -1 -14 4 8 14 -6 10 -22 -6 -9 -8 -27
      -15 -42 -16 -84 -2 -148 -8 -192 -19 -27 -6 -55 -10 -62 -8 -7 3 -19 -2 -25
      -11 -8 -10 -15 -12 -20 -6 -5 6 -55 10 -113 11 -58 1 -166 4 -240 8 -74 3
      -171 8 -215 10 -44 2 -116 0 -160 -5 -44 -6 -141 -11 -215 -13 -155 -3 -282
      -14 -325 -26 -16 -5 -70 -13 -119 -19 -49 -5 -91 -14 -95 -19 -4 -6 -31 -11
      -61 -10 -30 0 -66 -2 -80 -6 -14 -3 -67 -10 -118 -16 -59 -5 -108 -17 -133
      -29 -26 -14 -40 -17 -45 -9 -5 8 -11 6 -18 -5 -18 -23 -83 -26 -99 -3 -8 11
      -19 16 -24 13 -6 -4 -14 0 -17 9 -3 8 -11 12 -17 9 -7 -5 -5 -13 6 -25 15 -16
      14 -18 -9 -24 -13 -3 -31 -9 -39 -12 -11 -4 -14 -1 -11 13 4 16 -2 18 -51 19
      -30 1 -55 -2 -55 -5 0 -10 -64 -7 -72 4 -4 6 2 12 14 15 12 3 24 12 26 19 3
      10 0 12 -11 8 -11 -4 -21 1 -27 13 -12 23 -60 28 -60 6 0 -17 -30 -50 -38 -42
      -2 3 1 12 7 20 17 20 4 38 -22 30 -11 -3 -41 -6 -65 -6 -25 1 -63 -3 -84 -7
      -23 -6 -38 -5 -38 1 0 16 -62 4 -74 -14 -8 -14 -6 -20 8 -31 17 -13 15 -14
      -25 -13 -34 1 -45 5 -49 19 -4 16 -16 19 -83 19 -42 1 -80 -2 -84 -5 -3 -4 4
      -18 16 -31 19 -20 20 -24 6 -30 -10 -3 -27 4 -43 19 -15 14 -33 25 -41 25 -9
      0 -11 7 -8 21 3 12 0 22 -9 26 -20 7 -17 20 6 26 10 3 24 -1 31 -9 8 -10 8
      -14 0 -14 -6 0 -11 -4 -11 -10 0 -5 7 -10 15 -10 8 0 15 7 15 16 0 8 5 12 10
      9 15 -9 47 4 54 22 4 9 1 20 -6 25 -10 6 -10 8 0 8 8 0 16 -9 19 -20 6 -22 18
      -26 28 -10 4 6 1 18 -7 27 -11 14 -11 15 7 6 34 -19 188 -38 193 -24 2 6 8 9
      13 5 5 -3 9 -1 9 5 0 6 -12 8 -29 5 -23 -5 -29 -2 -34 15 -3 12 -2 19 2 16 13
      -7 45 21 38 33 -6 8 -84 6 -95 -3 -7 -6 12 -34 26 -38 10 -3 5 -5 -10 -6 -19
      -1 -29 5 -34 22 -4 12 -11 25 -17 29 -7 4 -6 12 2 22 22 26 -5 19 -36 -10 -20
      -19 -24 -27 -14 -33 9 -6 5 -10 -14 -13 -19 -4 -23 -2 -16 5 20 20 11 28 -26
      24 -28 -3 -38 -9 -38 -21 0 -10 -8 -22 -18 -27 -9 -6 -17 -7 -17 -3 0 3 -7 1
      -15 -6 -13 -11 -15 -8 -15 18 0 17 -4 34 -10 37 -16 10 -12 23 10 30 28 9 25
      25 -4 25 -19 0 -23 4 -19 20 8 32 39 24 61 -15 10 -17 11 -18 12 -2 0 9 5 17
      10 17 6 0 10 7 10 15 0 18 -17 20 -22 3 -5 -15 -38 13 -38 31 0 6 -7 11 -15
      11 -8 0 -15 -6 -15 -14 0 -35 -73 -14 -85 24 -7 20 -25 28 -25 10 0 -5 4 -10
      10 -10 5 0 9 -3 9 -7 -1 -5 -1 -21 0 -36 1 -23 5 -27 20 -22 23 7 31 -3 22
      -28 -6 -17 -8 -17 -20 -3 -17 20 -31 21 -31 1 0 -9 11 -20 24 -26 14 -7 22
      -17 20 -24 -3 -7 5 -20 18 -29 22 -16 22 -16 -20 -16 -23 0 -42 -5 -42 -10 0
      -6 -11 -8 -25 -4 -15 4 -25 2 -25 -5 0 -11 25 -16 72 -12 33 3 36 -9 7 -27
      -21 -13 -21 -14 -2 -32 11 -10 29 -20 39 -23 12 -3 7 -5 -12 -6 -21 -1 -34 5
      -38 15 -3 8 -10 12 -16 9 -5 -3 -10 1 -10 9 0 9 -4 16 -8 16 -11 0 -9 -61 2
      -78 6 -10 0 -10 -22 2 -23 12 -31 13 -35 3 -3 -9 -7 -9 -18 0 -19 16 -112 20
      -116 6 -3 -7 10 -16 28 -20 31 -9 32 -9 13 -28 -18 -18 -18 -19 0 -12 10 4 24
      4 30 -1 6 -5 30 -14 54 -22 28 -9 41 -18 38 -27 -3 -7 1 -13 9 -13 8 0 15 8
      16 18 0 12 3 11 10 -6 5 -13 7 -28 4 -33 -11 -17 16 -50 38 -47 12 1 27 0 32
      -3 6 -4 27 -9 48 -13 50 -9 38 -26 -19 -26 -33 0 -44 -4 -44 -15 0 -35 -75
      -39 -113 -7 -16 14 -23 14 -71 -1 -29 -9 -61 -17 -70 -17 -9 0 -15 -4 -11 -9
      3 -5 0 -13 -7 -17 -16 -10 -69 -11 -75 -2 -2 5 -19 9 -36 9 -31 2 -31 2 -4 6
      32 5 45 27 27 48 -14 17 -167 16 -178 -1 -12 -18 -44 -25 -58 -13 -8 6 -23 9
      -34 6 -11 -3 -20 -1 -20 4 0 5 -7 9 -15 9 -8 0 -15 -6 -15 -14 0 -22 -25 -26
      -37 -7 -9 15 -27 18 -92 19 -73 1 -81 -1 -81 -18 0 -10 -3 -21 -7 -23 -15 -8
      -73 5 -67 15 6 10 -12 28 -28 28 -13 0 -9 -38 5 -44 8 -3 7 -5 -3 -4 -8 1 -24
      -3 -35 -9 -36 -20 -85 -26 -85 -11 0 7 -9 20 -19 27 -11 8 -17 20 -14 28 4 9
      -5 13 -28 14 -31 2 -31 2 -6 6 15 2 27 10 27 18 0 8 7 11 19 8 10 -3 22 0 25
      6 4 6 20 11 36 11 32 0 124 43 114 53 -4 3 -26 4 -49 0 -23 -3 -50 -1 -60 4
      -13 8 -4 12 43 17 34 4 64 11 67 15 2 5 25 12 50 15 35 6 45 11 45 26 0 21
      -12 21 -109 -1 -24 -5 -45 -8 -48 -5 -2 2 9 7 26 11 25 5 31 12 31 32 0 22 -3
      24 -22 18 -13 -4 -40 -7 -61 -6 -25 1 -39 -3 -43 -14 -8 -19 -43 -20 -50 0 -4
      10 -20 15 -45 15 -30 0 -37 3 -33 15 8 20 -29 20 -45 1 -9 -11 -9 -15 1 -19 7
      -3 3 -6 -9 -6 -12 -1 -25 -6 -29 -12 -3 -6 -19 -9 -35 -7 -16 3 -29 1 -29 -2
      0 -13 -83 -22 -90 -10 -4 6 -22 10 -41 8 -25 -2 -34 -8 -33 -20 1 -10 -5 -18
      -13 -18 -8 0 -11 5 -8 10 14 23 -26 21 -45 -1 -10 -13 -19 -18 -20 -11 0 17
      -52 15 -76 -3 -19 -14 -19 -14 1 -16 17 -1 16 -2 -5 -9 -39 -12 -50 -11 -50 5
      0 20 -36 19 -44 -1 -6 -15 -8 -15 -25 0 -16 14 -23 15 -41 6 -13 -7 -105 -16
      -219 -21 -108 -4 -254 -13 -326 -19 -71 -6 -186 -15 -255 -20 -69 -5 -176 -14
      -238 -20 -81 -7 -118 -7 -133 1 -17 9 -21 7 -26 -11 -5 -17 -11 -20 -32 -15
      -20 4 -22 3 -11 -5 12 -8 9 -10 -15 -10 -39 -1 -65 -21 -59 -46 5 -18 0 -20
      -43 -19 -61 0 -69 23 -18 50 70 38 36 48 -146 42 -74 -2 -147 -7 -164 -10 -55
      -10 -232 -9 -252 1 -37 20 -279 36 -300 19 -9 -6 -18 -6 -27 2 -14 12 -46 5
      -58 -13 -4 -6 -2 -13 4 -17 21 -13 -4 -29 -45 -29 -31 0 -45 5 -55 20 -7 11
      -8 20 -3 20 6 0 13 -4 16 -10 8 -12 41 -8 57 8 7 7 10 12 8 13 -3 0 -38 3 -77
      5 -50 3 -73 0 -73 -7 0 -8 -4 -8 -12 -2 -8 6 -87 9 -188 7 -96 -1 -179 1 -185
      6 -14 10 -90 19 -102 11 -6 -3 -13 -13 -16 -21 -4 -12 -6 -11 -6 3 -1 9 -7 17
      -13 17 -22 0 -95 64 -90 79 3 10 -3 8 -21 -5 -15 -10 -27 -26 -27 -34 0 -13
      -2 -13 -9 -1 -5 8 -26 16 -47 17 -42 4 -54 12 -35 23 9 6 5 10 -14 15 -14 4
      -30 4 -34 1 -5 -2 -14 2 -21 10 -7 8 -16 12 -21 9 -5 -3 -9 2 -9 10 0 9 -4 16
      -10 16 -5 0 -10 -4 -10 -10 0 -5 -21 -10 -47 -10 -27 0 -55 -5 -63 -10 -11 -8
      -8 -9 11 -5 18 5 28 2 32 -9 4 -11 1 -13 -10 -9 -12 5 -15 2 -10 -10 3 -9 2
      -17 -3 -17 -6 0 -10 -7 -10 -15 0 -9 6 -15 13 -14 6 1 23 1 37 0 19 -1 22 -3
      10 -11 -11 -7 -5 -18 35 -53 47 -43 48 -45 25 -52 -17 -6 -20 -9 -9 -16 10 -6
      11 -9 2 -9 -7 0 -22 9 -33 20 -14 14 -28 18 -52 15 -18 -3 -27 -3 -20 1 22 10
      2 23 -28 17 -16 -3 -37 -2 -47 2 -10 4 -38 7 -62 5 -24 -1 -47 3 -50 9 -4 6
      -102 13 -258 17 -139 4 -250 11 -247 16 3 4 1 8 -5 8 -5 0 -11 -5 -13 -10 -2
      -7 -85 -14 -218 -20 -118 -4 -242 -13 -275 -19 -33 -6 -86 -8 -118 -5 -49 6
      -57 9 -52 24 5 15 0 18 -27 16 -18 -1 -38 2 -44 6 -14 10 -52 -1 -50 -15 1 -7
      5 -18 9 -25 5 -7 3 -12 -7 -12 -9 0 -16 -4 -16 -9 0 -18 -89 -36 -100 -20 -5
      8 -106 11 -280 10 -84 -1 -86 -2 -121 -35 -23 -23 -40 -32 -48 -26 -8 4 -11
      12 -8 17 3 5 2 15 -2 22 -6 9 -43 12 -139 12 -133 0 -172 -9 -172 -40 0 -11
      -3 -11 -15 -1 -8 7 -15 21 -15 31 0 11 -4 19 -10 19 -5 0 -10 -7 -10 -15 0
      -19 -40 -19 -150 0 -70 12 -86 13 -92 1 -5 -9 -31 -13 -83 -14 -62 -2 -77 1
      -82 15 -4 9 1 21 10 28 26 19 20 25 -22 25 -43 0 -56 -16 -36 -43 6 -9 14 -16
      16 -14 2 1 1 -2 -4 -8 -5 -6 -15 -2 -25 10 -9 12 -25 24 -34 28 -19 7 -34 -8
      -18 -18 27 -17 0 -22 -117 -24 -71 -1 -155 -6 -188 -12 -75 -12 -75 -12 -102
      -5 -16 3 -23 0 -23 -10 0 -19 -6 -18 -31 7 -15 15 -18 24 -10 32 20 20 12 27
      -29 27 -41 0 -52 -14 -28 -38 9 -9 0 -12 -42 -12 -36 0 -59 -5 -72 -17 -17
      -15 -20 -15 -34 -2 -18 19 -74 13 -74 -7 0 -8 6 -14 14 -14 16 0 11 -36 -7
      -47 -6 -4 -8 0 -4 10 5 14 -1 17 -37 17 -26 0 -46 5 -49 12 -4 10 -8 9 -17 -2
      -8 -11 -13 -12 -16 -3 -3 7 -18 9 -41 6 -24 -4 -38 -2 -43 8 -11 17 -3 29 21
      29 13 0 19 7 19 21 0 18 -5 20 -35 16 -19 -3 -36 0 -38 6 -2 6 -9 7 -16 3 -7
      -5 -10 -14 -7 -21 3 -8 -2 -11 -15 -8 -10 3 -19 9 -19 14 0 11 -58 11 -85 0
      -12 -5 -26 -4 -34 3 -12 9 -13 6 -7 -16 5 -24 3 -28 -14 -28 -11 0 -20 7 -20
      15 0 11 -12 15 -48 15 -36 0 -54 5 -67 20 -10 11 -22 20 -27 20 -13 0 -9 -30
      5 -31 19 -1 -26 -18 -50 -18 -16 -1 -23 5 -23 18 0 30 -11 51 -26 51 -7 0 -18
      9 -24 20 -14 26 -29 25 -39 -2 -7 -19 -8 -19 -14 -4 -4 11 -12 14 -19 9 -7 -4
      -31 -9 -53 -10 -110 -7 -109 -7 -112 -37 -2 -31 -13 -50 -13 -23 0 10 -8 17
      -20 17 -13 0 -20 7 -20 20 0 16 -7 20 -32 20 -18 0 -46 0 -63 0 -16 1 -40 6
      -52 12 -18 9 -26 8 -41 -5 -10 -10 -22 -14 -26 -10 -4 4 -4 0 0 -10 4 -12 1
      -17 -10 -17 -9 0 -16 8 -16 18 0 27 -10 33 -32 21 -14 -8 -24 -7 -35 3 -16 13
      -47 14 -291 17 -53 1 -81 5 -77 11 3 6 28 10 54 10 27 0 63 3 80 6 26 6 31 11
      31 35 0 30 24 41 33 17 3 -10 9 -10 26 -1 26 16 31 16 31 0 0 -7 9 -20 20 -29
      17 -15 25 -16 71 -4 35 9 61 11 77 5 35 -13 110 -11 114 3 3 9 9 8 22 -5 21
      -21 40 -22 56 -2 9 11 16 12 32 2 16 -9 22 -9 25 0 2 7 11 9 19 6 8 -3 14 1
      14 11 0 9 4 16 8 16 4 0 9 11 10 25 1 14 -3 25 -8 25 -6 0 -10 -11 -10 -24 0
      -27 -19 -39 -41 -25 -8 4 -25 5 -38 2 -16 -4 -28 -1 -35 9 -6 8 -18 18 -26 21
      -9 4 -6 5 7 3 13 -2 26 0 29 5 3 5 9 7 13 4 5 -2 13 6 19 20 9 20 8 24 -6 28
      -9 2 -27 0 -39 -5 -19 -6 -23 -4 -23 12 0 23 -4 24 -32 9 -14 -8 -23 -8 -27 0
      -4 5 -22 10 -41 10 -106 -1 -120 -3 -120 -17 0 -10 -6 -12 -17 -7 -10 4 -31 9
      -47 11 -17 2 -33 9 -36 14 -7 12 -40 3 -40 -11 0 -4 -10 -13 -21 -20 -17 -9
      -23 -8 -30 2 -6 11 -11 11 -20 2 -6 -6 -26 -9 -45 -6 -26 4 -34 2 -34 -9 0 -9
      -6 -21 -14 -27 -12 -10 -18 -8 -31 12 -21 33 -55 26 -55 -12 l-1 -26 -22 28
      c-28 34 -37 34 -37 2 0 -16 -6 -25 -15 -25 -8 0 -15 5 -15 10 0 6 -7 10 -15
      10 -8 0 -15 -5 -15 -10 0 -6 -17 -8 -42 -5 -66 7 -120 14 -155 20 -28 5 -31 3
      -26 -15 4 -16 2 -19 -11 -14 -9 3 -16 10 -16 15 0 5 -6 9 -14 9 -15 0 -30 -56
      -19 -74 4 -6 6 -20 5 -32 -3 -18 4 -22 44 -28 34 -4 52 -2 63 8 10 9 22 11 31
      6 8 -4 56 -7 105 -6 129 3 135 4 135 21 0 10 10 15 28 15 23 -1 25 -2 9 -11
      -9 -6 -15 -14 -12 -20 5 -8 -23 -14 -47 -10 -13 2 -9 -9 10 -28 15 -17 35 -19
      180 -19 168 0 192 -5 192 -43 0 -15 9 -18 56 -18 32 0 90 -3 131 -6 49 -5 78
      -4 87 4 10 8 16 8 25 -3 17 -20 7 -23 -83 -24 -53 0 -82 -5 -87 -14 -6 -9 -9
      -9 -9 -1 0 14 -28 20 -131 28 l-77 7 -18 -30 c-15 -26 -25 -31 -68 -36 -28 -3
      -70 -8 -94 -12 -28 -4 -47 -2 -57 6 -11 9 -15 10 -15 1 0 -8 -10 -10 -32 -6
      -37 8 -45 8 -138 1 -36 -2 -71 -5 -79 -5 -9 -1 -11 -9 -7 -26 4 -15 2 -25 -4
      -25 -6 0 -8 -5 -5 -10 3 -6 -3 -10 -14 -10 -15 0 -21 7 -21 23 -1 20 -2 21
      -14 5 -16 -22 -26 -23 -26 -3 0 18 -16 20 -23 3 -3 -8 -6 -5 -6 5 -1 10 -7 17
      -13 14 -7 -2 -13 -14 -12 -26 0 -11 -3 -21 -8 -21 -16 0 -40 29 -33 40 3 5 1
      10 -5 10 -6 0 -10 -10 -8 -22 3 -20 -2 -23 -32 -25 -19 -1 -29 0 -22 3 6 3 12
      10 12 15 0 5 -6 9 -12 9 -7 0 -24 9 -38 20 -24 18 -24 20 -7 37 16 16 17 16
      17 -5 0 -15 6 -22 20 -22 27 0 25 28 -2 39 -20 7 -36 7 -79 2 -4 -1 -10 -13
      -14 -28 -4 -16 -10 -22 -12 -15 -6 15 -47 16 -73 2 -13 -7 -21 -7 -25 0 -3 5
      -15 10 -26 10 -10 0 -19 5 -19 11 0 6 6 9 14 6 8 -3 22 1 33 9 17 13 17 15 1
      28 -10 7 -18 18 -18 25 0 6 -4 11 -10 11 -5 0 -10 6 -10 14 0 7 -7 19 -15 26
      -15 13 -22 45 -7 36 4 -2 13 4 20 14 10 17 8 19 -18 18 -40 -1 -49 9 -16 18
      14 3 26 9 26 13 -2 35 -84 61 -102 31 -13 -21 -55 -15 -84 12 -15 13 -24 16
      -29 8 -5 -8 -10 -8 -20 0 -8 7 -19 9 -25 5 -18 -11 -11 -24 18 -28 25 -4 25
      -4 -5 -6 -23 -1 -33 4 -33 13 0 15 -53 32 -69 22 -10 -7 -83 -17 -115 -17 -13
      0 -16 -3 -8 -11 5 -5 31 -9 56 -7 34 3 48 0 58 -13 9 -13 26 -18 61 -18 28 0
      46 -4 42 -10 -3 -5 -12 -7 -20 -3 -8 3 -15 0 -15 -6 0 -7 -26 -11 -77 -10 -53
      1 -71 4 -60 11 34 19 19 36 -29 33 -50 -4 -65 -12 -45 -24 19 -12 -40 -22
      -141 -24 -49 -1 -88 -5 -88 -9 0 -5 -5 -8 -11 -8 -6 0 -9 8 -7 18 2 10 11 16
      21 14 21 -4 23 24 1 32 -25 10 -34 7 -34 -9 0 -8 -4 -15 -10 -15 -5 0 -10 7
      -10 15 0 10 -11 15 -35 15 -22 0 -38 6 -44 17 -10 17 -12 17 -25 1 -7 -10 -22
      -18 -33 -18 -11 0 -27 -9 -34 -20 -12 -17 -19 -19 -44 -12 -18 5 -68 3 -125
      -4 -52 -7 -116 -14 -143 -15 -26 -1 -50 -6 -53 -10 -3 -5 -28 -9 -55 -9 -28 0
      -48 4 -45 9 7 11 -85 2 -100 -10 -6 -5 -15 -4 -20 1 -5 5 -44 11 -86 12 -42 2
      -72 0 -65 -4 7 -4 -2 -8 -21 -8 -29 0 -32 -3 -27 -20 7 -22 15 -24 -112 27 -7
      3 -13 0 -13 -6 0 -13 -33 -7 -93 15 -20 8 -50 14 -65 14 -15 0 -32 4 -38 9 -5
      5 -40 11 -78 13 -51 3 -70 8 -78 21 -5 9 -21 17 -35 17 -26 0 -26 -1 -7 -14
      17 -13 2 -14 -127 -13 -134 1 -146 3 -141 19 3 13 -1 18 -17 18 -11 0 -21 5
      -21 12 0 11 -24 -14 -47 -49 -11 -17 -12 -17 -12 3 -1 30 -10 35 -37 17 -19
      -12 -27 -13 -39 -3 -15 12 -55 5 -55 -11 0 -15 -36 -10 -50 6 -7 8 -25 15 -41
      15 -16 0 -29 5 -29 10 0 15 -36 12 -49 -4 -10 -11 -8 -15 6 -18 10 -3 -5 -8
      -34 -12 -29 -4 -57 -5 -63 -1 -5 3 -10 -1 -10 -11 0 -17 -18 -17 -119 2 -25 5
      -36 3 -43 -9 -7 -13 -14 -14 -35 -6 -19 7 -28 6 -33 -2 -5 -8 -13 -8 -26 -1
      -11 6 -58 16 -104 22 -47 7 -94 16 -105 20 -26 11 -160 30 -253 37 -85 5 -122
      -8 -174 -62 -18 -19 -38 -35 -44 -35 -7 0 -14 -7 -18 -15 -3 -8 -12 -15 -21
      -15 -8 0 -15 -5 -15 -11 0 -5 -4 -8 -9 -5 -5 3 -8 14 -6 24 1 9 0 11 -2 4 -3
      -7 -13 -10 -27 -6 -12 3 -34 0 -49 -5 -17 -7 -27 -7 -27 -1 0 6 -7 10 -15 10
      -8 0 -15 -9 -15 -20 0 -11 5 -20 11 -20 5 0 8 -4 4 -9 -6 -10 -98 -13 -127 -5
      -10 3 -18 13 -18 22 0 9 -5 24 -10 32 -9 13 -12 12 -25 -4 -8 -11 -15 -15 -15
      -10 0 6 -12 16 -26 22 -19 9 -24 17 -19 32 8 26 -11 26 -25 -1 -14 -25 -6 -49
      15 -49 8 0 20 -12 26 -27 10 -26 11 -27 23 -10 11 14 15 15 25 3 9 -11 8 -15
      -5 -20 -30 -11 -67 -6 -80 12 -10 12 -13 13 -14 3 0 -11 -4 -10 -19 3 -11 10
      -25 19 -33 20 -7 2 -26 8 -41 14 -16 7 -33 12 -38 12 -5 0 -9 5 -9 10 0 7 7 7
      21 0 16 -9 24 -8 38 4 10 9 27 16 39 16 12 0 22 6 22 14 0 10 -19 14 -72 16
      -131 5 -302 3 -341 -4 l-38 -7 6 -45 c3 -26 1 -44 -5 -44 -5 0 -10 4 -10 9 0
      6 -24 8 -55 6 -30 -2 -54 0 -54 3 5 35 -21 43 -49 16 -10 -11 -17 -13 -20 -5
      -2 6 -26 11 -58 11 -41 0 -54 -4 -54 -15 0 -8 6 -15 13 -15 7 0 18 -9 25 -20
      7 -11 23 -20 36 -20 31 0 56 -10 56 -21 0 -5 -19 -7 -42 -5 -24 3 -46 5 -50 5
      -4 1 -5 8 -2 16 6 16 -11 21 -20 6 -7 -10 -55 -21 -54 -12 2 21 -5 31 -22 31
      -13 0 -20 7 -20 20 0 11 5 19 11 17 6 -1 13 3 16 10 4 12 -94 24 -112 14 -5
      -3 -41 -6 -80 -7 -102 -2 -115 -3 -115 -13 0 -5 8 -12 18 -14 15 -4 15 -5 -1
      -6 -25 -1 -16 -25 11 -29 12 -2 22 -8 22 -13 0 -13 -27 -11 -35 2 -4 6 -13 8
      -20 5 -7 -2 -16 2 -19 10 -3 8 -1 14 5 14 6 0 9 10 7 23 -2 14 -9 21 -18 18
      -8 -2 -67 -5 -130 -6 -221 -4 -240 -5 -240 -14 0 -16 152 -18 167 -3 9 9 13
      10 13 2 0 -8 19 -11 58 -8 53 3 60 1 69 -19 9 -19 8 -23 -8 -28 -10 -4 -29 -3
      -40 0 -14 5 -19 4 -15 -4 5 -7 1 -9 -9 -5 -8 4 -21 1 -28 -5 -10 -8 -19 -6
      -35 5 -17 12 -22 12 -22 2 0 -9 -3 -9 -10 2 -8 12 -12 12 -18 2 -6 -9 -16 -10
      -33 -3 -29 10 -149 12 -149 1 0 -3 -30 -7 -67 -8 -38 -1 -120 -4 -183 -5 -82
      -3 -119 0 -130 9 -14 12 -56 19 -92 16 -9 -1 -19 -10 -21 -19 -3 -13 -5 -12
      -6 5 -1 18 -5 22 -20 17 -10 -3 -41 -5 -67 -5 -86 1 -99 0 -102 -10 -2 -5 -9
      -7 -15 -4 -7 2 -13 -4 -14 -16 -2 -18 3 -19 73 -16 88 4 136 -4 142 -23 4 -11
      -3 -13 -27 -8 -19 4 -31 2 -31 -4 0 -6 -7 -3 -16 5 -18 19 -16 18 -46 7 -30
      -11 -44 -11 -41 0 4 12 -14 12 -21 0 -7 -10 -56 -2 -56 10 0 5 -5 9 -11 9 -5
      0 -8 -4 -5 -8 2 -4 -28 -7 -67 -6 -40 2 -89 3 -109 3 -32 0 -37 3 -31 18 6 15
      4 16 -8 6 -9 -8 -28 -9 -58 -3 -25 4 -51 6 -58 3 -7 -3 -13 0 -13 6 0 6 -5 11
      -11 11 -6 0 -8 -8 -5 -17 5 -16 4 -16 -6 -3 -7 8 -26 16 -42 18 -16 2 -47 12
      -68 23 -38 19 -38 19 -38 0 0 -12 10 -23 28 -29 l27 -10 -25 -1 c-15 -1 -32 5
      -38 11 -9 9 -15 9 -20 3 -6 -9 -116 -13 -132 -5 -3 2 -16 3 -29 4 -18 1 -27 9
      -34 31 -12 35 -26 47 -49 41 -9 -2 -36 0 -60 5 -23 5 -81 11 -128 14 -47 2
      -125 8 -175 12 -131 11 -228 10 -232 -3 -2 -7 17 -12 53 -13 31 -1 64 -6 74
      -11 9 -5 32 -12 51 -15 43 -8 68 -30 51 -47 -8 -8 -16 -5 -31 13 -20 24 -20
      24 -21 2 0 -12 6 -24 13 -27 6 -3 1 -3 -13 0 -34 9 -52 25 -46 41 3 8 1 11 -4
      8 -6 -4 -24 -8 -40 -10 -22 -3 -28 0 -23 11 3 8 -1 14 -11 14 -9 0 -16 -8 -16
      -20 0 -14 -6 -20 -22 -19 -17 1 -19 2 -5 6 29 8 19 30 -15 36 -41 6 -53 0 -36
      -20 11 -14 10 -15 -9 -9 -12 4 -30 9 -40 11 -10 2 -26 6 -35 10 -13 5 -18 2
      -18 -11 -1 -18 -1 -18 -11 -1 -13 22 -47 22 -51 0 -3 -16 -6 -15 -26 5 -12 12
      -30 22 -39 22 -10 0 -27 7 -37 15 -27 20 -47 19 -39 -2 4 -11 3 -14 -5 -9 -7
      4 -12 14 -12 22 0 8 -9 14 -20 14 -13 0 -18 -5 -14 -15 4 -8 1 -15 -5 -15 -6
      0 -11 4 -11 8 0 5 -12 14 -26 20 -14 7 -23 8 -19 2 3 -5 -1 -10 -10 -10 -11 0
      -15 6 -10 18 4 16 0 19 -25 20 -16 0 -30 -4 -30 -9 0 -5 -9 -9 -21 -9 -12 0
      -18 4 -13 11 3 6 0 15 -7 20 -10 6 -6 9 14 9 18 0 27 4 25 13 -4 11 -73 29
      -153 41 -52 7 -85 18 -85 27 0 5 -9 9 -20 9 -11 0 -20 5 -20 11 0 7 -10 9 -25
      7 -17 -4 -25 -1 -25 8 0 8 -7 14 -15 14 -8 0 -15 -7 -15 -16 0 -28 -70 -8
      -101 29 -22 27 -29 30 -42 19 -9 -8 -18 -9 -21 -4 -3 6 -37 18 -76 27 -38 9
      -70 21 -70 27 0 8 18 5 58 -9 6 -3 12 2 12 11 0 10 -11 19 -30 23 -33 6 -40
      20 -14 25 10 2 18 15 20 31 3 26 2 27 -39 21 -49 -8 -83 -32 -67 -48 32 -32
      -22 -41 -67 -10 -20 13 -48 27 -62 30 -14 3 -44 12 -66 19 -22 8 -71 16 -110
      20 -38 3 -74 10 -80 15 -5 4 -37 11 -70 14 -53 5 -90 9 -132 14 -13 2 -19 52
      -7 52 4 0 13 -8 21 -17 14 -17 14 -17 9 0 -3 9 -1 17 5 17 6 0 7 5 4 10 -3 6
      1 10 9 10 9 0 16 5 16 10 0 6 -4 10 -9 10 -5 0 -16 3 -25 6 -10 4 -16 1 -16
      -8 0 -7 -9 -20 -20 -28 -11 -8 -20 -22 -20 -31 0 -13 -6 -15 -32 -10 -27 6
      -33 12 -34 34 -1 39 -11 44 -44 25 -17 -10 -32 -13 -35 -7 -6 9 -84 6 -102 -4
      -4 -2 -21 2 -38 9 -30 13 -30 13 -7 20 19 5 22 11 17 32 -5 20 -3 23 10 18 9
      -3 27 -6 41 -6 17 0 22 -4 18 -15 -4 -8 -1 -15 5 -15 6 0 11 11 11 25 0 20 -5
      25 -25 26 -14 1 -33 2 -42 3 -21 1 -43 20 -43 35 0 6 -13 11 -30 11 -19 0 -30
      -5 -30 -14 0 -8 -7 -17 -16 -20 -10 -4 -14 -14 -11 -26 7 -27 -32 -27 -61 0
      -12 11 -30 20 -41 20 -11 0 -22 4 -25 9 -3 4 -25 11 -48 15 -24 3 -57 14 -75
      23 -39 21 -57 9 -24 -16 22 -17 22 -18 5 -23 -11 -2 -41 -1 -69 2 -27 4 -40 8
      -29 9 26 1 51 28 38 40 -5 5 -26 12 -48 15 -33 6 -38 5 -33 -8z m17742 -1013
      c6 8 20 16 30 18 24 3 57 -10 50 -20 -2 -4 10 -5 27 -3 28 4 30 2 25 -17 -3
      -14 1 -24 11 -31 19 -11 16 -11 -53 -14 -31 -1 -42 -6 -52 -26 -10 -20 -13
      -21 -13 -6 0 17 -7 18 -103 14 l-104 -5 -9 31 c-7 25 -16 32 -44 37 l-35 6 35
      3 c19 1 56 2 82 1 42 0 50 3 70 29 l22 30 25 -31 c21 -27 26 -29 36 -16z"/>
      </g>
    </svg>
  );
}

export default FooterSeparator;
