import React from "react";

export default function ({ color }) {
  return (
		<svg
			fill={color || "#000000"}
			version="1.1"
			id="email"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 512 512">
		<g>
			<g>
				<path d="M493.469,0.481c-0.14,0.034-0.281,0.062-0.421,0.1L26.362,127.859c-11.729,3.199-14.956,18.333-5.591,26.049
					L166.019,273.57l-26.743,80.228c-3.898,11.696,7.26,22.877,18.974,18.974l80.228-26.743L358.14,491.277
					c7.748,9.403,22.855,6.115,26.049-5.591L511.466,18.999c0.04-0.147,0.07-0.296,0.105-0.444
					C514.204,7.56,504.277-2.128,493.469,0.481z M62.21,149.178L431.982,48.331L183.593,249.179L62.21,149.178z M234.599,315.698
					l-57.376,19.126l19.126-57.376L396.206,115.84L234.599,315.698z M362.868,449.836L262.866,328.453l200.85-248.39L362.868,449.836z
					"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M46.805,465.313c-5.861-5.856-15.359-5.851-21.214,0.01L4.388,486.546c-5.855,5.86-5.851,15.358,0.01,21.213
					c5.594,5.589,15.625,5.584,21.213-0.01l21.204-21.223C52.67,480.666,52.666,471.168,46.805,465.313z"/>
			</g>
		</g>
		<g>
			<g>
				<path d="M110.413,401.645c-5.861-5.855-15.357-5.851-21.213,0.01l-21.203,21.223c-5.322,5.327-5.317,15.891,0.01,21.213
					c5.059,5.054,16.156,5.051,21.213-0.01l21.203-21.223C116.278,416.998,116.274,407.5,110.413,401.645z"/>
			</g>
		</g>
		</svg>
	)
}
