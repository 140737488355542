import React from "react";

export default function ({ color }) {
  return (
		<svg
			fill={color || "#000000"}
			version="1.1"
			id="nif"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 512 512">
			<g>
				<g>
					<path d="M467,61H45C20.187,61,0,81.187,0,106v300c0,24.813,20.187,45,45,45h422c24.813,0,45-20.187,45-45V106
						C512,81.187,491.813,61,467,61z M482,406c0,8.271-6.729,15-15,15H45c-8.271,0-15-6.729-15-15V106c0-8.271,6.729-15,15-15h422
						c8.271,0,15,6.729,15,15V406z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M150,241c-49.626,0-90,40.374-90,90v15c0,24.813,20.187,45,45,45h90c24.813,0,45-20.187,45-45v-15
						C240,281.374,199.626,241,150,241z M210,346c0,8.271-6.729,15-15,15h-90c-8.271,0-15-6.729-15-15v-15c0-33.084,26.916-60,60-60
						s60,26.916,60,60V346z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M150,121c-33.084,0-60,26.916-60,60s26.916,60,60,60s60-26.916,60-60S183.084,121,150,121z M150,211
						c-16.542,0-30-13.458-30-30s13.458-30,30-30s30,13.458,30,30S166.542,211,150,211z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M437,331H287c-8.284,0-15,6.716-15,15s6.716,15,15,15h150c8.284,0,15-6.716,15-15S445.284,331,437,331z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M437,271H287c-8.284,0-15,6.716-15,15s6.716,15,15,15h150c8.284,0,15-6.716,15-15S445.284,271,437,271z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M437,211H287c-8.284,0-15,6.716-15,15s6.716,15,15,15h150c8.284,0,15-6.716,15-15S445.284,211,437,211z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M407,151h-90c-8.284,0-15,6.716-15,15s6.716,15,15,15h90c8.284,0,15-6.716,15-15S415.284,151,407,151z"/>
				</g>
			</g>
		</svg>
	)
}
